import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col } from "react-bootstrap";
import styled from "styled-components";

export const CardList = styled(Col)`
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    margin-bottom: .7rem;
    border-radius: .5rem;
    position: relative;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    transition: all .5s;
    box-shadow: 0px 4px 4px 0px #00000040;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 4px 4px 0px #F29F05;
        transition: all .5s;
        background: #F2F2F2;
    }

    &.selected {
        box-shadow: 0 0 1rem #F28705;
    }
`;

export const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: white;
  background: white;
  border: 1px solid #ccc;
  padding: 3px;
  border-radius: .5rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 1.25rem;

  &.selected {
    background-color: #153B7F;
  }
`;

export interface CardDataProps {
    card_id: number;
    card_name: string;
}