import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select, { SingleValue, ActionMeta } from 'react-select';
import axios from 'axios';
import Bank from '../../../../../../interfaces/BanksPortability';

interface EditBankModalProps {
  show: boolean;
  onHide: () => void;
  onSave: (bank: Bank) => void;
  bankToEdit: Bank | null;
}

const EditBankModal: React.FC<EditBankModalProps> = ({
  show,
  onHide,
  onSave,
  bankToEdit,
}) => {
  const [banks, setBanks] = useState<Bank[]>([]);
  const [selectedBank, setSelectedBank] = useState<Bank | null>(bankToEdit);
  const [portTax, setPortTax] = useState<string>('');
  const [refinTax, setRefinTax] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await axios.get('https://brasilapi.com.br/api/banks/v1');
        setBanks(response.data);
      } catch (error) {
        console.error('Erro ao buscar bancos:', error);
      }
    };

    fetchBanks();
  }, []);

  useEffect(() => {
    if (bankToEdit) {
      setSelectedBank(bankToEdit);
      setPortTax(String(bankToEdit.portTax || ''));
      setRefinTax(String(bankToEdit.refinTax || ''));
    }
  }, [bankToEdit]);

  const handleSave = async () => {
    if (!selectedBank || !portTax || !refinTax) {
      alert('Por favor, preencha todos os campos.');
      return;
    }

    const bankToUpdate: Bank = {
      ...selectedBank,
      portTax: parseFloat(portTax),
      refinTax: parseFloat(refinTax),
    };

    try {
      setLoading(true);
      console.log(bankToUpdate.id);
      
      await axios.patch(`https://bi.gfttech.com.br/port/banks/${bankToUpdate.id}`, {
        bankName: bankToUpdate?.bankName,
        bankCode: bankToUpdate.bankCode,
        portTax: parseFloat(portTax),
        refinTax: parseFloat(refinTax),
      });
      alert('Banco atualizado com sucesso!');
      onSave(bankToUpdate);
      onHide();
    } catch (error) {
      alert('Erro ao atualizar o banco.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const bankOptions = banks.map((bank) => ({
    value: bank.bankCode,
    label: `${bank.bankCode || 'N/A'} - ${bank?.bankName}`,
    ...bank,
  }));

  const handleSelectChange = (
    newValue: SingleValue<{ value: string | null; label: string }>,
    actionMeta: ActionMeta<{ value: string | null; label: string }>
  ) => {
    if (newValue && newValue.value) {
      const bank = banks.find((b) => b.bankCode.toString() === newValue.value);
      if (bank) {
        setSelectedBank(bank);
      }
    } else {
      setSelectedBank(null);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Banco</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Banco</Form.Label>
            {/* Exibindo o banco de forma informativa, sem permitir alterações */}
            <Form.Control
              type="text"
              value={selectedBank ? `${selectedBank.bankCode || 'N/A'} - ${selectedBank?.bankName}` : ''}
              readOnly
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Taxa de Portabilidade</Form.Label>
            <Form.Control
              type="number"
              value={portTax}
              onChange={(e) => setPortTax(e.target.value)}
              placeholder="Digite a taxa de portabilidade"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Taxa de Refinanciamento</Form.Label>
            <Form.Control
              type="number"
              value={refinTax}
              onChange={(e) => setRefinTax(e.target.value)}
              placeholder="Digite a taxa de refinanciamento"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={loading}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={loading}>
          {loading ? 'Salvando...' : 'Salvar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditBankModal;