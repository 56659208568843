import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styles from './FilterSearchReturn.module.scss';

interface FilterSearchReturnProps {
  cpfFilter: string;
  setCpfFilter: (cpf: string) => void;
}

export default function FilterSearchReturn({ cpfFilter, setCpfFilter }: FilterSearchReturnProps) {
  const [localCpf, setLocalCpf] = useState(cpfFilter);

  useEffect(() => {
    setLocalCpf(cpfFilter);
  }, [cpfFilter]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCpf = event.target.value;
    setLocalCpf(newCpf);
    setCpfFilter(newCpf); 
  };

  return (
    <div className={styles.inputWrapper}>
      <Form.Control 
        type="text" 
        placeholder="Procurar CPF"
        value={localCpf}
        onChange={handleInputChange}
        className={styles.inputText}
      />
      <div className={styles.searchButton}>
        <FontAwesomeIcon icon={faSearch} />
      </div>
    </div>
  );
}
