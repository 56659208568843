import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Modal, Button, Form, Spinner, Alert } from "react-bootstrap";

interface EditPortabilityModalProps {
  show: boolean;
  onHide: () => void;
  onSave: () => void;
  portabilityToEdit: any; 
}

const EditPortabilityModal: React.FC<EditPortabilityModalProps> = ({
  show,
  onHide,
  onSave,
  portabilityToEdit,
}) => {
  const [formData, setFormData] = useState({
    originBank: { id: 0, bankName: "" },
    destinationBank: { id: 0, bankName: "" },
    minimumInstallments: "",
    minimumBalanceAmount: "",
    loas: false,
    legalRepresentative: false,
    srcc: false,
    illiterate: false,
  });

  const [banks, setBanks] = useState<{ value: number; label: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (show) {
      setLoading(true);
      fetch("https://bi.gfttech.com.br/port/banks")
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch banks");
          }
          return response.json();
        })
        .then((data) => {
          const formattedBanks = data.map((bank: any) => ({
            value: bank.id,
            label: bank.bankName,
          }));
          setBanks(formattedBanks);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
  }, [show]);

  useEffect(() => {
    if (portabilityToEdit) {
      setFormData({
        originBank: portabilityToEdit.originBank,
        destinationBank: portabilityToEdit.destinationBank,
        minimumInstallments: portabilityToEdit.minimumInstallments,
        minimumBalanceAmount: portabilityToEdit.minimumBalanceAmount,
        loas: portabilityToEdit.loas,
        legalRepresentative: portabilityToEdit.legalRepresentative,
        srcc: portabilityToEdit.srcc,
        illiterate: portabilityToEdit.illiterate,
      });
    }
  }, [portabilityToEdit]);
  

  const handleSave = () => {
    const { minimumInstallments, minimumBalanceAmount } = formData;
  
    const parsedInstallments = parseInt(minimumInstallments, 10);
    if (parsedInstallments < 1 || isNaN(parsedInstallments)) {
      alert("O número de parcelas mínimas deve ser um número inteiro positivo e maior ou igual a 1.");
      return;
    }
  
    // ajustar o valor de 'minimumBalanceAmount' para ser um número inteiro, ignorando a parte decimal
    const formattedBalance = parseFloat(minimumBalanceAmount.replace('R$', '').replace(',', '.'));
    if (isNaN(formattedBalance) || formattedBalance < 0) {
      alert("O valor mínimo de saldo deve ser um número válido e positivo.");
      return;
    }
  
    const integerBalance = Math.floor(formattedBalance); // Ignorar a parte decimal
  
    if (formData.originBank.id === formData.destinationBank.id) {
      alert("O banco de origem e o banco de destino não podem ser iguais.");
      return;
    }
  
    const dataToSubmit = {
      originBank: formData.originBank.id,
      destinationBank: formData.destinationBank.id,
      minimumInstallments: parsedInstallments,
      minimumBalanceAmount: integerBalance,
      loas: formData.loas,
      legalRepresentative: formData.legalRepresentative,
      srcc: formData.srcc,
      illiterate: formData.illiterate,
    };
  
    fetch(`https://bi.gfttech.com.br/port/portabilities/${portabilityToEdit?.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSubmit),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao salvar dados!");
        }
        return response.json();
      })
      .then(() => {
        alert("Portabilidade editada com sucesso!");
        onHide();
        onSave();
      })
      .catch((err) => {
        setError(err.message);
      });
  };
  
  const handleBalanceChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = e.target.value;
  
    // remove caracteres que não sejam números ou vírgulas
    value = value.replace(/[^\d,]/g, "");
  
    // substituir múltiplos pontos ou vírgulas
    const parts = value.split(",");
    if (parts.length > 2) {
      value = parts[0] + "," + parts.slice(1).join("");
    }
  
    setFormData((prev) => ({
      ...prev,
      minimumBalanceAmount: value,
    }));
  };  
  
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Editar Regra de Portabilidade</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        )}
        {error && <Alert variant="danger">{error}</Alert>}
        {!loading && !error && (
          <Form>
            <Form.Group controlId="originBank" className="mb-3">
              <Form.Label>Banco de Origem</Form.Label>
              <Select
                options={banks}
                placeholder="Select the bank"
                value={banks.find(
                  (option) => option.value === formData.originBank.id
                )}
                onChange={(option) =>
                  setFormData((prev) => ({
                    ...prev,
                    originBank: {
                      ...prev.originBank,
                      id: option?.value || 0,
                      bankName: option?.label || "",
                    },
                  }))
                }
              />
            </Form.Group>
            <Form.Group controlId="destinationBank" className="mb-3">
              <Form.Label>Banco de Destino</Form.Label>
              <Select
                options={banks}
                placeholder="Select the bank"
                value={banks.find(
                  (option) => option.value === formData.destinationBank.id
                )}
                onChange={(option) =>
                  setFormData((prev) => ({
                    ...prev,
                    destinationBank: {
                      ...prev.destinationBank,
                      id: option?.value || 0,
                      bankName: option?.label || "",
                    },
                  }))
                }
              />
            </Form.Group>
            <Form.Group controlId="minimumInstallments" className="mb-3">
              <Form.Label>Parcelas Minimas</Form.Label>
              <Form.Control
                type="number"
                value={formData.minimumInstallments}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    minimumInstallments: e.target.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group controlId="minimumBalanceAmount" className="mb-3">
              <Form.Label>Valor Mínimo de Saldo</Form.Label>
              <Form.Control
                type="text"
                value={formData.minimumBalanceAmount}
                onChange={handleBalanceChange}
                placeholder="R$ 0,00"
              />
            </Form.Group>

            <Form.Group controlId="loas" className="mb-3">
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  id="loasCheckbox"
                  checked={formData.loas}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      loas: e.target.checked,
                    }))
                  }
                  style={{
                    width: "25px",
                    height: "25px",
                    border: "2px solid #333",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
                <label htmlFor="loasCheckbox">LOAS</label>
              </div>
            </Form.Group>
            <Form.Group controlId="legalRepresentative" className="mb-3">
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  id="legalRepresentativeCheckbox"
                  checked={formData.legalRepresentative}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      legalRepresentative: e.target.checked,
                    }))
                  }
                  style={{
                    width: "25px",
                    height: "25px",
                    border: "2px solid #333",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
                <label htmlFor="legalRepresentativeCheckbox">Representante Legal</label>
              </div>
            </Form.Group>
            <Form.Group controlId="srcc" className="mb-3">
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  id="srccCheckbox"
                  checked={formData.srcc}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      srcc: e.target.checked,
                    }))
                  }
                  style={{
                    width: "25px",
                    height: "25px",
                    border: "2px solid #333",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
                <label htmlFor="srccCheckbox">SRCC</label>
              </div>
            </Form.Group>
            <Form.Group controlId="illiterate" className="mb-3">
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  id="illiterateCheckbox"
                  checked={formData.illiterate}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      illiterate: e.target.checked,
                    }))
                  }
                  style={{
                    width: "25px",
                    height: "25px",
                    border: "2px solid #333",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
                <label htmlFor="illiterateCheckbox">Analfabeto</label>
              </div>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPortabilityModal;