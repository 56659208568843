import { Accordion, Badge, Col, Modal, Row, Table } from 'react-bootstrap';
import PageSubTitle from '../../../PageSubTitle';
import ButtonPrimary from '../../../Buttons/ButtonPrimary';
import { BankDataEntries, ViewUnitaryProps } from './interface';
import { useState } from 'react';
import ToastWarning from '../../../ToastWarning';
import { CREATE_EXCEL_URL } from '../../../../config';

const ViewDataUnitarySimulation = ({show, onHide, simulationData}: ViewUnitaryProps) => {
    const [toastMessage, setToastMessage] = useState('');

    const formatCurrency = (value: number | string | null | undefined) => {
        if (value == null || value === undefined || value === "")
          return "Indisponível";
        const numberValue = typeof value === "string" ? parseFloat(value) : value;
        if (isNaN(numberValue)) return "Indisponível"; // Se não for um número, retorna 'Indisponível'
        return numberValue.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
    };

    const formatCPF = (cpf: string) => {
        const cleanedCPF = cpf.replace(/\D/g, '');
        return cleanedCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    };
    

    const renderSimulation = (bankKey: string, bankData: BankDataEntries) => {
        if (!bankData || !Array.isArray(bankData)) return null;
    
        return (
            <Accordion key={bankKey}>
                <Accordion.Item eventKey={bankKey} className="mb-2">
                    <Accordion.Header>
                        <span>{bankKey.toUpperCase()}</span> {/* Nome do banco no título */}
                    </Accordion.Header>
                    <Accordion.Body>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>PRODUTO</th>
                                    <th>PARCELAS</th>
                                    <th>VALOR PARCELAS</th>
                                    <th>VALOR LÍQUIDO</th>
                                    <th>VALOR BRUTO</th>
                                    <th>TAXA</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bankData.map((data, index) => (
                                    <tr key={`${bankKey}-${index}`}>
                                        {data.status === "failed" ? (
                                            <td colSpan={6}>
                                                {data.error || "Erro desconhecido"}
                                            </td>
                                        ) : (
                                            <>
                                                <td>{data.product || "Indisponível"}</td>
                                                {data.product === "Cartão RMC/RCC" || data.product === "cartão rmc" 
                                                    ? (
                                                        <>
                                                            <td>Limite: {formatCurrency(data.installmentQuantity)}</td>
                                                            <td> - </td>
                                                            <td>Saque: {formatCurrency(data.netAmount)}</td>
                                                        </> 
                                                    ) : (
                                                        <>
                                                            <td>{data.installmentQuantity || "Indisponível"}</td>
                                                            <td>{formatCurrency(data.installmentAmount)}</td>
                                                            <td>{formatCurrency(data.netAmount)}</td>
                                                        </>
                                                    )
                                                }
                                                <td>{formatCurrency(data.grossAmount)}</td>
                                                <td>{data.clientRate || "Indisponível"}</td>
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );
    }; 

    const handleExport = async () => {
        try {
            if (!simulationData || !simulationData.conditions?.conditions) {
                setToastMessage("Dados de simulação não encontrados.");
                return;
            }
    
            const exportData = Object.entries(simulationData.conditions.conditions).flatMap(
                ([bankKey, bankData]) => 
                    Array.isArray(bankData) 
                        ? bankData
                            .filter((product) => product.status === "success") 
                            .map((product) => ({
                                cpf: formatCPF(simulationData.cpf),
                                covenantDescription: simulationData.covenantDescription,
                                bank: bankKey.toUpperCase(),
                                product: product.product || "Indisponível",
                                installmentQuantity: product.product === "Cartão RMC/RCC" ? 
                                    "Limite: " + formatCurrency(product.installmentQuantity) || "Indisponível" 
                                    : product.installmentQuantity || "Indisponível",
                                installmentAmount: product.product === "Cartão RMC/RCC" ? 
                                    "-" 
                                    : formatCurrency(product.installmentAmount) || "Indisponível",
                                netAmount: product.product === "Cartão RMC/RCC" 
                                    ? "Saque: " + formatCurrency(product.netAmount) || "Indisponível" 
                                    : formatCurrency(product.netAmount) || "Indisponível",
                                grossAmount: formatCurrency(product.grossAmount) || "Indisponível",
                                clientRate: product.clientRate || "Indisponível",
                            }))
                        : []
            );

            if (exportData.length === 0) {
                setToastMessage("Nenhuma simulação com status 'success' encontrada.");
                return;
            }

            const requestBody = {
                fileName: "detalhe_unitaria",
                pageName: "Simulação Unitária",
                pageHeader: [
                    "CPF", 
                    "Convênio", 
                    "Banco", 
                    "Produto", 
                    "Parcelas", 
                    "Valor das Parcelas", 
                    "Valor Líquido", 
                    "Valor Bruto", 
                    "Taxa"
                ],
                data: exportData
            };
    
            // Envia a requisição para exportar os dados
            const res = await fetch(`${CREATE_EXCEL_URL}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });
    
            if (res.ok) {
                const blob = await res.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
    
                // Cria um link para download do arquivo
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "detalhe_unitaria.xlsx");
                document.body.appendChild(link);
                link.click();
    
                // Remove o link após o download
                if (link.parentNode) {
                    link.parentNode.removeChild(link);
                    setToastMessage('Arquivo criado com sucesso!');
                } else {
                    console.warn("link não foi adicionado corretamente");
                }
            } else {
                console.log("Erro ao criar arquivo");
                setToastMessage("Erro ao criar arquivo");
            }
        } catch (error) {
            console.error("Erro de exportação", error);
            setToastMessage("Erro ao exportar dados.");
        }
    };    

    return(
        <Modal show={show} onHide={onHide} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <PageSubTitle size="h4">Detalhes da Simulação</PageSubTitle>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-4">
                    <Col className="d-flex align-items-center">
                        <Badge bg="secondary" className="fs-6 me-2">
                            {simulationData ? formatCPF(simulationData.cpf) : ''}
                        </Badge>
                        <Badge bg="success" className="fs-6 me-2">
                            {simulationData?.registration}
                        </Badge>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <ButtonPrimary content="Exportar" onClick={handleExport} />
                    </Col>
                </Row>
                    {simulationData && simulationData.conditions?.conditions &&
                        Object.entries(simulationData.conditions.conditions).map(
                        ([bankKey, bankData]) =>
                            renderSimulation(bankKey, bankData)
                        )
                    }
            </Modal.Body>
            {toastMessage && <ToastWarning message={toastMessage} />}
        </Modal>
    )
}

export default ViewDataUnitarySimulation;