import React from 'react';
import styled from 'styled-components';

// Estilo para o container das bolinhas
const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
`;

// Estilo para cada bolinha
const ProgressDot = styled.div<{ isActive: boolean }>`
  width: 12px;
  height: 12px;
  background-color: #153B7F; // Laranja para todas as bolinhas
  border-radius: 50%;
  margin: 0 5px;
  border: 2px solid transparent; // Borda padrão invisível
  transition: border 0.3s ease;
  
  &.active {
    border: 2px solid #153B7F; // Borda laranja apenas se ativo
  }
`;

// Tipagem para as propriedades do componente ProgressIndicator
interface ProgressIndicatorProps {
  totalSteps: number;
  currentStep: number;
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ totalSteps, currentStep }) => {
  // Renderiza as bolinhas
  const dots = Array.from({ length: totalSteps }).map((_, index) => {
    // Determina se a bolinha é a ativa
    const isActive = index + 1 === currentStep;

    return (
      <ProgressDot
        key={index}
        className={isActive ? 'active' : ''} // Aplica a classe 'active' se for a bolinha ativa
      />
    );
  });

  return (
    <ProgressContainer>
      {dots}
    </ProgressContainer>
  );
};

export default ProgressIndicator;
