import { useState, useEffect } from "react";
import { Container, Table, Row, Col, Form, Pagination, Badge, Button, Spinner } from "react-bootstrap";
import Select from "react-select";
import Logo from "../../../../components/Logo";
import styles from "./TypingList.module.scss";
import axios from "axios";
import { Client } from "../../../../types/client";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import ButtonPrimary from "../../../../components/Buttons/ButtonPrimary";
import NavBarAgent from "../../../../components/NavBar/Agent_Partner";

export default function TypingList() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem("isMenuOpen") === "true");
  const [filter, setFilter] = useState<string>(""); // Filter by CPF
  const [bankFilter, setBankFilter] = useState<string | null>(null); // Bank filter
  const [clients, setClients] = useState<Client[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(5);
  const [isLoading, setIsLoading] = useState<boolean>(false); // Estado de carregamento

  const token = localStorage.getItem("token");

  const bankOptions = [
    { value: "c6", label: "C6" },
    { value: "facta", label: "Facta" },
  ];

  const fetchClients = async (params: { bankName?: string; cpf?: string } = {}) => {
    setIsLoading(true); // Inicia o carregamento
    const startTime = Date.now(); // Marca o início do carregamento

    try {
      const response = await axios.get(
        `https://acelereai.gfttech.com.br/api/v1/proposal/batch-digitation/scheduled/paged`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            limit: itemsPerPage,
            page: currentPage,
            ...params,
          },
        }
      );

      const { data, totalItems, totalPage } = response.data;

      setClients(data || []);
      setTotalPages(totalPage || 1);

      // Ajusta a página atual caso o total de páginas diminua
      if (currentPage > totalPage) {
        setCurrentPage(totalPage || 1);
      }
    } catch (error) {
      console.error("Erro ao buscar clientes:", error);
    } finally {
      const elapsedTime = Date.now() - startTime; // Tempo decorrido
      const remainingTime = Math.max(0, 500 - elapsedTime); // Garante pelo menos 2 segundos de carregamento

      setTimeout(() => {
        setIsLoading(false); // Finaliza o carregamento após 2 segundos
      }, remainingTime);
    }
  };

  const formatDate = (date: string | null) =>
    date ? dayjs(date).format("DD/MM/YYYY HH:mm") : "-";

  useEffect(() => {
    if (token) {
      const params: { bankName?: string; cpf?: string } = {};
      if (bankFilter) params.bankName = bankFilter;
      if (filter) params.cpf = filter;

      fetchClients(params);
    }
  }, [token, currentPage, bankFilter, filter]);

  const handleFilterChange = () => {
    setCurrentPage(1); // Reseta para a primeira página
    const params: { bankName?: string; cpf?: string } = {};
    if (bankFilter) params.bankName = bankFilter;
    if (filter) params.cpf = filter;

    fetchClients(params);
  };

  const translateStatus = (status: string) => {
    if (status === "PENDING") return "PENDENTE";
    if (status === "FAILED") return "FALHA";
    if (status === "SUCCESSFULLY") return "FINALIZADO";
    return "";
  };

  const getBadgeVariant = (status: string) => {
    if (status === "PENDING") return "secondary";
    if (status === "FAILED") return "danger";
    if (status === "SUCCESSFULLY") return "success";
    return "secondary";
  };

  return (
    <>
      <section className={`${styles.navbarSection} mt-5`}>
        <NavBarAgent onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <div className={styles.logoSection}>
        <Logo />
      </div>

      <Container
        className={`${styles.contentSection} ${
          menuOpen ? styles.sideBarOpen : styles.sideBarClose
        } mt-5`}
      >
        <Row className="mb-3">
          <Col md={6} className="d-flex align-items-center">
            <Form.Control
              type="text"
              placeholder="Filtrar por CPF"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleFilterChange()}
            />
          </Col>
          <Col md={6}>
            <Select
              options={bankOptions}
              placeholder="Filtrar por Banco"
              isClearable
              onChange={(selected) => {
                setBankFilter(selected ? selected.value : null);
                handleFilterChange();
              }}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              menuPlacement="auto"
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </Col>
        </Row>

        <Row className="mt-4 mb-3">
          <Col md={4}>
            <ButtonPrimary
              onClick={handleFilterChange}
              disabled={isLoading}
              content={
                isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Atualizando...
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faRefresh} className="me-2" />
                    <b>Atualizar</b>
                  </>
                )
              }
            />
          </Col>
        </Row>

        {isLoading ? (
          <div className="d-flex justify-content-center my-5">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Carregando...</span>
            </Spinner>
          </div>
        ) : (
          <Table className={`${styles.tableStyle} table table-responsive`}>
            <thead>
              <tr>
                <th>DATA</th>
                <th>BANCO</th>
                <th>CPF</th>
                <th>STATUS</th>
                <th>PMT</th>
                <th>OBS</th>
              </tr>
            </thead>
            <tbody>
              {clients.map((client, index) => (
                <tr key={index}>
                  <td>{formatDate(client.date)}</td>
                  <td>{client.bankName}</td>
                  <td>{client.cpf}</td>
                  <td>
                    <Badge bg={getBadgeVariant(client.status)}>
                      {translateStatus(client.status)}
                    </Badge>
                  </td>
                  <td>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(client.valor_parcela)}
                  </td>
                  <td>{client.errorMessage}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Pagination>
          {totalPages <= 10 ? (
            Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))
          ) : (
            <>
              {/* Exibe a primeira página */}
              <Pagination.Item
                key={1}
                active={1 === currentPage}
                onClick={() => setCurrentPage(1)}
              >
                1
              </Pagination.Item>

              {/* Exibe reticências se a página atual for maior que 4 */}
              {currentPage > 4 && <Pagination.Ellipsis />}

              {/* Exibe páginas próximas à atual (3 antes e 3 depois) */}
              {Array.from({ length: 7 }, (_, index) => {
                const page = currentPage - 3 + index;
                if (page > 1 && page < totalPages) {
                  return (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  );
                }
                return null;
              })}

              {/* Exibe reticências se a página atual for menor que o total de páginas menos 3 */}
              {currentPage < totalPages - 3 && <Pagination.Ellipsis />}

              {/* Exibe a última página */}
              <Pagination.Item
                key={totalPages}
                active={totalPages === currentPage}
                onClick={() => setCurrentPage(totalPages)}
              >
                {totalPages}
              </Pagination.Item>
            </>
          )}
        </Pagination>
      </Container>
    </>
  );
}
