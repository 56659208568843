import styled from "styled-components";
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  max-height: 500px;
  overflow-y: auto;
  padding: 1rem;
`;

export const CovenantCard = styled(Col)`
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  text-align: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 1rem #F28705;
  }

  &.selected {
    box-shadow: 0 0 1rem #F28705;
  }

  @media(max-width: 480px) {
    width: 70%;
  }
  @media(max-width: 764px) {
    width: 80%;
  }
`;

export const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: white;
  background: gray;
  padding: 3px;
  border-radius: 50%;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 1.25rem;

  &.selected {
    background-color: #153B7F;
  }
`;

export const CovenantName = styled.div`
  font-size: 1rem;
  margin-top: 0.5rem;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
  color: #F28705;
`;

export const CovenantLogo = styled.img`
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 0.5rem;
`;

export const SelectedCount = styled.div`
  font-size: 1.1rem;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
  color: #732C02;
  text-align: right;
  margin-bottom: 1rem;
`;
