import React from 'react';
import styles from './PaginationRequestTyping.module.scss';

interface PaginationProps {
  currentPage: number;
  lastPage: number;
  firstPageUrl: string;
  nextPageUrl: string | null;
  prevPageUrl: string | null;
  pageUrls: { url: string | null, label: string, active: boolean }[]; // URLs das páginas
  onPageChange: (page: number) => void; // Função para mudar de página
}

const PaginationRequestTyping: React.FC<PaginationProps> = ({
  currentPage,
  lastPage,
  firstPageUrl,
  nextPageUrl,
  prevPageUrl,
  pageUrls,
  onPageChange,
}) => {
  // Filtra os links "Previous" e "Next" do array pageUrls
  const filteredPageUrls = pageUrls.filter(link => link.label !== "&laquo; Previous" && link.label !== "Next &raquo;");

  return (
    <nav className='mb-5'>
      <ul className={`pagination ${styles.pagination}`}>
        {/* Botão "Previous" */}
        <li className={`page-item ${!prevPageUrl ? 'disabled' : ''}`}>
          <button
            className={`page-link ${styles.pageLink}`}
            onClick={() => prevPageUrl && onPageChange(currentPage - 1)}
            disabled={!prevPageUrl}
          >
            &laquo; Anterior
          </button>
        </li>

        {/* Links de Páginas filtrados */}
        {filteredPageUrls.map((link, index) => (
          <li
            key={index}
            className={`page-item ${currentPage === Number(link.label) ? 'active' : ''}`}
          >
            <button
              className={`page-link ${styles.pageLink}`}
              onClick={() => link.url && onPageChange(Number(link.label))}  // Passando a página selecionada
              disabled={currentPage === Number(link.label)}  // Desabilitar o botão da página ativa
            >
              {link.label}
            </button>
          </li>
        ))}

        {/* Botão "Next" */}
        <li className={`page-item ${!nextPageUrl ? 'disabled' : ''}`}>
          <button
            className={`page-link ${styles.pageLink}`}
            onClick={() => nextPageUrl && onPageChange(currentPage + 1)}
            disabled={!nextPageUrl}
          >
            Próximo &raquo;
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default PaginationRequestTyping;
