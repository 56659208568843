import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity, faBalanceScale, faExchangeAlt, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import Banks from './Banks';
import FactorList from './Factor';
import PortabilityList from './Portabilities';

const TabsParameters = () => {
  return (
    <div className="container mt-4">
      <Tabs defaultActiveKey="bancos" id="tabs-organizer" className="mb-3">
        <Tab
          eventKey="bancos"
          title={
            <span>
              <FontAwesomeIcon icon={faUniversity} className="me-2" />
              Bancos
            </span>
          }
        >
          <div className="p-3">
            <Banks/>
          </div>
        </Tab>

        <Tab
          eventKey="fatores"
          title={
            <span>
              <FontAwesomeIcon icon={faBalanceScale} className="me-2" />
              Fatores
            </span>
          }
        >
          <div className="p-3">
            <FactorList/>
          </div>
        </Tab>

        <Tab
          eventKey="portabilidades"
          title={
            <span>
              <FontAwesomeIcon icon={faMoneyBillTransfer} className="me-2" />
              Regras
            </span>
          }
        >
          <div className="p-3">
            <PortabilityList/>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default TabsParameters;
