import { useState } from 'react';
import { Col, Row, Container, Button } from 'react-bootstrap';
import styles from './AccountTabPane.module.scss';
import PersonalData from './PersonalData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import ChangePassword from './ChangePassword';
import BanksAccess from './BanksAccess';

const AccountTabPane = () => {
    const [activeTab, setActiveTab] = useState<'dados' | 'conta' | 'acessos'>('dados');

    const handleTabChange = (tab: 'dados' | 'conta' | 'acessos') => {
        setActiveTab(tab);
    };

    return (
        <Container fluid className={styles.mainContainer}>
            <Row className="align-items-start">
                <Col xs={12} md={4} lg={3} className={styles.sidebar}>
                    <div className={styles.profileContainer}>
                        <div className={styles.profilePicture}>
                            <FontAwesomeIcon icon={faUser} className={styles.iconUser} />
                        </div>
                    </div>
                    <div className={styles.navButtons}>
                        <Button
                            variant={activeTab === 'dados' ? 'primary' : 'light'}
                            onClick={() => handleTabChange('dados')}
                            className={`${styles.navButton} ${activeTab === 'dados' ? styles.active : ''}`}
                        >
                            Dados Pessoais
                        </Button>
                        <Button
                            variant={activeTab === 'conta' ? 'primary' : 'light'}
                            onClick={() => handleTabChange('conta')}
                            className={`${styles.navButton} ${activeTab === 'conta' ? styles.active : ''}`}
                        >
                            Conta
                        </Button>
                        <Button
                            variant={activeTab === 'acessos' ? 'primary' : 'light'}
                            onClick={() => handleTabChange('acessos')}
                            className={`${styles.navButton} ${activeTab === 'acessos' ? styles.active : ''}`}
                        >
                            Meus Acessos
                        </Button>
                    </div>
                </Col>
                
                {/* Linha Vertical */}
                <Col xs={1} className={styles.verticalLine}></Col>

                {/* Conteúdo Principal */}
                <Col xs={12} md={7} lg={8} className={styles.contentContainer}>
                    {activeTab === 'dados' && <PersonalData />}
                    {activeTab === 'conta' && <ChangePassword />}
                    {activeTab === 'acessos' && <BanksAccess />}
                </Col>
            </Row>
        </Container>
    );
}

export default AccountTabPane;
