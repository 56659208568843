import React, { useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import styles from './ForgotMyPassword.module.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import leroError from '../../../../assets/images/lerocomduvida.png'
import ButtonLogin from '../../../Buttons/ButtonLogin';
import ButtonPrimary from '../../../Buttons/ButtonPrimary';

interface ForgotMyPasswordProps {
  show: boolean;
  onClose: () => void;
}

const ForgotMyPassword: React.FC<ForgotMyPasswordProps> = ({ show, onClose }) => {
  const [email, setEmail] = useState(''); 
  
  const emailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handdleSend = () => {

  }

  return (
    <Modal className={styles.modalGeral} show={show} onHide={onClose}>
      <Modal.Header className={styles.modalHeader} closeButton>
        <Modal.Title>Recuperação de senha</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <div className={styles.rowLero}>
            <div className='d-flex justify-content-center'>
              <img className={styles.imageModal} src={leroError}/>
            </div>
        </div>
        <h5 className='text-center mt-4'>
          Por gentileza, informe o e-mail cadastrado para iniciarmos o processo de recuperação.
        </h5>
        <div className='mt-5'>
          <div className={`${styles.floatingLabel}`}>
              <input
                  type="email"
                  id="email"
                  required
                  placeholder=" "
                  value={email}
                  onChange={emailChange}
                  className={`form-control ${styles.input}`}
              />
              <label htmlFor="email">Email</label>
          </div>
          <div className="mt-5 text-center">
            <ButtonPrimary
                onClick={handdleSend}
                content="Enviar"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotMyPassword;
