import React, { useState } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import Bank from '../../../../../../interfaces/BanksPortability';

interface DeleteBankModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  bank: Bank | null;
}

const DeleteBankModal: React.FC<DeleteBankModalProps> = ({
  show,
  onHide,
  onConfirm,
  bank,
}) => {
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    if (!bank?.id) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`https://bi.gfttech.com.br/port/banks/${bank.id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setIsSuccess(true); // Sucesso na exclusão
        onConfirm(); // Chama a função de confirmação após a exclusão
        setTimeout(() => onHide(), 2000); // Fecha o modal após 2 segundos
      } else {
        setIsSuccess(false); // Falha na exclusão
      }
    } catch (error) {
      console.error('Erro ao excluir o banco:', error);
      setIsSuccess(false); // Falha na requisição
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Excluir Banco</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isSuccess !== null && (
          <Alert variant={isSuccess ? 'success' : 'danger'}>
            {isSuccess ? 'Banco excluído com sucesso!' : 'Erro ao excluir o banco. Tente novamente.'}
          </Alert>
        )}
        {!isSuccess && (
          <div>
            Tem certeza que deseja excluir o banco <strong>{bank?.bankName}</strong>?
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isSuccess === null && !isLoading && (
          <>
            <Button variant="secondary" onClick={onHide}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Confirmar
            </Button>
          </>
        )}
        {isLoading && <Button variant="secondary" disabled>Carregando...</Button>}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteBankModal;
