
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './SelectProductInss.module.scss';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import NavBar from '../../../../components/NavBar';
import PageTitle from '../../../../components/PageTitle';
import PageSubTitle from '../../../../components/PageSubTitle';
import ProductList from '../../../Query/UnitaryQuery/ProductListSimulation';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import ToastValidationSimulation from '../../../../components/ToastValidationSimulation';
import Logo from '../../../../components/Logo';

export default function ProductSelectionInssBatch() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [toastMessage, setToastMessage] = useState('');
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);

  const subtitleTextClient = "Revise os dados e selecione os produtos.";

  localStorage.removeItem("processedCpfs")

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem('formData') || '{}');
    if (selectedProductId) {
      formData.serviceCode = "010";
      formData.produto = selectedProductId;
      localStorage.setItem('formData', JSON.stringify(formData));
    }
  }, [selectedProductId]);

  const handleProductSelection = (selectedId: string) => {
    setSelectedProductId(selectedId);
    setToastMessage('');

    if (selectedId === 'NORMAL') {
      localStorage.setItem('formData', JSON.stringify({ serviceCode: '010', produto: selectedId }));
    }
  };


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedProductId) {
      setToastMessage('Por favor, selecione um produto antes de prosseguir.');
      return;
    }

    window.location.href = '/ConsultaLote/Inss/SelecaoBancos';
  };

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Logo/>
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={`${styles.titleSection} mt-5`}>
          <PageTitle size="h2">Consulta em Lote</PageTitle>
        </div>
        <div className={`${styles.subTitleSection} mb-5`}>
          <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
        </div>
        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <ProductList 
            onProductSelect={handleProductSelection}
            selectedProductId={selectedProductId}
           
          />

          <Row className='mt-5'>
            
            <Col className='d-flex justify-content-end mt-5'>
              <ButtonPrimary 
                content={<><span>Prosseguir</span> <FontAwesomeIcon icon={faArrowRight} /></>} 
                onClick={handleSubmit}
              />
            </Col>
          </Row>
         
        </Container>
        {toastMessage && <ToastValidationSimulation message={toastMessage} />}
      </motion.div>
    </>
  );
}
