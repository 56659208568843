import { useEffect, useState } from "react";
import { Col, Row, Toast } from "react-bootstrap";
import styles from './ToastValidationSimulation.module.scss';

interface ToastValidationSimulationProps {
  message: string;
}

export default function ToastValidationSimulation({ message }: ToastValidationSimulationProps) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (message) {
      setShow(true);
      const timer = setTimeout(() => setShow(false), 3000); // Fechar o toast após 3 segundos
      return () => clearTimeout(timer);
    } else {
      setShow(false);
    }
  }, [message]);

  return (
    <Row>
      <Col xs={6}>
        <Toast className={styles.toast} onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">Valide os dados!</strong>
          </Toast.Header>
          <Toast.Body>
            {message}
          </Toast.Body>
        </Toast>
      </Col>
    </Row>
  );
}
