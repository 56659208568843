import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Router from './routes';
import AppWrapper from './components/AppWrapper';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
     <AppWrapper>
        <Router />
     </AppWrapper>
  </React.StrictMode>
);
