import { useState } from 'react';
import NavBar from '../../components/NavBar';
import ExtractMoves from './Extract_Moves';
import QuickAccess from './Quick_Access';
import { Col, Row } from 'react-bootstrap';
import styles from './GeneralPanel.module.scss';
import Logo from '../../components/Logo';

export default function GeneralPanel() {
    const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');

    return (
        <>
            <section className={styles.navbarSection}>
                <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)}/>
            </section>
            <div className={styles.logoSection}>
                <Logo />
            </div>
            <Row className={`justify-content-center align-self-center ${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
                <Col md={6} className={`${menuOpen ? styles.open : styles.close}`}>
                    <ExtractMoves />
                </Col>
                <Col md={6} className={`${menuOpen ? styles.open : styles.close}`}>
                    <QuickAccess />
                </Col >
            </Row>
        </>
    );
}
