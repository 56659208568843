import React, { useEffect, useState } from 'react';
import BlockPrint from '../Modals/Others/BlockPrint';

const AppWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [showBlockPrint, setShowBlockPrint] = useState(false);

  useEffect(() => {
    const handlePrintScreen = (e: KeyboardEvent) => {
      
      if (e.key === "PrintScreen") {
        e.preventDefault(); // Evita a ação de print
        setShowBlockPrint(true); // Mostra o modal
      }
      if (e.ctrlKey && e.key === 's') {
        e.preventDefault(); // Impede o comportamento de salvar a página
        setShowBlockPrint(true); // Mostra o modal
      }
      // Detecta Ctrl + P (atalho para impressão)
      else if (e.ctrlKey && e.key === 'p') {
        e.preventDefault(); // Impede a ação de imprimir
        setShowBlockPrint(true); // Mostra o modal
      }
    };

    // Escuta eventos de teclado
    window.addEventListener('keydown', handlePrintScreen);

    // Limpeza ao desmontar o componente
    return () => {
      window.removeEventListener('keydown', handlePrintScreen);
    };
  }, []);

  return (
    <>
      {children}
      <BlockPrint show={showBlockPrint} onClose={() => setShowBlockPrint(false)} />
    </>
  );
};

export default AppWrapper;
