import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

interface Props {
  show: boolean;
  onHide: () => void;
  portabilityId: number | null;
  onDelete: () => void;
}

const DeletePortabilityModal: React.FC<Props> = ({ show, onHide, portabilityId, onDelete }) => {
  const handleDelete = async () => {
    if (!portabilityId) return;
    try {
      await axios.delete(`https://bi.gfttech.com.br/port/portabilities/${portabilityId}`);
      onDelete();
    } catch (error) {
      console.error('Erro ao excluir portabilidade:', error);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Excluir Portabilidade</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Tem certeza de que deseja excluir esta portabilidade?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Excluir
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeletePortabilityModal;