import React, { useState, useEffect } from 'react';
import { Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import styles from './SendFile.module.scss';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import { URL_SIMULATIONS } from '../../../../config';
import Logo from '../../../../components/Logo';
import PageTitle from '../../../../components/PageTitle';
import PageSubTitle from '../../../../components/PageSubTitle';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import ToastValidationSimulation from '../../../../components/ToastValidationSimulation';
import ModalErrorBatchFile from '../../../../components/Modals/Errors/ModalErrorBatchFile';
import Select from 'react-select';
import C6Tables from '../../../../DataMocks/C6Tables/tables.json'
import C6T from '../../../../interfaces/C6Tables';
import NavBarAgent from '../../../../components/NavBar/Agent_Partner';

export default function SendFileInssBatchTyping() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [toastMessage, setToastMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [uniqueCpfCount, setUniqueCpfCount] = useState<number | null>(null);
  const [selectedBank, setSelectedBank] = useState<string | null>(null);
  const [typeOperations, setTypeOperations] = useState<string | null>(null);
  const [formalizationType, setFormalizationType] = useState<string | null>(null); // Para o banco "c6"
  const [tableType, setTableType] = useState<string | null>(null); // Para o banco "facta"
  const [tableTypeC6, setTableTypeC6] = useState<string | null>(null);
  const [factaOptions, setFactaOptions] = useState<{ value: string; label: string }[]>([]);
  const [isLoadingTable, setIsLoadingTable] = useState(true); // Estado para o carregamento


  const subtitleTextClient = "Anexe o arquivo conforme o modelo padrão.";

  useEffect(() => {
    const bankNames = JSON.parse(localStorage.getItem("selectedBankNamesTyping") || "[]");
    if (Array.isArray(bankNames) && bankNames.length > 0) {
      setSelectedBank(bankNames[0]?.toString().toLowerCase());
    }
  }, []);

  useEffect(() => {
    const fetchFactaOptions = async () => {
      setIsLoadingTable(true); 
      try {
        const token = localStorage.getItem('token'); // Obter o token do localStorage
        if (!token) {
          console.error('Token não encontrado no localStorage.');
          return;
        }
  
        const response = await axios.get(
          'https://acelereai.gfttech.com.br/api/v2/banking-services/facta/proposal/tables',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (response.status === 200) {
          const options = response.data.map((table: any) => ({
            value: table.codTabela,
            label: `${table.tabela}`,
          }));
          setFactaOptions(options);
        } else {
          console.error('Erro ao buscar tabelas Facta:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao buscar tabelas Facta:', error);
      } finally{
        setIsLoadingTable(false); 
      }
    };
  
    fetchFactaOptions();
  }, []);
  

  const handleBack = (e: React.FormEvent) => {
    window.location.href = '/DigitacoesLote/INSS/SelecaoBancos';
  };

  const handleDownloadExample = async () => {
    try {
      if (!selectedBank) {
        setToastMessage("Nenhum banco selecionado.");
        return;
      }

      const response = await axios.post(
        `${URL_SIMULATIONS}/proposal/batch-digitation/build-document`,
        null,
        {
          params: { bankName: selectedBank },
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Template-Digitacao-Em-Lote.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        setToastMessage("Erro ao baixar o modelo.");
        console.log(response.status);
      }
    } catch (error) {
      setToastMessage("Erro inesperado ao tentar realizar o download.");
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setSelectedFile(file);
        processFile(file);
        setShowErrorModal(false);
      } else {
        setShowErrorModal(true);
        setSelectedFile(null);
      }
    }
  };

  const processFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];

      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as unknown[];
      const cpfColumnIndex = 0;

      const cpfs = (jsonData as (string[] | any[])[])
        .slice(1)
        .map((row: any[]) => row[cpfColumnIndex])
        .filter((cpf: string | undefined) => cpf);

      const uniqueCpfs = new Set(cpfs);
      setUniqueCpfCount(uniqueCpfs.size);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!selectedFile || uniqueCpfCount === null) {
      setToastMessage('Selecione algum arquivo para prosseguirmos.');
      return;
    }
  
    // Verificar se o banco foi selecionado e o tipo de formalização ou tabela
    if ((selectedBank === 'c6' && !formalizationType) || (selectedBank === 'facta' && !tableType)) {
      setToastMessage('Selecione uma opção válida no campo de seleção.');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', selectedFile);
  
    const token = localStorage.getItem('token');
  
    try {
      let apiUrl = 'https://acelereai.gfttech.com.br/api/v1/proposal/batch-digitation/upload-document';
  
      // Ajustar a URL de acordo com o banco selecionado
      let params: { [key: string]: string } = {};
  
      if (selectedBank === 'c6') {
        params = 
        {
          bankName: 'c6',
          tipoFormalizacao: formalizationType!,
          codTabela: tableTypeC6!,
          tipoProduto: 'AUMENTO'
        };
      } else if (selectedBank === 'facta') {
        params = 
        { 
          bankName: 'facta',
          tipoOperacao: typeOperations!,
          codTabela: tableType! 
        };
      }      
      console.log(apiUrl + params)

      const response = await axios.post(apiUrl, formData, {
        params,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200) {
        alert(response.data.message);
        window.location.reload();
      } else {
        setToastMessage(response.data.message || 'Erro ao enviar a planilha.');
      }
    } catch (error) {
      setToastMessage('Erro ao realizar a requisição.');
    }
  };
  
  const bankName = () => {
    let bankNameExib = "";
    if (selectedBank === 'c6') {
      bankNameExib = "Banco C6";
    } else if (selectedBank === 'facta') {
      bankNameExib = "Banco Facta";
    }
    return bankNameExib;
  };


  const formalizationOptions = [
    { value: 'DIGITAL_WEB', label: 'DIGITAL_WEB' },
    { value: 'PLUS', label: 'PLUS' },
  ];

  const typeOperationsOptions = [
    { value: 'NOVO', label: 'NOVO' },
  ];


  const c6Tables = C6Tables.map((table: C6T) => ({
    value: table.codigoTabela,
    label: table.descricaoTabela
  }));

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBarAgent onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <div className={styles.logoSection}>
        <Logo />
      </div>

      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={styles.titlesSection}>
          <div className={`${styles.titleSection} mt-5`}>
            <PageTitle size="h2">Digitação em Lote - {bankName()}</PageTitle>
          </div>
          <div className={`${styles.subTitleSection} mb-3`}>
            <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
          </div>
        </div>

        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Row className='mt-5'>
            <Col md={4} sm={12}>
              <ButtonPrimary content={<><FontAwesomeIcon icon={faDownload} /> Baixar modelo padrão</>} onClick={handleDownloadExample} />
            </Col>
          </Row>

          <Row className='mt-4 mb-5'>
            <Col md={4} sm={12}>
              <Form.Group controlId="formFile" className="mb-3">
                {selectedBank === 'c6' && (
                  <>
                    <div>
                      <Select
                        options={formalizationOptions}
                        value={formalizationType ? { value: formalizationType, label: formalizationType } : null}
                        onChange={(e: any) => setFormalizationType(e?.value || null)}
                        placeholder="Selecione a formalização"
                      />
                    </div>
                    <div className='mt-4'>
                      <Select
                        options={c6Tables}
                        value={c6Tables.find(option => option.value === tableTypeC6) || null}
                        onChange={(e: any) => setTableTypeC6(e?.value || null)}
                        placeholder="Selecione a tabela"
                      />
                    </div>
                  </>
                )}
                {selectedBank === 'facta' && (
                  <>
                    <div>
                      <Select
                        options={typeOperationsOptions}
                        value={typeOperations ? { value: typeOperations, label: typeOperations } : null}
                        onChange={(e: any) => setTypeOperations(e?.value || null)}
                        placeholder="Selecione a Operação"
                      />
                    </div>
                    <div className='mt-4'>
                    <Select
                      options={factaOptions}
                      value={factaOptions.find(option => option.value === tableType) || null}
                      onChange={(e: any) => setTableType(e?.value || null)}
                      placeholder={isLoadingTable ? 'Carregando tabelas...' : 'Selecione a tabela'}
                      isDisabled={isLoadingTable} // Desativa o Select enquanto carrega
                      noOptionsMessage={() =>
                        isLoadingTable ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <Spinner animation="border" size="sm" role="status" className="me-2" />
                            Carregando...
                          </div>
                        ) : (
                          'Nenhuma tabela disponível'
                        )
                      }
                    />
                    </div>
                  </>
                )}
                <Form.Control
                  className='mt-5'
                  type="file"
                  onChange={handleFileChange}
                  disabled={!formalizationType && selectedBank  === 'c6' || !typeOperations && selectedBank  === 'facta'}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={12}>
              <ButtonPrimary
                content={<><FontAwesomeIcon icon={faArrowLeft} /> Voltar</>}
                onClick={handleBack}
              />
            </Col>
            <Col md={6} sm={12} className='d-flex justify-content-end'>
              <ButtonPrimary
                content={<><FontAwesomeIcon icon={faArrowRight} /> Próximo</>}
                onClick={handleSubmit}
              />
            </Col>
          </Row>
        </Container>
      </motion.div>

      {toastMessage && <ToastValidationSimulation message={toastMessage} />}
      {showErrorModal && <ModalErrorBatchFile show={showErrorModal} onClose={() => setShowErrorModal(false)} />}
    </>
  );
}
