import React from 'react';
import styles from './ProcessingQueue.module.scss';

interface ProcessingQueueProps {
  totalCPFs: number;
  processedCPFs: number;
}

export default function ProcessingQueue({ totalCPFs, processedCPFs }: ProcessingQueueProps) {
  const percentage = (processedCPFs / totalCPFs) * 100;

  return (
    <div className={styles.queueContainer}>
      <div className={styles.progressBar}>
        <div className={styles.progress} style={{ width: `${percentage}%` }}>
          {Math.round(percentage)}%
        </div>
      </div>
      <div className={styles.textInfo}>
        {processedCPFs} de {totalCPFs} CPFs processados
      </div>
    </div>
  );
}
