import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container, Badge } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './ResultSimulation.module.scss';
import { motion } from 'framer-motion';
import inssLogo from '../../../../assets/images/inss-logo.png';
import panLogo from '../../../../assets/images/banco pan.png';
import bmgLogo from '../../../../assets/images/banco bmg.png';
import c6Logo from '../../../../assets/images/banco c6.png';
import masterLogo from '../../../../assets/images/banco master.png';
import queromaisLogo from '../../../../assets/images/queromaislogo.png';
import daycovalLogo from '../../../../assets/images/daycoval-logo.png'
import PageTitle from '../../../../components/PageTitle';
import PageSubTitle from '../../../../components/PageSubTitle';
import NavBar from '../../../../components/NavBar';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faRotateRight, faSearchDollar } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Logo from '../../../../components/Logo';
import { URL_SIMULATIONS } from '../../../../config';

const ResultSimulationInss: React.FC = () => {
  const [result, setResult] = useState<any>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const subtitleTextClient = "Retorno da sua Consulta.";
  const [opportunityCount, setOpportunityCount] = useState<number>(0);

  // Pegar parâmetro iS da URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const simulationId = searchParams.get('iS');

  useEffect(() => {
    if (simulationId) {
      fetch(`${URL_SIMULATIONS}/proposal/simulation/find-by-id?id=${simulationId}`)
        .then(response => response.json())
        .then(data => {
          if (data && data.length > 0 && data[0]?.conditions) {
            const conditions = data[0].conditions.conditions;
            const margin = data[0].conditions.margin;
            setResult({ conditions, margin });
  
            let totalOpportunities = 0;
  
            if (conditions.bmg && Array.isArray(conditions.bmg)) {
              totalOpportunities += conditions.bmg.filter((bmg: any) => bmg.status === 'success').length;
            }
            if (conditions.pan && Array.isArray(conditions.pan)) {
              totalOpportunities += conditions.pan.filter((pan: any) => pan.status === 'success').length;
            }
            if (conditions.c6 && Array.isArray(conditions.c6)) {
              totalOpportunities += conditions.c6.filter((c6: any) => c6.status === 'success').length;
            }
  
            if (conditions.master && Array.isArray(conditions.master)) {
              totalOpportunities += conditions.master.filter((master: any) => master.status === 'success').length;
            }

            if (conditions.daycoval && Array.isArray(conditions.daycoval)) {
              totalOpportunities += conditions.daycoval.filter((daycoval: any) => daycoval.status === 'success').length;
            }
  
            setOpportunityCount(totalOpportunities);
          } else {
            setResult(null);
            setOpportunityCount(0);
          }
        })
        .catch(error => console.error('Erro ao buscar dados:', error));
    }
    
  }, [simulationId]);

  
  const getLogoPath = (bank: string) => {
    switch (bank) {
      case 'bmg': return bmgLogo;
      case 'master': return masterLogo;
      case 'c6': return c6Logo;
      case 'pan': return panLogo;
      case 'queromais': return queromaisLogo;
      case 'daycoval': return daycovalLogo;
      default: return '';
    }
  };

  const formatCurrency = (value: any) => {
    const numberValue = parseFloat(value);
    return isNaN(numberValue)
      ? '0'
      : `R$ ${numberValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  const handleNewSimulation = () => {
    window.location.href = "/ConsultaUnitaria/Inss/DadosCliente";
  };

  const handleHomeInss = () => {
    window.location.href = "/ConsultaUnitaria/Inss/SelecaoBancos";
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <div className={styles.logoSection}>
        <Logo/>
      </div>
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={styles.titlesSection}>
          <div className={`${styles.titleSection}`}>
            <PageTitle size="h2">Consulta Unitária</PageTitle>
          </div>
          <div className={`${styles.subTitleSection} mb-5`}>
            <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
          </div>
        </div>
        
        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Row>
            <Col className="d-flex justify-content-start">
              <Badge bg="success" className={`${styles.oportunityBage} me-2`}>
                <FontAwesomeIcon icon={faSearchDollar} /> Você tem {opportunityCount} oportunidade(s) de negócio!
              </Badge>
            </Col>
            <Col className="d-flex justify-content-end">
              <Badge bg="primary" className={`${styles.badgeMargin} me-2`}>Margem: {formatCurrency(result?.margin?.availableMargin)}</Badge>
              <Badge bg="secondary" className={`${styles.badgeCardMargin} me-2`}>Margem Cartão: {formatCurrency(result?.margin?.availableMarginCard)}</Badge>
              <Badge bg="success" className={`${styles.badgeBenefitTypeNumber} me-2`}>Tipo de Benefício: {result?.margin?.benefitTypeNumber}</Badge>
            </Col>
          </Row>

          <Row className={`${styles.resultSimulation} mt-5`}>
            <Carousel responsive={responsive}>

              {['bmg', 'c6', 'pan','master', 'queromais', 'daycoval'].map((bank) => (
                Array.isArray(result?.conditions?.[bank]) && result.conditions[bank].map((item: any, index: number) => (
                  item.status === "success" && item.product !== "Cartão RMC/RCC" && (
                    <Col xs={12} sm={6} md={4} key={index} className="mb-3">
                      <Card className={styles.cardContainer}>
                        <Card.Header className={styles.cardHeader}>
                          {item.product}
                        </Card.Header>
                        <Card.Body className={styles.cardBody}>
                          <div className={styles.logoContainer}>
                            <img src={getLogoPath(bank)} alt={`${bank.toUpperCase()} logo`} className={styles.bankLogo} />
                            <img src={inssLogo} alt="INSS logo" className={styles.covenantLogo} />
                          </div>
                          <div className={styles.netAmount}>
                            {formatCurrency(item.netAmount)}
                          </div>
                          <Container className={styles.textAmount}>
                            <p>{item.installmentQuantity} parcelas de {formatCurrency(item.installmentAmount)}</p>
                            <p>{Number(item.clientRate).toFixed(1)}% ao mês</p>
                          </Container>
                        </Card.Body>
                      </Card>
                    </Col>
                  )
                ))
              ))}

              {/*Cartão RMC/RCC */}
              {['bmg', 'c6', 'master','pan', 'queromais', 'daycoval'].map((bank) => (
                Array.isArray(result?.conditions?.[bank]) && result.conditions[bank].map((item: any, index: number) => (
                  item.status === "success" && item.product === "Cartão RMC/RCC" && (
                    <Col xs={12} sm={6} md={4} key={`rmc-${index}`} className="mb-3">
                      <Card className={styles.cardContainer}>
                        <Card.Header className={styles.cardHeader}>
                          {item.product}
                        </Card.Header>
                        <Card.Body className={styles.cardBody}>
                          <div className={styles.logoContainer}>
                            <img src={getLogoPath(bank)} alt={`${bank.toUpperCase()} logo`} className={styles.bankLogo} />
                            <img src={inssLogo} alt="INSS logo" className={styles.covenantLogo} />
                          </div>
                          <div className={styles.netAmount}>
                            {formatCurrency(item.netAmount)}
                          </div>
                          <div className={styles.textAmount}>
                          <p>Limite de R$ {formatCurrency(item.installmentQuantity)}</p>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  )
                ))
              ))}
            </Carousel>
          </Row>

          <Row className='mt-5'>
            <Col className='d-flex justify-content-end gap-4'>
              <ButtonPrimary 
                onClick={handleHomeInss}
                content={
                  <>
                    <FontAwesomeIcon className='me-2' icon={faHome}/>
                    <span>Inicio INSS</span>
                  </>
                }
              />
              <ButtonPrimary 
                onClick={handleNewSimulation}
                content={
                  <>
                    <FontAwesomeIcon className='me-2' icon={faRotateRight}/>
                    <span>Nova Simulação</span>
                  </>
                }
              />
            </Col>
          </Row>
        </Container>
      </motion.div>
    </>
  );
};

export default ResultSimulationInss;