import React from 'react';
import styles from '../SearchField.module.scss';
import { Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface SearchFieldProps {
    search: string;
    placeholder?: string;
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchField: React.FC<SearchFieldProps> = ({ search, placeholder, onSearchChange }) => {

    const clearSearch = () => {
        onSearchChange({
            target: { value: '' }, //insere uma string vazia para limpar o campo de pesquisa
        } as React.ChangeEvent<HTMLInputElement>);
    }

    return(
        <Row className="d-flex justify-content-center">
            <div className={`${styles.inputWrapper} col-sm-6`}>
                <Form.Control
                    type="text"
                    placeholder={placeholder}
                    className={`${styles.inputText} mb-5`}
                    value={search}
                    onChange={onSearchChange}
                />
                {search && (
                    <FontAwesomeIcon 
                        icon={faXmark}
                        className={styles.clearIcon}
                        onClick={clearSearch}
                    />
                )}
            </div>
        </Row>
    )
}

export default SearchField;