import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import c6Logo from '../../../../assets/images/banco c6.png';
import panLogo from '../../../../assets/images/banco pan.png';
import masterLogo from '../../../../assets/images/banco master.png';
import bmgLogo from '../../../../assets/images/banco bmg.png';

const BankCard = styled(Col)`
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 35px;
  padding: 1rem;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  text-align: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 1rem #F28705;
  }

  &.selected {
    box-shadow: 0 0 1rem #F28705;
  }
`;

const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: white;
  background: gray;
  padding: 3px;
  border-radius: 50%;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 1.25rem;

  &.selected {
    background-color: #153B7F;
  }
`;

const BankName = styled.div`
  font-size: 1rem;
  margin-top: 0.5rem;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
  color: #F28705;
`;

const BankLogo = styled.img`
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 0.5rem;
`;

const SelectedCount = styled.div`
  font-size: 1rem;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
  color: #F28705;
  text-align: center;
  margin-bottom: 1rem;
`;

const BankListSimulationBatch = ({ isSelectAll, onSelectionChange }: { isSelectAll: boolean, onSelectionChange: (selected: string[]) => void }) => {
  const [selectedBanks, setSelectedBanks] = useState<string[]>([]);

  const banks = [
    { id: 'c6', logo: c6Logo, name: 'Banco C6' },
    { id: 'pan', logo: panLogo, name: 'Banco Pan' },
    { id: 'master', logo: masterLogo, name: 'Banco Master' },
    { id: 'bmg', logo: bmgLogo, name: 'Banco BMG' }
  ];

  useEffect(() => {
    // Recupera a lista de bancos selecionados do local storage
    const savedSelectedBanks = JSON.parse(localStorage.getItem('selectedBanks') || '[]');
    setSelectedBanks(savedSelectedBanks);
  }, []);

  useEffect(() => {
    // Atualiza o local storage sempre que selectedBanks mudar
    localStorage.setItem('selectedBanks', JSON.stringify(selectedBanks));
    onSelectionChange(selectedBanks);
  }, [selectedBanks, onSelectionChange]);

  useEffect(() => {
    setSelectedBanks(isSelectAll ? banks.map(bank => bank.id) : []);
  }, [isSelectAll]);

  const handleSelectBank = (bankId: string) => {
    setSelectedBanks(prevSelected =>
      prevSelected.includes(bankId)
        ? prevSelected.filter(id => id !== bankId)
        : [...prevSelected, bankId]
    );
  };

  return (
    <>
      <Row className='d-flex justify-content-center mt-5 mb-5'>
        {banks.map(bank => (
          <BankCard
            sm={12} md={2} key={bank.id}
            onClick={() => handleSelectBank(bank.id)}
            className={selectedBanks.includes(bank.id) ? 'selected' : ''}
            aria-label={`Select ${bank.name}`}
          >
            <CheckIcon
              icon={faCheck}
              className={selectedBanks.includes(bank.id) ? 'selected' : ''}
              aria-hidden="true"
            />
            <BankLogo
              src={bank.logo}
              alt={bank.name}
            />
            <BankName>{bank.name}</BankName>
          </BankCard>
        ))} 
      </Row>
      {selectedBanks.length > 0 && (
        <Row>
          <Col className='d-flex justify-content-end mt-5'>
            <SelectedCount>
              {selectedBanks.length} Banco{selectedBanks.length !== 1 ? 's' : ''} Selecionado{selectedBanks.length !== 1 ? 's' : ''}
            </SelectedCount>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BankListSimulationBatch;
