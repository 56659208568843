import { Col, Container, Row } from 'react-bootstrap';
import styles from './BankForm.module.scss';
import ButtonPrimary from '../../../../../components/Buttons/ButtonPrimary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import CreateForm from './CreateForm';
import UpdateForm from './UpdateForm';

interface BankFormProps{
    onProceed: any;
}

const BankForm: React.FC<BankFormProps> = ({onProceed}) => {
    const apiId = localStorage.getItem('apiId');
    const formRenderize = apiId === null ? <CreateForm onProceed={onProceed} /> : <UpdateForm onProceed={onProceed} />;

    const clearLocalStorage = () =>{
        localStorage.removeItem('bankId');
        localStorage.removeItem('bankName');
        localStorage.removeItem('apiId');
        localStorage.removeItem('bankUpdateName');
    } 

    return (
        <>
        <Container className={styles.mainContainer}>
            {formRenderize}
        </Container>
        <Row className={styles.buttonFooter}>
            <Col className="d-flex justify-content-between mt-5">
                <Col className="d-flex justify-content-start">
                    {!apiId && <ButtonPrimary
                        onClick={() => {
                            onProceed('list')
                            clearLocalStorage();
                        }}
                        content={<>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                            <span> Voltar</span>
                        </>} 
                    />}
                </Col>
                <Col className="d-flex justify-content-end">
                    <ButtonPrimary
                        onClick={() => {
                            onProceed('access');
                            clearLocalStorage();
                        }}
                        content="Meus acessos" 
                    />
                </Col>
            </Col>
        </Row>
        </>
    )
}

export default BankForm;