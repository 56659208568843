import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

interface DeleteFactorModalProps {
  show: boolean;
  onHide: () => void;
  factorId: number | null;
  onDelete: () => void;
}

const DeleteFactorModal: React.FC<DeleteFactorModalProps> = ({ show, onHide, factorId, onDelete }) => {
  const handleDelete = async () => {
    if (factorId === null) {
      alert('ID do fator não especificado.');
      return;
    }

    try {
      await axios.delete(`https://bi.gfttech.com.br/port/factor/${factorId}`);
      alert('Fator excluído com sucesso!');
      onDelete();
    } catch (error) {
      alert('Erro ao excluir o fator.');
      console.error(error);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Excluir Fator</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Tem certeza de que deseja excluir este fator? Esta ação não pode ser desfeita.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Excluir
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteFactorModal;
