import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Row } from "react-bootstrap";
import styles from '../SearchField.module.scss';
import { useEffect, useState } from "react";
import { PartnerType } from "../../../pages/Register/Register";
import { BASE_URL } from "../../../config";

interface SearchProps {
    name: string;
    type: string;
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onTypeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SearchRegister = ({name, type, onSearchChange, onTypeChange}: SearchProps) => {
    const [types, setTypes] = useState<PartnerType[]>([]);

    const handleType = async () => {
        const token = localStorage.getItem('token');
        
        try {
            const res = await fetch(`${BASE_URL}/tipo-parceiro`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.ok) {
                const data: PartnerType[] = await res.json();
                setTypes(data);
            } else {
                console.log(res.statusText);
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
        }
    };

    const clearSearch = () => {
        onSearchChange({
            target: { value: '' }, 
        } as React.ChangeEvent<HTMLInputElement>);
    };

    useEffect(() => {
        handleType();
    }, []);

    return(
        <>
            <Row className="d-flex justify-content-center">
                <div className={`${styles.inputWrapper} col-sm-5`}>
                    <Form.Control
                        type="text"
                        placeholder="Pesquisar por nome..."
                        className={`${styles.inputText} mb-5`}
                        value={name}
                        onChange={onSearchChange}
                    />
                    {name && (
                        <FontAwesomeIcon 
                            icon={faXmark}
                            className={styles.clearIcon}
                            onClick={clearSearch}
                        />
                    )}
                </div>
                <div className="col-sm-5">
                    <select 
                        name="type" 
                        value={type}
                        onChange={onTypeChange}
                        className={`${styles.inputSelect} form-select`}
                    >
                        <option value=""></option>
                        {types.map((type) => (
                            <option key={type.type_partner_id} value={type.type}>{type.type}</option>
                        ))}
                    </select>
                </div>
            </Row>
        </>
    )
}

export default SearchRegister;