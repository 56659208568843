import { Modal } from "react-bootstrap";
import ButtonPrimary from "../../../Buttons/ButtonPrimary";
import ButtonGray from "../../../Buttons/ButtonGray";
import { useEffect, useState } from "react";
import Select from "react-select";
import { PartnerType, Position } from "../../../../pages/Register/Register";
import { BASE_URL, BASE_URL_V3 } from "../../../../config";
import ToastWarning from "../../../ToastWarning";
import { formatCNPJ, formatCPF, formatPhone, isValidCPF } from "../../../../Utils";
import { EmployeeData } from "./Registration";

interface ModalProps {
  showModal: boolean;
  onClose: () => void;
}

interface Manager {
  id: number;
  label: string;
}

const AddRegistration = ({ showModal, onClose }: ModalProps) => {
  const [name, setName] = useState<string>("");
  const [fantasy, setFantasy] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [cnpj, setCnpj] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [manager, setManager] = useState<Manager[]>([]);
  const [managerSelect, setManagerSelect] = useState<number>(0);
  const [type, setType] = useState<PartnerType[]>([]);
  const [typeSelect, setTypeSelect] = useState<number>(0);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [position, setPosition] = useState<Position[]>([]);
  const [positionSelect, setPositionSelect] = useState<number>(0);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const fields = [
    { label: "Nome", value: name, setValue: setName },
    { label: "Nome Fantasia", value: fantasy, setValue: setFantasy },
    { label: "Razão Social", value: reason, setValue: setReason },
    { label: "E-mail", value: email, setValue: setEmail },
    { label: "CPF", value: formatCPF(cpf), setValue: setCpf },
    { label: "CNPJ", value: formatCNPJ(cnpj), setValue: setCnpj },
    { label: "Telefone", value: formatPhone(phone), setValue: setPhone },
  ];

  useEffect(() => {
    const handleType = async () => {
      const token = localStorage.getItem("token");

      try {
        const res = await fetch(`${BASE_URL}/tipo-parceiro`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.ok) {
          const data: PartnerType[] = await res.json();
          setType(data);
        }
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    };

    const handlePosition = async () => {
      const token = localStorage.getItem("token");

      try {
        const res = await fetch(`${BASE_URL}/posicao`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.ok) {
          const data: Position[] = await res.json();
          setPosition(data);
        }
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    };

    const handleManager = async () => {
      const token = localStorage.getItem("token");

      try {
        const res = await fetch(`${BASE_URL}/gerente?tipo=gerente`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.ok) {
          const data: EmployeeData[] = await res.json();
          const formattedData: Manager[] = data
            .filter((d) => d.employee_id !== 1)
            .map((data) => ({
              id: data.employee_id,
              label: data.user.user_name,
            }));
          setManager(formattedData);
        }
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    };

    handleType();
    handlePosition();
    handleManager();
  }, []);

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');

    const data = ({
        full_name: name,
        fantasy_name: fantasy,
        corporation_reason: reason,
        email,
        cpf,
        cnpj,
        cellphone: phone,
        date_of_hiring: new Date(),
        cod_manager: managerSelect.toString(),
        type_partner_id: typeSelect,
        situation_id: 6,
        position_id: positionSelect
    });

    try{
        const res = await fetch(`${BASE_URL_V3}/precadastro`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });

        if(res.ok){
            setToastMessage("Registro criado com sucesso! Gerar código externo.");
        } else{
            setToastMessage("Erro ao criar acesso!");
        }
    } catch(err){
        throw err;
    }
};


  const validateForm = () => {
    const areFieldsFilled = fields.every((field) => field.value.trim() !== "");
    const isTypeSelected = typeSelect !== 0;
    const isPositionSelected = positionSelect !== 0;
    const isManagerSelected = managerSelect !== 0;
    const isCPFValid = isValidCPF(cpf);

    if (!isCPFValid && cpf.length > 11) {
      setToastMessage("CPF inválido!");
    }

    setIsFormValid(
      areFieldsFilled &&
        isTypeSelected &&
        isPositionSelected &&
        isManagerSelected &&
        isCPFValid
    );
  };

  useEffect(() => {
    validateForm();
  }, [name, fantasy, reason, email, cpf, cnpj, phone, typeSelect, positionSelect, managerSelect]);

  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Adicionar Acesso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {fields.map((field, index) => (
            <div className="form-floating mb-3" key={index}>
              <input
                type="text"
                id={`field-${index}`}
                value={field.value}
                onChange={(e) => field.setValue(e.target.value)}
                className="form-control"
                placeholder={field.label}
                required
              />
              <label htmlFor={`field-${index}`}>{field.label}</label>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="mb-3">
          <Select
            options={manager}
            onChange={(e) => setManagerSelect(e?.id || 0)}
            placeholder="Selecione o Gerente"
            isSearchable
            menuPortalTarget={document.body} // Renderiza o menu no body
            styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Define o z-index elevado
                menu: (base) => ({
                ...base,
                zIndex: 9999, // Garantia adicional para sobrepor outros elementos
                }),
            }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-floating mb-3">
            <select
              className="form-select"
              onChange={(e) => setTypeSelect(Number(e.target.value))}
              value={typeSelect}
              required
            >
              <option value={0} disabled>
              </option>
              {type.map((t) => (
                <option key={t.type_partner_id} value={t.type_partner_id}>
                  {t.type}
                </option>
              ))}
            </select>
            <label>Tipo Parceiro</label>
          </div>
        </div>
        <div className="row">
          <div className="form-floating mb-3">
            <select
              className="form-select"
              onChange={(e) => setPositionSelect(Number(e.target.value))}
              value={positionSelect}
              required
            >
              <option value={0} disabled>
              </option>
              {position
                .filter((p) => p.position_id !== 8 && p.position_id !== 9)
                .map((t) => (
                  <option key={t.position_id} value={t.position_id}>
                    {t.position_name}
                  </option>
                ))}
            </select>
            <label>Cargo</label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary onClick={handleSubmit} content="Enviar" disabled={!isFormValid} />
        <ButtonGray onClick={onClose} content="Cancelar" />
      </Modal.Footer>
      {toastMessage && <ToastWarning message={toastMessage} />}
    </Modal>
  );
};

export default AddRegistration;
