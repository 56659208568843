import React from 'react';
import styles from './PageTitle.module.scss';

interface PageTitleProps {
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children: React.ReactNode;
}

const PageTitle: React.FC<PageTitleProps> = ({ size = 'h5', children }) => {
  const Tag = size;

  return (
    <Tag className={`${styles.underlineTitle} display-${size}`}>
      {children}
    </Tag>
  );
};

export default PageTitle;