import React, { useEffect, useState } from 'react';
import { Col, Row, Toast } from "react-bootstrap";
import styles from './ToastWarning.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';

interface ToastWarningProps {
    message: string;
  }

export default function ToastWarning ({ message }: ToastWarningProps){
    const [show, setShow] = useState(false);
    const timer = setTimeout(() => setShow(false), 4000); // Fecha após 4 segundos

    useEffect(() => {
        if (message) {
          setShow(true);
          return () => clearTimeout(timer);
        } else {
          setShow(false);
        }
      }, [message]);

    return(
        <Row>
            <Col xs={6}>
                <Toast className={styles.toast} onClose={() => setShow(false)} show={show} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">
                        <FontAwesomeIcon icon={faComments} className={styles.icon} />
                         <span className={styles.span}>Mensagem</span>
                    </strong>
                </Toast.Header>
                <Toast.Body>
                    {message}
                </Toast.Body>
                </Toast>
            </Col>
        </Row>
    )
}