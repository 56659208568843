import { Container, Nav } from "react-bootstrap";
import NavBar from "../../components/NavBar";
import { useState } from "react";
import styles from './MySimulations.module.scss';
import BatchSimulations from './BatchSimulations';
import Logo from "../../components/Logo";
import UnitarySimulations from "./UnitarySimulations";

export default function MySimulations() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [activeTab, setActiveTab] = useState<string>('lote');

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <div className={styles.logoSection}>
        <Logo/>
      </div>

      <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose} mt-5`}>
        <Nav variant="pills" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey as string)}>
          <Nav.Item className="me-4">
            <Nav.Link
              eventKey="lote"
              className={`${styles.navLink} ${activeTab === 'lote' ? styles.navLinkActive : ''}`}
            >
              Lote
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="unitaria"
              className={`${styles.navLink} ${activeTab === 'unitaria' ? styles.navLinkActive : ''}`}
            >
              Unitária
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <div className="mt-5">
          {activeTab === 'lote' && <BatchSimulations />}
          {activeTab === 'unitaria' && <UnitarySimulations />}
        </div>
      </Container>
    </>
  );
}
