import { Container } from "react-bootstrap";
import AccountTabPane from "./AccountTabPane";
import { useState } from "react";
import styles from './MyAccount.module.scss'
import Logo from "../../../components/Logo";
import NavBarAgent from "../../../components/NavBar/Agent_Partner";

export default function MyAccountAgent() {
    const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
    return(
        <>
          <section className={styles.navbarSection}>
            <NavBarAgent onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)}/>
          </section>
          <div className={styles.logoSection}>
            <Logo/>
          </div>
          <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
            <AccountTabPane />
          </Container>
        </>  
    )
}