import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import convenioLogo1 from '../../../../assets/images/inss-logo.png';

const CovenantCard = styled(Col)`
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 35px;
  padding: 1rem;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  text-align: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 1rem #F28705;
  }

  &.selected {
    box-shadow: 0 0 1rem #F28705;
  }

  /* Para telas grandes */
  @media (min-width: 1024px) {
    max-width: 18rem;
    margin-left: 2rem;
  }

  /* Para telas médias */
  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 16rem;
    margin-left: 1.5rem;
  }

  /* Para telas pequenas */
  @media (max-width: 767px) {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 1rem;
  }
`;

const CovenantLogo = styled.img`
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 0.5rem;

  /* Ajuste para telas pequenas */
  @media (max-width: 767px) {
    max-width: 80px;
    max-height: 80px;
  }
`;

const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: white;
  background: gray;
  padding: 3px;
  border-radius: 50%;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 1.25rem;

  &.selected {
    background-color: #153B7F;
  }
`;

const CovenantName = styled.div`
  font-size: 1rem;
  margin-top: 0.5rem;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
  color: #F28705;
`;

const SelectedCount = styled.div`
  font-size: 1rem;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
  color: #F28705;
  text-align: center;
  margin-bottom: 1rem;
`;

const CovenantListSimulation = ({ onSelectionChange }: { onSelectionChange: (selectedId: string) => void }) => {
  const [selectedCovenant, setSelectedCovenant] = useState<string | null>(null);
  const [covenants, setCovenants] = useState<{ covenantId: string, covenantDescription: string }[]>([]);

  useEffect(() => {
    fetch('https://acelereai.gfttech.com.br/api/proposal/simulation/external-covenants')
      .then(response => response.json())
      .then(data => setCovenants(data))
      .catch(error => console.error('Error fetching covenants:', error));
  }, []);

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem('formData') || '{}');
    if (selectedCovenant) {
      const selectedCovenantData = covenants.find(c => c.covenantId === selectedCovenant);
      if (selectedCovenantData) {
        formData.covenantId = selectedCovenantData.covenantId;
        formData.covenantDescription = selectedCovenantData.covenantDescription;
        localStorage.setItem('formData', JSON.stringify(formData));
        onSelectionChange(selectedCovenantData.covenantId);
      }
    }
  }, [selectedCovenant, covenants, onSelectionChange]);

  const handleSelectCovenant = (covenantId: string) => {
    setSelectedCovenant(prevSelected => (prevSelected === covenantId ? null : covenantId));
  };

  const getCovenantLogo = (covenantId: string) => {
    switch (covenantId) {
      case '3':
        return convenioLogo1;
      default:
        return '';
    }
  };

  return (
    <>
      <Row className='d-flex justify-content-center mt-5 mb-5'>
        {covenants.map(covenant => (
          <CovenantCard
            sm={12} md={2} key={covenant.covenantId}
            onClick={() => handleSelectCovenant(covenant.covenantId)}
            className={selectedCovenant === covenant.covenantId ? 'selected' : ''}
            aria-label={`Select ${covenant.covenantDescription}`}
          >
            <CheckIcon
              icon={faCheck}
              className={selectedCovenant === covenant.covenantId ? 'selected' : ''}
              aria-hidden="true"
            />
            <CovenantLogo
              src={getCovenantLogo(covenant.covenantId)}
              alt={covenant.covenantDescription}
            />
            <CovenantName>{covenant.covenantDescription}</CovenantName>
          </CovenantCard>
        ))} 
      </Row>
      {selectedCovenant && (
        <Row>
          <Col className='d-flex justify-content-end mt-5'>
            <SelectedCount>
              {`Convênio Selecionado: ${covenants.find(c => c.covenantId === selectedCovenant)?.covenantDescription}`}
            </SelectedCount>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CovenantListSimulation;