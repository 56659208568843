import React from 'react';
import styles from './ButtonPrimary.module.scss';
import { Button } from 'react-bootstrap';

interface ButtonPrimaryProps {
  content: React.ReactNode; // Permite texto, ícones ou qualquer elemento JSX
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({ content, onClick, disabled }) => {
  return (
    <Button
      className={`btn ${styles.buttonPrimary}`}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </Button>
  );
};

export default ButtonPrimary;
