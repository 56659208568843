import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import styles from './BatchSimulations.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ButtonGrayUltimate from "../../../components/Buttons/ButtonGrayUltimate";
import Pagination from '../../../components/Pagination';
import FilterBatch from "./FilterBatch";
import ViewDataBatchSimulation from "../../../components/Modals/Simulations/ViewDataBatchSimulation";
import { BASE_URL, URL_SIMULATIONS } from "../../../config";
import ToastWarning from "../../../components/ToastWarning";
import SpinnerDefault from "../../../components/Spinner";

interface SimulationData {
  _id: string;
  batchId: string;
  rows: number;
  date: string;
}

export default function BatchSimulations() {
  const [simulations, setSimulations] = useState<SimulationData[]>([]);
  const [filteredSimulations, setFilteredSimulations] = useState<SimulationData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [selectedBatchId, setSelectedBatchId] = useState<string>('');
  const [toastMessage, setToastMessage] = useState('');

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(filteredSimulations.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredSimulations.length);
  const currentSimulations = filteredSimulations.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber: number) => {
      setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const fetchSimulations = async () => {
      try {
        setLoading(true);

        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token não encontrado');
        }

        const partnerResponse = await fetch(`${BASE_URL}/parceiro/dados-logado`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!partnerResponse.ok) {
          throw new Error('Erro ao buscar dados do parceiro');
        }

        const partnerData = await partnerResponse.json();
        const partnerId = partnerData.partnerData.partner_id;

        const response = await fetch(`${URL_SIMULATIONS}/batch/find-by-user?userId=${partnerId}&userType=Partner`);
        if (!response.ok) {
          throw new Error('Erro ao buscar os dados');
        }
        
        const data: SimulationData[] = await response.json();
        setSimulations(data);
        setFilteredSimulations(data); // Inicializa as simulações filtradas com todas as simulações
      } catch (error) {
        console.error('Erro ao buscar simulações:', error);
        setToastMessage('Erro ao buscar dados');
      } finally {
        setLoading(false);
      }
    };

    fetchSimulations();
  }, []);

  // Função para filtrar as simulações
  const handleFilter = (search: string, startDate: string, endDate: string) => {
    let filtered = simulations;

    // Filtro pelo ID do lote
    if (search) {
      filtered = filtered.filter(simulation => simulation.batchId.includes(search));
    }

    // Filtro pela data inicial
    if (startDate) {
      filtered = filtered.filter(simulation => new Date(simulation.date) >= new Date(startDate));
    }

    // Filtro pela data final
    if (endDate) {
      filtered = filtered.filter(simulation => new Date(simulation.date) <= new Date(endDate));
    }

    setFilteredSimulations(filtered);
    setCurrentPage(1); // Reseta a página atual para 1 ao aplicar o filtro
  };

  const handleViewBatch = (batchId: string) => {
    setSelectedBatchId(batchId);
    setModalShow(true);
  };

  return (
    <>  
        <FilterBatch onFilter={handleFilter} />

        <div className={`${styles.tableContainer} mt-5`}>
          <div className={styles.scrollableTable}>
            <Table className={`${styles.tableStyle} table table-responsive`}>
              <thead>
                <tr>
                  <th>ID DO LOTE</th>
                  <th>DATA E HORÁRIO DO LOTE</th>
                  <th>CLIENTES</th>
                  <th>AÇÕES</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={4}>
                      <SpinnerDefault/>
                    </td>
                  </tr>
                ) : currentSimulations.map((simulation) => (
                    <tr key={simulation._id}>
                      <td>{simulation.batchId}</td>
                      <td>{new Date(simulation.date).toLocaleString('pt-BR')}</td>
                      <td>{simulation.rows} clientes</td>
                      <td>
                        <ButtonGrayUltimate 
                          content={<><FontAwesomeIcon icon={faInfoCircle} /></>} 
                          onClick={() => handleViewBatch(simulation.batchId)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          {totalPages > 1 && (
            <Pagination
                totalItems={filteredSimulations.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            />
          )}
        </div>

        <ViewDataBatchSimulation 
          show={modalShow} 
          onHide={() => setModalShow(false)} 
          batchId={selectedBatchId} 
        />
        {toastMessage && <ToastWarning message={toastMessage} />}
    </>
  );
}