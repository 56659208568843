import { Container } from 'react-bootstrap';
import styles from './ExtractMoves.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import LatestMoves from './LatestMoves';
import PageTitle from '../../../../components/PageTitle';

const ExtractMoves = () => {

    return(
        <Container className={styles.mainContainer}>
            <div className={styles.header}>
                {/* <AvailableBalance /> */}
            </div>
            <div className="row mt-5">
                <PageTitle size="h4">Últimas Movimentações</PageTitle>
                <LatestMoves />
                <div className="d-flex align-items-center mt-3">
                    <h5 className={`${styles.extract} mb-0`}> Ver extrato </h5>
                    <div className={`${styles.iconCircle} ms-2`}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                </div>
            </div>
        </Container>
    );

}

export default ExtractMoves;