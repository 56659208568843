import React from 'react';
import styles from './ButtonGrayUltimate.module.scss';
import { Button } from 'react-bootstrap';

interface ButtonGrayUltimateProps {
  content: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

const ButtonGrayUltimate: React.FC<ButtonGrayUltimateProps> = ({ content, onClick, disabled }) => {
  return (
    <Button
      className={`btn ${styles.buttonGrayUltimate}`}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </Button>
  );
};

export default ButtonGrayUltimate;
