import { Col, Row } from 'react-bootstrap';
import styles from './UpdateForm.module.scss';
import { useEffect, useState } from 'react';
import ButtonPrimary from '../../../../../../components/Buttons/ButtonPrimary';
import ToastWarning from '../../../../../../components/ToastWarning';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../../../../../config';

interface UpdateProps{
    onProceed: (page: string) => void;
}

const UpdateForm = ({onProceed}: UpdateProps) => {
    const token = localStorage.getItem('token');
    const apiId = localStorage.getItem('apiId');
    const bankId = localStorage.getItem('bankId');
    const bankUpdateName = localStorage.getItem('bankUpdateName');
    const keysEnabled = Number(bankId) === 6 || Number(bankId) === 1;

    const [toastMessage, setToastMessage] = useState('');
    const [data, setData] = useState<any[]>([]);
    const [login, setLogin] = useState('');
    const [cpf, setCpf] = useState<string>('');
    const [bankUserId, setBankUserId] = useState('');
    const [password, setPassword] = useState('');
    const [key, setKey] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [idBank, setIdBank] = useState('');

    const buttonDesable = () => {
        const fieldsIvalid = (login || '').trim() === '' || (password || '').trim() === ''; //desabilita o botão caso os campos de login e senha não estejam preenchidos
        const keysFieldInvalid = keysEnabled && ((key || '').trim() === '' || (secretKey || '').trim() === ''); //verifica qual o banco selecionado, para então, tornar obrigatório o preenchimento das keys
    
        return fieldsIvalid || keysFieldInvalid;
    };    

    const handleSearch = async () => {
        try {
            const res = await fetch(`${BASE_URL}/acesso-usuario-banco`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.ok) {
                const data = await res.json();
                const filteredData = data.find((item: any) => item.bank_api_credential_id === Number(apiId));
                if (filteredData) {
                    setData([filteredData]); 
                    setLogin(filteredData.login);
                    setPassword(filteredData.password);
                    setKey(filteredData.api_key);
                    setIdBank(filteredData.bank_id);
                    setBankUserId(filteredData.bank_user_id);
                    setCpf(filteredData.user_digitizer_cpf);
                } else {
                    setToastMessage('Nenhum dado encontrado!');
                }
            } else {
                console.log(res.statusText);
                setToastMessage('Nenhum dado encontrado!');
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
            setToastMessage('Nenhum dado encontrado!');
        }
    };

    const handleUpdate = async () => {
        try {
            const res = await fetch(`${BASE_URL}/acesso-usuario-banco/${apiId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    bank_id: idBank,
                    login: login,
                    password: password,
                    bank_user_id: bankUserId,
                    api_key: key,
                    secret_key: secretKey,
                    user_digitizer_cpf: cpf,
                }),
            });

            if (res.ok) {
                const data = await res.json();
                console.log(data);
                setToastMessage('Dados atualizados com sucesso!');
                setTimeout(() => {
                    onProceed('access');
                    localStorage.removeItem('apiId');
                    localStorage.removeItem('bankUpdateName');
                    localStorage.removeItem('bankId');
                }, 2000)
            } else {
                console.log(res.statusText);
                setToastMessage('Erro ao atualizar dados!');
            }
        } catch(error){
            console.error('Erro na atualização:', error);
            setToastMessage('Erro ao atualizar dados!');
        }
    }

    const keyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter' && !buttonDesable()){
            handleUpdate();
        }
    };

    const formatCPF = (cpf: string): string => {
        const numericCpf = cpf.replace(/\D/g, '');
        return numericCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    };

    const fields = [
        { id: "login", label: "Login", type: "text", value: login, onChange: (e: any) => setLogin(e.target.value), required: true},
        { id: "bank_user_id", label: "Código do Operador", type: "text", value: bankUserId, onChange: (e: any) => setBankUserId(e.target.value), required: false},
        { id: "cpf", label: "CPF", type: "text", value: formatCPF(cpf), onChange: (e: any) => setCpf(e.target.value), required: true},
        { id: "senha", label: "Senha", type: showPassword ? "text" : "password", value: password, onChange: (e: any) => setPassword(e.target.value), 
            extraContent: (
                <div className={`${styles.icon}`} onClick={() => setShowPassword(!showPassword)}>
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </div>
            ),
            required: true,
        },
        ...(keysEnabled ? [
                  { id: "key", label: "Key", type: "text", value: key, onChange: (e: any) => setKey(e.target.value), required: keysEnabled},
                  { id: "secretKey", label: "Secret Key", type: "text", value: secretKey, onChange: (e: any) => setSecretKey(e.target.value), onKeyDown: keysEnabled ? keyPress : undefined, required: keysEnabled},
              ]
            : []),
    ];

    useEffect(() => {
        handleSearch();
    }, [apiId, token]);

    return(
        <>
            <div className={`${styles.title} d-flex justify-content-center mt-3 mb-2`}>
                <h2>{bankUpdateName}</h2>
            </div>
            {fields.map((field) => (
                <div key={field.id} className={styles.floatingLabel}>
                    <input
                        type={field.type}
                        id={field.id}
                        value={field.value}
                        onChange={field.onChange}
                        onKeyDown={field.onKeyDown}
                        className={`form-control ${styles.input}`}
                        required={field.required}
                    />
                    <label htmlFor={field.id}>{field.label}</label>
                    {field.extraContent && field.extraContent}
                </div>
            ))}
            <Row>
                <Col className="d-flex justify-content-center mt-3">
                    <ButtonPrimary
                        disabled={buttonDesable()}
                        content="Salvar informações" 
                        onClick={() => handleUpdate()}
                    />
                </Col>
            </Row>
            
        {toastMessage && <ToastWarning message={toastMessage} />}
        </>
    )
}

export default UpdateForm;