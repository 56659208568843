import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './ConvenantSelection.module.scss';
import { motion } from 'framer-motion';
import PageSubTitle from '../../../../components/PageSubTitle';
import NavBar from '../../../../components/NavBar';
import PageTitle from '../../../../components/PageTitle';
import ToastValidationSimulation from '../../../../components/ToastValidationSimulation';
import CovenantListSimulation from '../ConvenantListSimulation';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ProgressIndicator from '../../../../components/ProgressContainer';
import AvailableBalance from '../../../../components/AvailableBalance';
import Logo from '../../../../components/Logo';

export default function ConvenantSelection() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [toastMessage, setToastMessage] = useState('');
  const [selectedCovenantId, setSelectedCovenantId] = useState<string | null>(null);

  const totalSteps = 5;
  const currentStep = 2;

  const subtitleTextClient = "Escolha apenas 1 Convênio.";

  const handleCovenantSelection = (selectedId: string) => {
    setSelectedCovenantId(selectedId);
    setToastMessage('');
  };

  const handleBack = (e: React.FormEvent) => {
    window.location.href = '/CamposUnitarios';
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedCovenantId) {
      setToastMessage('Por favor, selecione um convênio antes de prosseguir.');
      return;
    }

    window.location.href = '/SelecaoProdutos';
  }

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Logo/>
     
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={`${styles.balanceConatiner} d-flex justify-content-end`}>
          <AvailableBalance />
        </div>
        <div className={`${styles.titleSection} mt-5`}>
          <PageTitle size="h2">Consulta Unitária</PageTitle>
        </div>
        <div className={`${styles.subTitleSection} mb-3`}>
          <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
        </div>
        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <CovenantListSimulation onSelectionChange={handleCovenantSelection} />
          <Row className='mt-5'>
            <Col className='d-flex justify-content-start mt-5'>
              <ButtonPrimary 
                content={<><FontAwesomeIcon icon={faArrowLeft} /> <span>Voltar</span></>} 
                onClick={handleBack}
              />
            </Col>
            <Col className='d-flex justify-content-end mt-5'>
              <ButtonPrimary 
                content={<><span>Prosseguir</span> <FontAwesomeIcon icon={faArrowRight} /></>} 
                onClick={handleSubmit}
              />
            </Col>
          </Row>
          <Row>
            <Col className='mt-3 d-flex justify-content-center mt-5' md={12}>
              <ProgressIndicator totalSteps={totalSteps} currentStep={currentStep} />
            </Col>
          </Row>
        </Container>
        {toastMessage && <ToastValidationSimulation message={toastMessage} />}
      </motion.div>
    </>
  );
}
