import { useState } from 'react';
import { Container} from 'react-bootstrap';
import PageTitle from '../../../../../components/PageTitle';
import PageSubTitle from '../../../../../components/PageSubTitle';
import styles from './BanksAccess.module.scss';
import MyAccess from './MyAccess';
import BankList from './BankList';
import BankForm from './BankForm';

const BanksAccess = () => {
    const subtitle = "Adicione ou altere as informações do acesso bancário.";
    const [currentView, setCurrentView] = useState<'access' | 'list' | 'form'>('access');

    const handleProgress = (view: 'list' | 'form') => {
        setCurrentView(view);
      };

    return(
        <Container>
            <div className={styles.titleContainer}>
                <div>
                    <PageTitle size="h4">Gerenciar Acessos</PageTitle>
                </div>
                <PageSubTitle size="h6">{subtitle}</PageSubTitle>
            </div>
            <div className={styles.contentSection}>
                 {currentView === 'access' && <MyAccess onProceed={handleProgress} />}
                 {currentView === 'list' && <BankList onProceed={handleProgress} />}
                 {currentView === 'form' && <BankForm onProceed={handleProgress} />}
            </div>
        </Container>
    )
}

export default BanksAccess;