import { Card, Modal, Container } from 'react-bootstrap';
import styles from './AddCard.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCheck, faFilePen, faMoneyBillWave, faPaperPlane, faPlus, faShop, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import ButtonGray from '../../../../../../components/Buttons/ButtonGray';
import ButtonPrimary from '../../../../../../components/Buttons/ButtonPrimary';
import ToastWarning from '../../../../../../components/ToastWarning';
import { BASE_URL } from '../../../../../../config';
import { CardDataProps, CardList, CheckIcon } from './interface';

const AddCard = ({ onClose }: { onClose: () => void }) => {
    const [showModal, setShowModal] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [selectedCards, setSelectedCards] = useState<number[]>([]);
    const [cards, setCards] = useState<number[]>([]);
    const [searchCards, setSearchCards] = useState<CardDataProps[]>([]);
    const [refresh, setRefresh] = useState(false);

    const buttonDisable = selectedCards.length === 0;
    const token = localStorage.getItem('token');

    const CardIcons: { [key: number]: any } = {
        1: faUser,
        2: faUsers,
        3: faFilePen,
        4: faShop,
        5: faMoneyBillWave,
        6: faBell,
        7: faPaperPlane,
    };

    const handleModal = () => {
        setShowModal(true)
        setToastMessage('');
        setSelectedCards([]);
        handleCard();
        handleCardList();
    };

    const handleCloseModal = () => {
        setShowModal(false)
        setRefresh(prev => !prev);
        setToastMessage('');
        onClose();
    };

    const handleCardSelected = (id: number) => {
        const totalSelectedCards = selectedCards.length + cards.length;
        if (selectedCards.includes(id)) {
            setSelectedCards(selectedCards.filter(cardId => cardId !== id));
        } else {
            if (totalSelectedCards < 4) {
                setSelectedCards([...selectedCards, id]);
            } else {
                setToastMessage('Você só pode selecionar até 4 cards.');
            }
        }
    };

    const handleCardList = async () => {
        try{
            const res = await fetch(`${BASE_URL}/acelerai/card`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
    
            if(res.ok){
                const data = await res.json();
                setSearchCards(data);
            } else{
                console.error('erro ao buscar cards:', res.statusText);
            }
        } catch(error){
            console.error('Error:', error);
        }
    }

    const handleSubmit = async () => {
        try {
            const responses = await Promise.all(selectedCards.map(async card_id => {
                const res = await fetch(`${BASE_URL}/acelerai/card/user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({ card_id }) 
                });
                return res;
            }));

            const data = await Promise.all(responses.map(res => res.json()));

            if (responses.every(res => res.ok)) {
                setToastMessage('Card adicionado com sucesso!');
            } else {
                console.log(data);
                setToastMessage('Erro ao adicionar card!');
            }
        } catch (error) {
            console.error('Error ao enviar dados!', error);
            setToastMessage('Erro ao enviar dados!');
        }

        setTimeout(() => {
            handleCloseModal();
        }, 2000);
    };

    const handleCard = async () => {
        try {
            const res = await fetch(`${BASE_URL}/acelerai/card/user`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (res.ok) {
                const data = await res.json();
                setCards(data.map((card: any) => card.card_id)); 
            } else {
                console.log('Error ao buscar cards selecionados', res.statusText);
            }
        } catch (error) {
            console.error('Error ao buscar dados!', error);
        }
    }

    useEffect(() => {
        handleCard();
        handleCardList();
    }, [refresh]);

    const desiredIds = [1, 2, 3, 7]; 
    const filterCards = searchCards.filter((card: CardDataProps) => desiredIds.includes(card.card_id));

    // Filtra os cards que não estão na lista dos selecionados
    //apenas substituir filterCards por searchCards
    const filteredCards = filterCards.filter(card => !cards.includes(card.card_id));

    return (
        <>
            <Card className={`${styles.mainCard}`} onClick={handleModal}>
                <Card.Body className={`${styles.iconContainer} d-flex justify-content-center`}>
                    <FontAwesomeIcon icon={faPlus} className={styles.icon} />
                </Card.Body>
                <span className={styles.text}>Adicionar</span>
            </Card>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Atalho</Modal.Title>
                </Modal.Header>
                <Container>
                    {filteredCards.map(card => (
                        <CardList 
                            key={card.card_id}
                            onClick={() => handleCardSelected(card.card_id)}
                            className={selectedCards.includes(card.card_id) ? 'selected' : ''}
                        >
                            <CheckIcon 
                                icon={faCheck}
                                aria-hidden="true"
                                className={selectedCards.includes(card.card_id) ? 'selected' : ''}
                            />
                            <div className={`${styles.iconOptionContainer} d-flex justify-content-center align-items-center`}>
                                <FontAwesomeIcon icon={CardIcons[card.card_id]} className={styles.iconOption} />
                            </div>
                            <span className={styles.textOption}>{card.card_name}</span>
                        </CardList>
                    ))}
                </Container>
                <Modal.Footer>
                    <ButtonGray onClick={handleCloseModal} content="Cancelar"/>
                    <ButtonPrimary onClick={handleSubmit} disabled={buttonDisable} content="Salvar"/>
                </Modal.Footer>
                {toastMessage && <ToastWarning message={toastMessage} />}
            </Modal>
        </>
    );
};

export default AddCard;
