import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import Pagination from '../../../../../components/Pagination/index';
import CreatePortabilityModal from './CreatePortabilityModal/index';
import ButtonPrimary from '../../../../../components/Buttons/ButtonPrimary';
import ButtonGrayUltimate from '../../../../../components/Buttons/ButtonGrayUltimate';
import ButtonDelete from '../../../../../components/Buttons/ButtonDelete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import EditPortabilityModal from './EditPortibilityModal';
import DeletePortabilityModal from './DeletePortabilityModal';
import { Portability, PaginatedResponse } from '../../../../../interfaces/Portability';

const PortabilityList: React.FC = () => {
  const [portabilities, setPortabilities] = useState<Portability[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPortability, setSelectedPortability] = useState<Portability | null>(null);
  const [selectedPortabilityId, setSelectedPortabilityId] = useState<number | null>(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const itemsPerPage = 5;

  const fetchPortabilities = async (page: number = 1) => {
    console.log(`Fetching page: ${page}`);
    try {
      const response = await axios.get<PaginatedResponse<Portability>>(
        `https://bi.gfttech.com.br/port/portabilities?page=${page}&pageSize=${itemsPerPage}`
      );
      setPortabilities(response.data.data || []);
      setTotalItems(response.data.totalPages || 0);
      setCurrentPage(response.data.currentPage || page);
    } catch (error) {
      console.error('Erro ao buscar portabilidades:', error);
    }
  };

  useEffect(() => {
    fetchPortabilities(currentPage);
  }, [currentPage]);

  const handlePageChange = (page: number) => setCurrentPage(page);

  const handleCreate = () => setShowCreateModal(true);
  const handleEdit = (portability: Portability) => {
    setSelectedPortability(portability);
    setShowEditModal(true);
  };
  const handleDelete = (portabilityId: number) => {
    setSelectedPortabilityId(portabilityId);
    setShowDeleteModal(true);
  };

  const handleSave = () => {
    fetchPortabilities(currentPage);
    setShowCreateModal(false);
    setShowEditModal(false);
  };

  const formatDate = (date: string): string => {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return '';
    }
    const day = String(parsedDate.getDate()).padStart(2, '0');
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const year = parsedDate.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatCurrency = (value: number | string | null | undefined) => {
    if (value == null || value === undefined || value === '') return 'Indisponível';
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(numberValue)) return 'Indisponível';
    return numberValue.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div>
      <ButtonPrimary onClick={handleCreate} content="+ Adicionar Nova Regra" />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Dat. Criação</th>
            <th>Ultima Alteração</th>
            <th>Banco Origem</th>
            <th>Banco Destino</th>
            <th>Parcelas Mínimas</th>
            <th>Saldo Mínimo</th>
            <th>Editar</th>
            <th>Excluir</th>
          </tr>
        </thead>
        <tbody>
          {portabilities.map((portability) => (
            <tr key={portability.id}>
              <td>{formatDate(portability.createdAt)}</td>
              <td>{formatDate(portability.updatedAt)}</td>
              <td>{portability.originBank?.bankName || 'N/A'}</td>
              <td>{portability.destinationBank?.bankName || 'N/A'}</td>
              <td>{portability.minimumInstallments}</td>
              <td>{formatCurrency(portability.minimumBalanceAmount)}</td>
              <td>
                <ButtonGrayUltimate
                  content={<FontAwesomeIcon icon={faEdit} />}
                  onClick={() => handleEdit(portability)}
                />
              </td>
              <td>
                <ButtonDelete
                  content={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() => handleDelete(portability.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
      <CreatePortabilityModal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        onSave={handleSave}
      />
      <EditPortabilityModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        onSave={handleSave}
        portabilityToEdit={selectedPortability}
      />
      <DeletePortabilityModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        portabilityId={selectedPortabilityId}
        onDelete={handleSave}
      />
    </div>
  );
};

export default PortabilityList;
