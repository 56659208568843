import { Button, Container, Row, Col, ListGroup } from "react-bootstrap";
import NavBar from "../../components/NavBar";
import { useState } from "react";
import logoBmg from '../../assets/images/banco bmg.png';
import logoMaster from '../../assets/images/banco master.png';
import logoC6 from '../../assets/images/c6-bank.png';
import logoINSS from '../../assets/images/inss-logo.png';
import logoPan from '../../assets/images/banco pan.png';
import styles from './VirtualWallet.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";

export default function VirtualWallet() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');

  // Simulação de oportunidades
  const opportunities = [
    { bank: 'Banco Master', amount: 30000, logo: logoMaster },
    { bank: 'Banco Bmg', amount: 400000, logo: logoBmg },
    { bank: 'Banco C6', amount: 850000, logo: logoC6 },
    { bank: 'Banco Pan', amount: 50200, logo: logoPan },
    { bank: 'Banco Daycoval', amount: 80000, logo: 'https://www.bancariosce.org.br/wp-content/uploads/2022/10/daycoval.webp' },
    { bank: 'Banco NBC', amount: 80000, logo: 'https://raichu-uploads.s3.amazonaws.com/logo_nbc-bank_vj05fa.png' },
    
  ];

  // Simulação de convênios
  const agreements = [
    { agreement: 'INSS', logo: logoINSS },
    { agreement: 'FGTS', logo: 'https://condo.news/wp-content/uploads/2023/04/condonews-fgts-logo.png' },
    { agreement: 'Governo Estadual da Bahia', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Bras%C3%A3o_do_estado_da_Bahia.svg/1200px-Bras%C3%A3o_do_estado_da_Bahia.svg.png' },
    { agreement: 'Prefeitura de Fortaleza', logo: 'https://seeklogo.com/images/B/Bras__o_Fortaleza-logo-F9E9144BB6-seeklogo.com.png' },
    { agreement: 'Prefeitura de Belo Horizonte', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Bras%C3%A3o-Belo-Horizonte.svg/1200px-Bras%C3%A3o-Belo-Horizonte.svg.png' },
    { agreement: 'Governo Estadual de Amazonas', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Bras%C3%A3o_do_Amazonas.svg/711px-Bras%C3%A3o_do_Amazonas.svg.png' },
  ];

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>

      <Container className={`${menuOpen ? styles.sideBarOpen : styles.sideBarClose} mt-5`}>
        <div className={`${styles.welcomeSection} text-center mb-4`}>
          <h1 className={styles.title}>
            <FontAwesomeIcon icon={faWallet} className="me-2" /> Bem-vindo à Carteira Virtual AcelereAI
          </h1>
          <p className="mt-4">Hoje você tem {opportunities.reduce((total, opp) => total + opp.amount, 0).toLocaleString('pt-BR')} oportunidades.</p>
        </div>

        <Row className="mt-5">
          <Col md={6}>
            <h2 className={styles.sectionTitle}>Oportunidades por Banco</h2>
            <ListGroup className={styles.listGroup}>
              {opportunities.map((opp, index) => (
                <ListGroup.Item key={index} className={styles.listItem}>
                  <img src={opp.logo} alt={`${opp.bank} Logo`} className={styles.bankLogo} />
                  <span className={styles.bankName}>{opp.bank}</span>
                  <span className={styles.bankAmount}>R$ {opp.amount.toLocaleString('pt-BR')}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>

          <Col md={6}>
            <h2 className={styles.sectionTitle}>Convênios Disponíveis</h2>
            <ListGroup className={styles.listGroup}>
              {agreements.map((agreement, index) => (
                <ListGroup.Item key={index} className={styles.listItem}>
                  <img src={agreement.logo} alt={`${agreement.agreement} Logo`} className={styles.agreementLogo} />
                  <span className={styles.agreementName}>{agreement.agreement}</span>
                  <span className={styles.agreementAmount}> {/* Espaço reservado para alinhamento */}
                    {/* Você pode colocar algum valor se necessário, ou apenas um espaço se não houver valor */}
                  </span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>

        <Container className="text-center mt-4">
          <Button variant="primary" className={styles.actionButton}>
            Clique aqui para seguir com as operações
          </Button>
        </Container>
      </Container>
    </>
  );
}
