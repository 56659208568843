import React from 'react';
import styles from './Pagination.module.scss';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faAnglesRight, faChevronLeft, faChevronRight, faEllipsis } from '@fortawesome/free-solid-svg-icons';

interface PaginationProps {
    totalItems: number;
    itemsPerPage: number;
    currentPage: number;
    onPageChange: (pageNumber: number) => void;
}

const getTotalPages = (totalItems: number, itemsPerPage: number) =>
    Math.ceil(totalItems / itemsPerPage);
  
  const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }: PaginationProps) => {
    const totalPages = getTotalPages(totalItems, itemsPerPage);

    const handlePageClick = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent, page: number) => {
        if (event.key === 'Enter' || event.key === ' ') {
            handlePageClick(page);
        }
    };

    const paginationItems = [];

    // Botão para a primeira página
    paginationItems.push(
        <li
            key="first"
            className={`${styles.paginationItem} ${currentPage === 1 ? styles.disabled : ''}`}
            onClick={() => handlePageClick(1)}
            tabIndex={currentPage === 1 ? -1 : 0}
            onKeyPress={(e) => handleKeyPress(e, 1)}
        >
            <FontAwesomeIcon icon={faAnglesLeft} className={styles.icon} />
        </li>
    );

    // Botão para a página anterior
    paginationItems.push(
        <li
            key="prev"
            className={`${styles.paginationItem} ${currentPage === 1 ? styles.disabled : ''}`}
            onClick={() => handlePageClick(currentPage - 1)}
            tabIndex={currentPage === 1 ? -1 : 0}
            onKeyPress={(e) => handleKeyPress(e, currentPage - 1)}
        >
            <FontAwesomeIcon icon={faChevronLeft} className={styles.icon} />
        </li>
    );

    // Mostrar páginas dinamicamente
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (currentPage <= 3) {
        endPage = Math.min(5, totalPages);
    } else if (currentPage >= totalPages - 2) {
        startPage = Math.max(totalPages - 4, 1);
    }

    for (let number = startPage; number <= endPage; number++) {
        paginationItems.push(
            <li
                key={number}
                className={`${styles.paginationItem} ${number === currentPage ? styles.active : ''}`}
                onClick={() => handlePageClick(number)}
                tabIndex={0}
                onKeyPress={(e) => handleKeyPress(e, number)}
            >
                {number}
            </li>
        );
    }

    // Adicionar reticências no início, se necessário
    if (startPage > 1) {
        paginationItems.unshift(
            <li key="ellipsis-start" className={`${styles.paginationItem} ${styles.ellipsis}`}>
                <FontAwesomeIcon icon={faEllipsis} className={styles.icon} />
            </li>
        );
    }

    // Adicionar reticências no final, se necessário
    if (endPage < totalPages) {
        paginationItems.push(
            <li key="ellipsis-end" className={`${styles.paginationItem} ${styles.ellipsis}`}>
                <FontAwesomeIcon icon={faEllipsis} className={styles.icon} />
            </li>
        );
    }

    // Botão para a próxima página
    paginationItems.push(
        <li
            key="next"
            className={`${styles.paginationItem} ${currentPage === totalPages ? styles.disabled : ''}`}
            onClick={() => handlePageClick(currentPage + 1)}
            tabIndex={currentPage === totalPages ? -1 : 0}
            onKeyPress={(e) => handleKeyPress(e, currentPage + 1)}
        >
            <FontAwesomeIcon icon={faChevronRight} className={styles.icon} />
        </li>
    );

    // Botão para a última página
    paginationItems.push(
        <li
            key="last"
            className={`${styles.paginationItem} ${currentPage === totalPages ? styles.disabled : ''}`}
            onClick={() => handlePageClick(totalPages)}
            tabIndex={currentPage === totalPages ? -1 : 0}
            onKeyPress={(e) => handleKeyPress(e, totalPages)}
        >
            <FontAwesomeIcon icon={faAnglesRight} className={styles.icon} />
        </li>
    );

    return (
        <Row className="justify-content-center mt-3">
            <Col xs="auto">
                <ul className={styles.pagination} role="navigation" aria-label="Pagination">
                    {paginationItems}
                </ul>
            </Col>
        </Row>
    );
};

export default Pagination;
