import { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import Logo from '../../../components/Logo';
import NavBarPortability from '../../../components/NavBar/Portability';
import styles from './Parameters.module.scss'
import TabsParameters from './TabsParameters';


export default function PortabilityParameters() {
    const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');

    return(
        <>
          <section className={`${styles.navbarSection} mt-4`}>
            <NavBarPortability onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
          </section>
          <div className={styles.logoSection}>
            <Logo/>
          </div>

          <Container className={`${styles.contentSection}`}>
            <Row className='mt-5'>
              <TabsParameters/>
            </Row>
          </Container>
        </>
    )
}