import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Modal, Button, Form, Spinner, Alert } from "react-bootstrap";

interface CreatePortabilityModalProps {
  show: boolean;
  onHide: () => void;
  onSave: () => void;
}

const CreatePortabilityModal: React.FC<CreatePortabilityModalProps> = ({
  show,
  onHide,
  onSave,
}) => {
  const initialFormData = {
    originBank: { id: 0, bankName: "" },
    destinationBank: { id: 0, bankName: "" },
    minimumInstallments: "",
    minimumBalanceAmount: "",
    loas: false,
    legalRepresentative: false,
    srcc: false,
    illiterate: false,
  };

  const [formData, setFormData] = useState(initialFormData);

  const [banks, setBanks] = useState<{ value: number; label: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (show) {
      setLoading(true);
      fetch("https://bi.gfttech.com.br/port/banks")
        .then((response) => {
          if (!response.ok) {
            throw new Error("Falha ao buscar bancos");
          }
          return response.json();
        })
        .then((data) => {
          const formattedBanks = data.map((bank: any) => ({
            value: bank.id,
            label: bank.bankName,
          }));
          setBanks(formattedBanks);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
  }, [show]);

  const handleSave = () => {
    const { minimumInstallments, minimumBalanceAmount } = formData;

    // Validação para garantir que o valor das parcelas mínimas seja válido
    const parsedInstallments = parseInt(minimumInstallments, 10);
    if (parsedInstallments < 1 || isNaN(parsedInstallments)) {
      alert(
        "O número de parcelas mínimas deve ser um número inteiro positivo e maior ou igual a 0."
      );
      return;
    }

    // Validação do saldo mínimo
    const formattedBalance = parseFloat(
      minimumBalanceAmount.replace("R$", "").replace(".", "").replace(",", ".")
    );
    if (isNaN(formattedBalance) || formattedBalance < 0) {
      alert("O valor mínimo de saldo deve ser um número válido e positivo.");
      return;
    }

    // Validação de banco de origem e destino
    if (formData.originBank.id === formData.destinationBank.id) {
      alert("O banco de origem e o banco de destino não podem ser iguais.");
      return;
    }

    const dataToSubmit = {
      originBank: formData.originBank.id,
      destinationBank: formData.destinationBank.id,
      minimumInstallments: parsedInstallments,
      minimumBalanceAmount: formattedBalance,
      loas: formData.loas,
      legalRepresentative: formData.legalRepresentative,
      srcc: formData.srcc,
      illiterate: formData.illiterate,
    };

    // Envio da requisição
    fetch("https://bi.gfttech.com.br/port/portabilities", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSubmit),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao salvar dados");
        }
        return response.json();
      })
      .then(() => {
        alert("Regra criada com sucesso!");
        setFormData(initialFormData); // Limpa os dados do formulário
        onSave();
        onHide();
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const formatCurrency = (value: string) => {
    const numberValue = value.replace(/\D/g, "");
    if (numberValue.length <= 3) {
      return `R$ ${numberValue}`;
    }
    if (numberValue.length <= 6) {
      return `R$ ${numberValue.slice(0, -3)}.${numberValue.slice(-3)}`;
    }
    return `R$ ${numberValue.slice(0, -6)}.${numberValue.slice(-6, -3)}.${numberValue.slice(-3)}`;
  };

  const handleBalanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatCurrency(e.target.value);
    setFormData((prev) => ({
      ...prev,
      minimumBalanceAmount: formattedValue,
    }));
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Criar Regra de Portabilidade</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        )}
        {error && <Alert variant="danger">{error}</Alert>}
        {!loading && !error && (
          <Form>
            <Form.Group controlId="originBank" className="mb-3">
              <Form.Label>Banco de Origem</Form.Label>
              <Select
                options={banks}
                placeholder="Selecione o banco"
                value={banks.find(
                  (option) => option.value === formData.originBank.id
                )}
                onChange={(option) =>
                  setFormData((prev) => ({
                    ...prev,
                    originBank: {
                      ...prev.originBank,
                      id: option?.value || 0,
                      bankName: option?.label || "",
                    },
                  }))
                }
              />
            </Form.Group>
            <Form.Group controlId="destinationBank" className="mb-3">
              <Form.Label>Banco de Destino</Form.Label>
              <Select
                options={banks}
                placeholder="Selecione o banco"
                value={banks.find(
                  (option) => option.value === formData.destinationBank.id
                )}
                onChange={(option) =>
                  setFormData((prev) => ({
                    ...prev,
                    destinationBank: {
                      ...prev.destinationBank,
                      id: option?.value || 0,
                      bankName: option?.label || "",
                    },
                  }))
                }
              />
            </Form.Group>
            <Form.Group controlId="minimumInstallments" className="mb-3">
              <Form.Label>Parcelas Mínimas</Form.Label>
              <Form.Control
                type="number"
                value={formData.minimumInstallments}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    minimumInstallments: e.target.value,
                  }))
                }
              />
            </Form.Group>
            <Form.Group controlId="minimumBalanceAmount" className="mb-3">
              <Form.Label>Valor Mínimo de Saldo</Form.Label>
              <Form.Control
                type="text"
                value={formData.minimumBalanceAmount}
                onChange={handleBalanceChange}
              />
            </Form.Group>
            <Form.Group controlId="loas" className="mb-3">
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  id="loasCheckbox"
                  checked={formData.loas}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      loas: e.target.checked,
                    }))
                  }
                  style={{
                    width: "25px",
                    height: "25px",
                    border: "2px solid #333",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
                <label htmlFor="loasCheckbox">LOAS</label>
              </div>
            </Form.Group>
            <Form.Group controlId="legalRepresentative" className="mb-3">
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  id="legalRepresentativeCheckbox"
                  checked={formData.legalRepresentative}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      legalRepresentative: e.target.checked,
                    }))
                  }
                  style={{
                    width: "25px",
                    height: "25px",
                    border: "2px solid #333",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
                <label htmlFor="legalRepresentativeCheckbox">
                  Representante Legal
                </label>
              </div>
            </Form.Group>
            <Form.Group controlId="srcc" className="mb-3">
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  id="srccCheckbox"
                  checked={formData.srcc}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      srcc: e.target.checked,
                    }))
                  }
                  style={{
                    width: "25px",
                    height: "25px",
                    border: "2px solid #333",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
                <label htmlFor="srccCheckbox">SRCC</label>
              </div>
            </Form.Group>
            <Form.Group controlId="illiterate" className="mb-3">
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  id="illiterateCheckbox"
                  checked={formData.illiterate}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      illiterate: e.target.checked,
                    }))
                  }
                  style={{
                    width: "25px",
                    height: "25px",
                    border: "2px solid #333",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
                <label htmlFor="illiterateCheckbox">Analfabeto</label>
              </div>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreatePortabilityModal;