import React, { useState, useEffect } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import ButtonGrayUltimate from '../../../../../components/Buttons/ButtonGrayUltimate';
import ButtonDelete from '../../../../../components/Buttons/ButtonDelete';
import Pagination from '../../../../../components/Pagination/index';
import ButtonPrimary from '../../../../../components/Buttons/ButtonPrimary';
import EditBankModal from './EditBankModal';
import DeleteBankModal from './DeleteBankModal';
import CreateEditBankModal from './CreateEditBankModal';
import Bank from '../../../../../interfaces/BanksPortability';

const Banks: React.FC = () => {
  const [banks, setBanks] = useState<Bank[]>([]); // Inicializa como um array vazio
  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const [showCreateEditModal, setShowCreateEditModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');

  // Função para atualizar a lista de bancos
  const fetchBanks = async () => {
    try {
      const response = await axios.get('https://bi.gfttech.com.br/port/banks');
      
      if (Array.isArray(response.data)) {
        console.log(response.data);
        
        setBanks(response.data); // Atualiza o estado com os dados recebidos da API
      } else {
        console.error('A resposta da API não é um array.');
      }
    } catch (error) {
      console.error('Erro ao buscar bancos:', error);
    }
  };

  useEffect(() => {
    fetchBanks(); // Carregar bancos ao montar o componente
  }, []);

  const handleCreateBank = () => {
    setSelectedBank(null);
    setShowCreateEditModal(true);
  };

  const handleEditBank = (bank: Bank) => {
    setSelectedBank(bank);
    setShowEditModal(true);
  };

  const handleDeleteBank = (bank: Bank) => {
    setSelectedBank(bank);
    setShowDeleteModal(true);
  };

  const handleSaveBank = () => {
    fetchBanks(); // Atualizar a lista de bancos após salvar
    setShowCreateEditModal(false);
    setShowEditModal(false);
  };

  const handleConfirmDelete = () => {
    setBanks((prevBanks) => prevBanks.filter((b) => b.id !== selectedBank?.id));
    setShowDeleteModal(false);
  };

  const indexOfLastBank = currentPage * itemsPerPage;
  const indexOfFirstBank = indexOfLastBank - itemsPerPage;

  // Filtrando os bancos com base no texto de pesquisa
  const filteredBanks = banks.filter((bank) => 
    (bank.bankName && bank.bankName.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (bank.bankCode && bank.bankCode.toString().toLowerCase().includes(searchQuery.toLowerCase()))
  );
  console.log('lerolero', filteredBanks);
  
  
  const currentBanks = filteredBanks.slice(indexOfFirstBank, indexOfLastBank);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="container">

      <Row>
        <Col sm={12} md={2}>
          <ButtonPrimary onClick={handleCreateBank} content="+ Adicionar Banco" />
        </Col>
        <Col sm={12} md={10}>
          <input
            type="text"
            placeholder="Buscar por código ou nome do banco"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="form-control mb-3"
          />
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Código</th>
            <th>Nome</th>
            <th>Tx. Port</th>
            <th>Tx. Refin</th>
            <th>Editar</th>
            <th>Excluir</th>
          </tr>
        </thead>
        <tbody>
          {currentBanks.map((bank) => (
            <tr key={bank.id}>
              <td>{bank.id}</td>
              <td>{bank.bankCode}</td>
              <td>{bank.bankName}</td>
              <td>{bank.portTax}</td>
              <td>{bank.refinTax}</td>
              <td>
                <ButtonGrayUltimate
                  onClick={() => handleEditBank(bank)}
                  content={<FontAwesomeIcon icon={faEdit} />}
                />
              </td>
              <td>
                <ButtonDelete
                  onClick={() => handleDeleteBank(bank)}
                  content={<FontAwesomeIcon icon={faTrash} />}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={filteredBanks.length}
        onPageChange={paginate}
      />
      
      {showCreateEditModal && (
        <CreateEditBankModal
          onSave={handleSaveBank}
          show={showCreateEditModal}
          onHide={() => setShowCreateEditModal(false)}
        />
      )}

      {showEditModal && (
        <EditBankModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          onSave={handleSaveBank}
          bankToEdit={selectedBank}
        />
      )}

      {showDeleteModal && (
        <DeleteBankModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          onConfirm={handleConfirmDelete}
          bank={selectedBank}
        />
      )}
    </div>
  );
};

export default Banks;
