import {Spinner as BootstrapSpinner} from 'react-bootstrap';
import styles from './Spinner.module.scss'

export default function SpinnerDefault(){
  return(
    <>
      <div className="d-flex justify-content-center">
        <BootstrapSpinner animation="border" role="status">
          <span className={`${styles.spinnerBody} visually-hidden`}>Loading...</span>
        </BootstrapSpinner>
      </div>
    </>
  )
}