import { Modal } from "react-bootstrap";
import ButtonPrimary from "../../../Buttons/ButtonPrimary";
import ButtonGray from "../../../Buttons/ButtonGray";
import { useState } from "react";
import ToastWarning from "../../../ToastWarning";
import { BASE_URL } from "../../../../config";

interface ModalProps{
    showModal: boolean;
    onClose: () => void;
    id: number;
}
const ResetPassWord = ({showModal, onClose, id}: ModalProps) => {
    const [toastMessage, setToastMessage] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    
    const handleSubmit = async () => {
        const token = localStorage.getItem('token');

        try{
            const res = await fetch(`${BASE_URL}/usuario/atualizar-senha-admin/${id}`,{
                method: 'PUT',
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body:JSON.stringify({
                    user_password: password 
                }),
            });

            if(res.ok){
                setToastMessage("Senha resetada com sucesso!");
            } else{
                setToastMessage("Erro ao resetar senha!");
            }
        } catch(err){
            throw err;
        }
    }

    return(
        <Modal show={showModal} onHide={onClose}>
             <Modal.Header closeButton>
                <Modal.Title>Resetar Senha</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className={ `mb-2`}>
                        <input
                            type="text"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Nova Senha"
                            required
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonPrimary onClick={handleSubmit} content="Resetar"/>
                <ButtonGray onClick={onClose} content="Cancelar"/>
            </Modal.Footer>
            {toastMessage && <ToastWarning message={toastMessage}/>}
        </Modal>
    )
}

export default ResetPassWord;