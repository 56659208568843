import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Row, Col } from 'react-bootstrap';
import styles from './EditBankSelection.module.scss';
import ButtonPrimary from '../../../../../components/Buttons/ButtonPrimary';
import ButtonGray from '../../../../../components/Buttons/ButtonGray';
import PageSubTitle from '../../../../../components/PageSubTitle';
import c6Logo from '../../../../../assets/images/c6-bank.png';
import panLogo from '../../../../../assets/images/banco pan.png';
import masterLogo from '../../../../../assets/images/banco master.png';
import bmgLogo from '../../../../../assets/images/banco bmg.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ToastValidationSimulation from '../../../../../components/ToastValidationSimulation';

interface EdiBankSelectionProps {
  show: boolean;
  handleClose: () => void;
  bankSelected: string[];
  updateBanks: (newData: string[]) => void;
}

const BankCard = styled(Col)`
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 35px;
  padding: 1rem;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  text-align: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 1rem #F28705;
  }

  &.selected {
    box-shadow: 0 0 1rem #F28705;
  }
`;

const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: white;
  background: gray;
  padding: 3px;
  border-radius: 50%;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 1.25rem;

  &.selected {
    background-color: #153B7F;
  }
`;

const BankName = styled.div`
  font-size: 1rem;
  margin-top: 0.5rem;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
  color: #F28705;
`;

const BankLogo = styled.img`
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 0.5rem;
`;

const EdiBankSelection: React.FC<EdiBankSelectionProps> = ({ show, handleClose, bankSelected, updateBanks }) => {
  const [selectedBanks, setSelectedBanks] = useState<string[]>(bankSelected);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    if (!show) {
      setToastMessage(''); // Clear toast message when modal is closed
    }
  }, [show]);

  useEffect(() => {
    setSelectedBanks(bankSelected);
  }, [bankSelected]);

  const banks = [
    { id: 'c6', logo: c6Logo, name: 'Banco C6' },
    { id: 'pan', logo: panLogo, name: 'Banco Pan' },
    { id: 'master', logo: masterLogo, name: 'Banco Master' },
    { id: 'bmg', logo: bmgLogo, name: 'Banco BMG' },
  ];

  const handleSelectBank = (bankId: string) => {
    setSelectedBanks((prevSelectedBanks) =>
      prevSelectedBanks.includes(bankId)
        ? prevSelectedBanks.filter((id) => id !== bankId)
        : [...prevSelectedBanks, bankId]
    );
  };

  const handleSave = () => {
    if (selectedBanks.length >= 1) {
      updateBanks(selectedBanks);
      localStorage.setItem('selectedBanks', JSON.stringify(selectedBanks));
      handleClose();
    } else {
      setToastMessage("Precisa selecionar ao menos um banco para simular.");
    }
  };

  const handleCloseWithValidation = () => {
    if (selectedBanks.length >= 1) {
      handleClose();
    } else {
      setToastMessage("Precisa selecionar ao menos um banco para simular.");
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseWithValidation}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <PageSubTitle size='h4'>
            <b>Editar Bancos da Simulação</b>
          </PageSubTitle>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        <div className={`${styles.bodyModal}`}>
          <Row className='d-flex justify-content-center mt-5 mb-5'>
            {banks.map((bank) => (
              <BankCard
                sm={12}
                md={4}
                key={bank.id}
                onClick={() => handleSelectBank(bank.id)}
                className={`${selectedBanks.includes(bank.id) ? 'selected' : ''} mt-5`}
                aria-label={`Select ${bank.name}`}
              >
                <CheckIcon
                  icon={faCheck}
                  className={selectedBanks.includes(bank.id) ? 'selected' : ''}
                  aria-hidden="true"
                />
                <BankLogo src={bank.logo} alt={bank.name} />
                <BankName>{bank.name}</BankName>
              </BankCard>
            ))}
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGray content="Cancelar" onClick={handleCloseWithValidation} />
        <ButtonPrimary content="Salvar" onClick={handleSave} />
      </Modal.Footer>
      {toastMessage && (
        <ToastValidationSimulation message={toastMessage} />
      )}
    </Modal>
  );
};

export default EdiBankSelection;
