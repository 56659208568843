import styled from "styled-components";
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.5rem;
  margin-left: -5%;

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 0.3rem;
  }
`;

export const BankCard = styled(Col)`
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  text-align: center;
  cursor: pointer;
  max-width: 20rem;
  height: 15rem;
  margin: 0.5rem;
  overflow: hidden;

  &:hover {
    box-shadow: 0 0 1rem #F28705;
  }

  &.selected {
    box-shadow: 0 0 1rem #F28705;
  }

  @media (max-width: 768px) {
    max-width: 18rem;
    height: 13rem;
    padding: .8rem;
  }

  @media (max-width: 480px) {
    max-width: 16rem;
    height: 12rem;
    padding: .5rem;
  }
`;

export const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: .5rem;
  right: .5rem;
  color: white;
  background: gray;
  padding: 3px;
  border-radius: .5rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 1.25rem;

  &.selected {
    background-color: #153B7F;
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 2px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    padding: 1px;
  }
`;

export const BankName = styled.div`
  font-size: 1rem;
  margin-top: .5rem;
  font-family: 'PlusJakartaSans-Bold';
  color: #F28705;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: .9rem;
    margin-top: .4rem;
  }

  @media (max-width: 480px) {
    font-size: .8rem;
    margin-top: .3rem;
  }
`;

export const BankLogo = styled.img`
  width: auto;
  height: 80px;
  max-width: 90px;
  object-fit: contain;
  margin-bottom: 0.8rem;
  margin-top: 3rem;

  @media (max-width: 768px) {
    height: 70px;
    max-width: 80px;
    margin-bottom: 0.7rem;
    margin-top: 2.5rem;
  }

  @media (max-width: 480px) {
    height: 60px;
    max-width: 70px;
    margin-bottom: 0.6rem;
    margin-top: 2rem;
  }
`;