import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import styles from './ReturnList.module.scss';
import { useLocation } from "react-router-dom";
import SpinnerDefault from "../../../../../components/Spinner";
import { BankCondition, Conditions, ReturnListProps, SimulationData } from "./ReturnListInterface";
import { URL_SIMULATIONS } from "../../../../../config";

export default function ReturnList({ cpfFilter, setProcessedCPFs, totalCpfs }: ReturnListProps) {
  const [simulations, setSimulations] = useState<SimulationData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [processedCpfs, setProcessedCpfsLocal] = useState<number>(0);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lote = searchParams.get("lote");

  const fetchSimulations = async () => {
    try {
      const response = await fetch(`${URL_SIMULATIONS}/proposal/simulation/find-by-batch?batchId=${lote}`);

      if (!response.ok) {
        throw new Error(`Erro ao carregar os dados. Status: ${response.status}`);
      }

      const data: SimulationData[] = await response.json();
      if (data.length > 0) {
        setSimulations(data);
        const uniqueCpfs = new Set(data.map((simulation) => simulation.cpf));
        const processedCpfsCount = uniqueCpfs.size;
        setProcessedCPFs(processedCpfsCount);
        setProcessedCpfsLocal(processedCpfsCount);
      }
    } catch (err) {
      console.log(err);
      if (retryCount < 3) {
        setRetryCount(retryCount + 1);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSimulations();
  }, [lote, retryCount, setProcessedCPFs]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (processedCpfs < totalCpfs) {
        fetchSimulations();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [processedCpfs, totalCpfs]);

  const formatCurrency = (value: string | number | null) => {
    if (value === null) return "-";
    const number = typeof value === "string" ? parseFloat(value) : value;
    return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const renderCreditData = (simulation: SimulationData) => {
    const conditionKeys = ["master", "c6", "bmg", "pan", "daycoval", "queromais"];
    let creditRows: JSX.Element[] = [];

    for (const key of conditionKeys) {
      const conditions = simulation.conditions.conditions;

      if (conditions && conditions[key as keyof Conditions]) {
        const bankCondition = conditions[key as keyof Conditions];
        const bankName = key.toUpperCase();

        if (Array.isArray(bankCondition)) {
          bankCondition.forEach((condition) => {
            creditRows.push(renderCreditRow(simulation, bankName, condition));
          });
        } else if (typeof bankCondition === "object") {
          creditRows.push(renderCreditRow(simulation, bankName, bankCondition));
        }
      }
    }

    return creditRows;
  };

  const renderCreditRow = (simulation: SimulationData, bankName: string, condition: BankCondition) => {
    if (condition.product === "Cartão RMC/RCC") {
      return (
        <tr key={`${simulation.id}-${bankName}-${condition.id}`}>
          <td>{simulation.cpf}</td>
          <td>{simulation.covenantDescription}</td>
          <td>{bankName}</td>
          <td>{condition.product || "-"}</td>
          <td>{formatCurrency(condition.netAmount ?? null)} (Saque)</td>
          <td>{formatCurrency(condition.installmentAmount ?? null)}</td>
          <td>{formatCurrency(condition.installmentQuantity ?? null)} (Limite do Cartão)</td>
          <td>{condition.clientRate ? `${Number(condition.clientRate).toFixed(1)}%` : "-"}</td>
        </tr>
      );
    } else {
      return (
        <tr key={`${simulation.id}-${bankName}-${condition.id}`}>
          <td>{simulation.cpf}</td>
          <td>{simulation.covenantDescription}</td>
          <td>{bankName}</td>
          <td>{condition.status === "failed" ? <span>{condition.error}</span> : condition.product || "-"}</td>
          <td>{condition.status === "failed" ? <span>{condition.error}</span> : formatCurrency(condition.netAmount ?? null)}</td>
          <td>{condition.status === "failed" ? <span>{condition.error}</span> : formatCurrency(condition.installmentAmount ?? null)}</td>
          <td>{condition.status === "failed" ? <span>{condition.error}</span> : condition.installmentQuantity || "-"}</td>
          <td>{condition.status === "failed" ? <span>{condition.error}</span> : (condition.clientRate ? `${Number(condition.clientRate).toFixed(1)}%` : "-")}</td>
        </tr>
      );
    }
  };

  const filteredSimulations = simulations.filter(simulation => {
    return cpfFilter === "" || simulation.cpf.includes(cpfFilter);
  });

  return (
    <div className={styles.tableContainer}>
      <div className={styles.scrollableTable}>
        <Table className={`${styles.tableStyle} table table-responsive`}>
          <thead>
            <tr>
              <th>CPF</th>
              <th>CONVÊNIO</th>
              <th>BANCO</th>
              <th>PRODUTO</th>
              <th>VLR. TOTAL</th>
              <th>VLR. PARCELAS</th>
              <th>PARCELAS</th>
              <th>TAXA</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={8}>
                  <SpinnerDefault/>
                </td>
              </tr>
            ) : filteredSimulations.length > 0 ? (
              filteredSimulations.flatMap((simulation) => renderCreditData(simulation))
            ) : (
              <tr>
                <td colSpan={8}>
                    <SpinnerDefault />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}