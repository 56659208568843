import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import styles from './ProductListSimulation.module.scss';
import styled from 'styled-components';
import axios from 'axios';
import { URL_SIMULATIONS_V2 } from '../../../../config';

interface ProductListProps {
  onProductSelect: (productId: string, productDescription: string) => void;
  selectedProductId: string | null;
}

interface Product {
  productId: string;
  productDescription: string;
}

const CustomCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 1.25rem;
  height: 1.25rem;
  appearance: none;
  background-color: #153B7F;
  border: 2px solid #153B7F;
  border-radius: 0.25rem;
  cursor: pointer;
  position: relative;

  &:checked::before {
    content: '';
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    background: white;
    border-radius: 0.15rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 165, 0, 0.25);
  }
`;

const CustomLabel = styled.label`
  font-size: 1.1rem;
  color: #153B7F;
  margin-left: 0.5rem;
  font-weight: bold;
  font-family: 'PlusJakartaSans-Light', sans-serif;
`;

const ProductList: React.FC<ProductListProps> = ({ onProductSelect, selectedProductId }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchProducts = async () => {

      // Ajuste na leitura do bankId
      const bankId = JSON.parse(localStorage.getItem("selectedBankIds") || '[]');
      const selectedBankId = Array.isArray(bankId) && bankId.length > 0 ? bankId[0] : null;

      const covenantId = localStorage.getItem("covenantId");

      if (!covenantId) {
        console.error('covenantId não encontrado no localStorage');
        return;
      }

      try {
        const response = await axios.get(
          `${URL_SIMULATIONS_V2}/engine/covenant/products?covenantId=${covenantId}&bankId=${selectedBankId}`
        );
        setProducts(response.data);
      } catch (error) {
        console.error("Erro ao buscar os produtos", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleProductSelect = (productId: string, productDescription: string) => {
    // Chame a função onProductSelect e armazene o productId no localStorage
    onProductSelect(productId, productDescription);
    localStorage.setItem('productId', productId);
    localStorage.setItem('productDescription', productDescription);
  };

  if (loading) {
    return <p>Carregando produtos...</p>;
  }

  return (
    <Col className={styles['product-list-container']}>
      <h5>Selecione um Produto:</h5>
      <Form className='mt-4'>
        {products.map(product => (
          <div key={product.productId} className={styles['product-checkbox']}>
            <CustomCheckbox
              checked={selectedProductId === product.productId}
              onChange={() => handleProductSelect(product.productId, product.productDescription)}
            />
            <CustomLabel>{product.productDescription}</CustomLabel>
          </div>
        ))}
      </Form>
    </Col>
  );
};

export default ProductList;
