import React from 'react';
import { Modal, Row } from 'react-bootstrap';
import styles from './ModalErrorBatchFile.module.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import leroError from '../../../../assets/images/lero-error.png'

interface ModalErrorBatchFileProps {
  show: boolean;
  onClose: () => void;
}

const ModalErrorBatchFile: React.FC<ModalErrorBatchFileProps> = ({ show, onClose }) => {
  return (
    <Modal className={styles.modalGeral} show={show} onHide={onClose}>
      <Modal.Header className={styles.modalHeader} closeButton>
        <Modal.Title>ATENÇÃO AO MODELO!</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <div className={styles.rowLero}>
            <div className='d-flex justify-content-center'>
              <img className={styles.imageModal} src={leroError}/>
            </div>
        </div>
        <h5 className='text-center'>
            Para ter um resultado assertivo, é necessário usar o modelo da planilha repassada.
        </h5>
      </Modal.Body>
    </Modal>
  );
};

export default ModalErrorBatchFile;
