import logo from '../../assets/images/acelera_logo.png'

const Logo = () => {
    return(
        <div className="d-flex justify-content-center mt-5" style={{marginBottom: '-10%'}}>
            <img src={logo} alt="Logo" />
        </div>
    ) 
}

export default Logo;