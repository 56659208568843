import { Modal } from "react-bootstrap";
import ButtonPrimary from "../../../Buttons/ButtonPrimary";
import ButtonGray from "../../../Buttons/ButtonGray";
import { useEffect, useState } from "react";
import { PartnerData } from "../../../../pages/Register/Register";
import { BASE_URL } from "../../../../config";
import SpinnerDefault from "../../../Spinner";
import ToastWarning from "../../../ToastWarning";

interface EditProps{
    showModal: boolean;
    onClose: () => void;
    id: number
}

const EditRegistration = ({showModal, onClose, id}: EditProps) => {
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');

    const fields = [
        {label: 'E-mail', value: email, setValue: setEmail},
        {label: 'Nome', value: name, setValue: setName},
    ];

    const handleData = async () => {
        const token = localStorage.getItem('token');
        
        try {
            setLoading(true);
            const res = await fetch(`${BASE_URL}/parceiro/buscar/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.ok) {
                const data: PartnerData = await res.json();
                setEmail(data.user.email);
                setName(data.user.user_name);
            } else {
                console.log(res.statusText);
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
        } finally{
            setLoading(false);
        }
    }

    const handleSubmit = async () => {
        const token = localStorage.getItem('token');
        
        try {
            const res = await fetch(`${BASE_URL}/parceiro/atualizar/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    email: email,
                    user_name: name
                }),
            });

            if (res.ok) {
                setToastMessage('Acesso editado com sucesso!');
                onClose();
            } else {
                console.log(res.statusText);
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
        }
    }

    useEffect(() => {
        handleData();
    }, [id]);

    return(
        <Modal show={showModal} onHide={onClose}>
             <Modal.Header closeButton>
                <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-start">
                        <Modal.Title>Editar Acesso</Modal.Title>
                    </div>
                    <div className="d-flex justify-content-end">
                        {loading && <SpinnerDefault/>}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    {fields.map((field, index) => (
                        <div className={ `mb-2`} key={index}>
                            <input
                                type="text"
                                id={`field-${index}`}
                                value={field.value}
                                onChange={(e) => field.setValue(e.target.value)}
                                className="form-control"
                                placeholder={field.label}
                                required
                            />
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonPrimary onClick={handleSubmit} content="Enviar"/>
                <ButtonGray onClick={onClose} content="Cancelar"/>
            </Modal.Footer>
            {toastMessage && <ToastWarning message={toastMessage}/>}
        </Modal>
    )
}

export default EditRegistration;