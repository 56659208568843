import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import styles from './EditSimulationData.module.scss'
import ButtonPrimary from '../../../../../components/Buttons/ButtonPrimary';
import ButtonGray from '../../../../../components/Buttons/ButtonGray';
import PageSubTitle from '../../../../../components/PageSubTitle';

interface EditSimulationDataProps {
  show: boolean;
  handleClose: () => void;
  formData: any;
  updateFormData: (newData: any) => void;
}

const EditSimulationData: React.FC<EditSimulationDataProps> = ({ show, handleClose, formData, updateFormData }) => {
  const [localFormData, setLocalFormData] = useState(formData);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLocalFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    updateFormData(localFormData);
    localStorage.setItem('formData', JSON.stringify(localFormData));
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <PageSubTitle size='h4'>
            <b>Editar Dados da Simulação</b>
          </PageSubTitle>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        <Form className={`${styles.bodyModal}`}>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group controlId="formCpf">
                <Form.Label>CPF</Form.Label>
                <Form.Control
                  type="text"
                  name="cpf"
                  value={localFormData.cpf}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group controlId="formRegistration">
                <Form.Label>Matrícula</Form.Label>
                <Form.Control
                  type="text"
                  name="registration"
                  value={localFormData.registration}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col sm={12} md={12}>
              <Form.Group controlId="formBirthDate">
                <Form.Label>Data de Nascimento</Form.Label>
                <Form.Control
                  type="date"
                  name="birthDate"
                  value={localFormData.birthDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col sm={12} md={8}>
              <Form.Group controlId="formParcelValue">
                <Form.Label>Valor da Parcela</Form.Label>
                <Form.Control
                  type="text"
                  name="parcelValue"
                  value={localFormData.parcelValue}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={4}>
              <Form.Group controlId="formNumberOfInstallments">
                <Form.Label>Parcelas</Form.Label>
                <Form.Control
                  type="number"
                  name="numberOfInstallments"
                  value={localFormData.numberOfInstallments}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGray content="Cancelar" onClick={handleClose}/>
        <ButtonPrimary content="Salvar" onClick={handleSave}/>
      </Modal.Footer>
    </Modal>
  );
};

export default EditSimulationData;
