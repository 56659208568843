import { useState } from "react";
import { Modal, Table, Tabs, Tab, Button } from "react-bootstrap";
import ButtonGrayUltimate from "../../Buttons/ButtonGrayUltimate";

type ProposalDetailsModalProps = {
  show: boolean;
  onHide: () => void;
  record: any;
};

export default function ProposalDetailsModal({
  show,
  onHide,
  record,
}: ProposalDetailsModalProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5; // Número de registros por página

  // Lista das chaves de proposta a serem exibidas
  const proposalFields = [
    { key: "proposal_number", label: "Número da Proposta" },
    { key: "digitalization_date", label: "Data de Digitação" },
    { key: "client_credit_date", label: "Data de Crédito do Cliente" },
    { key: "gross_value", label: "Valor Bruto" },
    { key: "net_value", label: "Valor Líquido" },
    { key: "installment_count", label: "Prazo" },
    { key: "installment_value", label: "Valor da Parcela" },
  ];

  // Função para formatar valores monetários em R$
  const formatCurrency = (value: any) => {
    if (value == null || isNaN(value)) return "0,00";

    const numericValue = Number(value);
    return numericValue
      .toFixed(2)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(\,|$))/g, "$1.");
  };

  // Função para formatar datas em d/m/y usando toLocaleDateString nativo
  const formatDate = (date: string) => {
    return date ? new Date(date).toLocaleDateString("pt-BR") : "";
  };

  // Lógica de paginação
  const totalOccurrences = record?.occurrences?.length || 0;
  const totalPages = Math.ceil(totalOccurrences / recordsPerPage);
  const currentOccurrences = record?.occurrences?.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Detalhes da Proposta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {record && (
          <Tabs defaultActiveKey="informacoes" id="proposal-details-tabs">
            {/* Aba de Informações da Proposta */}
            <Tab eventKey="informacoes" title="Informações da Proposta">
              <Table responsive className="mt-4">
                <tbody>
                  {proposalFields.map((field, index) => {
                    const value = record[field.key];
                    if (value !== null && value !== undefined) {
                      let displayValue;
                      if (field.key.includes("date")) {
                        displayValue = formatDate(value);
                      } else if (field.key.includes("value")) {
                        displayValue = "R$ " + formatCurrency(value);
                      } else {
                        displayValue = value;
                      }

                      return (
                        <tr key={index}>
                          <td>{field.label}</td>
                          <td>{displayValue}</td>
                        </tr>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </Table>
            </Tab>

            {/* Aba de Ocorrências */}
            <Tab eventKey="ocorrencias" title="Ocorrências">
              {totalOccurrences > 0 ? (
                <>
                  <Table responsive className="mt-4">
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Atividade</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentOccurrences.map(
                        (ocorrencia: any, index: number) => (
                          <tr key={index}>
                            <td>{formatDate(ocorrencia.created_at)}</td>
                            <td>
                              {ocorrencia.nature.occurrence_nature_description}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <ButtonGrayUltimate
                      content="Anterior"
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    />
                    <span>
                      Página {currentPage} de {totalPages}
                    </span>
                    <ButtonGrayUltimate
                      content="Próximo"
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    />
                  </div>
                </>
              ) : (
                <p>Não há ocorrências registradas para esta proposta.</p>
              )}
            </Tab>
          </Tabs>
        )}
      </Modal.Body>
    </Modal>
  );
}