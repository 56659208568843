import { ListGroup } from 'react-bootstrap';
import styles from './LatestMoves.module.scss';

const LatestMoves = () => {
    //dados fictícios para exbição do extato
    const data = [
        {acao: 'Consulta Margem', valor: '150.00'}, 
        {acao: 'Consulta Margem', valor: '150.00'}, 
        {acao: 'Consulta Margem', valor: '150.00'}, 
        {acao: 'Consulta Margem', valor: '150.00'}, 
        {acao: 'Consulta Margem', valor: '150.00'}, 
        {acao: 'Consulta Margem', valor: '150.00'}, 
    ];

    return(
        <>
            {/*{data.map((item, index) => (
                <ListGroup className={`${styles.lista}`}>
                    <ListGroup.Item 
                        key={index}
                        className={`${styles.listaItem} d-flex justify-content-sm-between mt-2`}
                    >
                        <span>{item.acao}:</span> R$ {item.valor}
                    </ListGroup.Item>
                </ListGroup>
            ))}*/}
        </>
    )
}

export default LatestMoves;