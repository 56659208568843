import { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { BankCard, BankLogo, BankName, CheckIcon } from './BankListSumulation';
import { URL_SIMULATIONS } from '../../../config';
import SpinnerDefault from '../../../components/Spinner';
import Pagination from '../../../components/Pagination';

const formatBankName = (name: string) => {
  return name
    .replace(/BANCO BMG/g, 'bmg')
    .replace(/BANCO|BANK|SOCIETY|CORP/g, '')
    .trim()
    .toLowerCase();
};
interface Bank {
    id: string;
    logo: string;
    name: string;
  }
  

const BankListTyping = ({ isSelectAll, onSelectionChange }: { isSelectAll: boolean, onSelectionChange: (selected: string[]) => void }) => {
  const [selectedBankNames, setSelectedBankNames] = useState<string[]>([]);
  const [selectedBankIds, setSelectedBankIds] = useState<string[]>([]);
  const [banks, setBanks] = useState<{ id: string, logo: string, name: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 4;

  useEffect(() => {
    const savedSelectedBanks = JSON.parse(localStorage.getItem('selectedBankNames') || '[]');
    const savedSelectedBankIds = JSON.parse(localStorage.getItem('selectedBankIds') || '[]');
    setSelectedBankNames(savedSelectedBanks);
    setSelectedBankIds(savedSelectedBankIds);
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedBankNamesTyping', JSON.stringify(selectedBankNames));
    localStorage.setItem('selectedBankIdsTyping', JSON.stringify(selectedBankIds));
    onSelectionChange(selectedBankNames);
  }, [selectedBankNames, selectedBankIds, onSelectionChange]);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await fetch(`${URL_SIMULATIONS}/bank/find-by-covenant-id?covenantId=3`);
        if (!response.ok) throw new Error('Failed to fetch banks');
  
        const data = await response.json();
        const filteredBanks = data
          .map((bank: { bankId: string; bankName: string; path: string }) => ({
            id: bank.bankId,
            logo: bank.path,
            name: bank.bankName
          }))
          .filter((bank: Bank) => {
            const bankNamesToFilter = ['c6', 'facta'];
            return bankNamesToFilter.includes(formatBankName(bank.name));
          });
        
        setBanks(filteredBanks);
      } catch (error) {
        setError(true);
        console.error('Error fetching bank data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBanks();
  }, []);
  
  useEffect(() => {
    if (isSelectAll) {
      const allBanks = banks.map(bank => formatBankName(bank.name));
      setSelectedBankNames(allBanks);
      setSelectedBankIds(banks.map(bank => bank.id));
    } else {
      setSelectedBankNames([]);
      setSelectedBankIds([]);
    }
  }, [isSelectAll, banks]);

  const handleSelectBank = (bankName: string, bankId: string) => {
    const formattedName = formatBankName(bankName);

    setSelectedBankNames(prevSelected =>
      prevSelected.includes(formattedName)
        ? prevSelected.filter(name => name !== formattedName)
        : [...prevSelected, formattedName]
    );

    setSelectedBankIds(prevSelected =>
      prevSelected.includes(bankId)
        ? prevSelected.filter(id => id !== bankId)
        : [...prevSelected, bankId]
    );
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedBanksForPage = banks.slice(startIndex, startIndex + itemsPerPage);

  if (loading) return <SpinnerDefault/>;
  if (error) return <p>Error loading banks. Please try again later.</p>;

  return (
    <>
      <Row className='d-flex justify-content-center mt-5 mb-5'>
        {selectedBanksForPage.map(bank => (
          <BankCard
            sm={12} md={2} key={bank.id}
            onClick={() => handleSelectBank(bank.name, bank.id)}
            className={selectedBankNames.includes(formatBankName(bank.name)) ? 'selected' : ''}
            aria-label={`Select ${bank.name}`}
          >
            <CheckIcon
              icon={faCheck}
              className={selectedBankNames.includes(formatBankName(bank.name)) ? 'selected' : ''}
              aria-hidden="true"
            />
            <BankLogo
              src={bank.logo}
              alt={bank.name}
            />
            <BankName>{bank.name}</BankName>
          </BankCard>
        ))} 
      </Row>
      <Pagination
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        onPageChange={handlePageChange}
        totalItems={banks.length}
      />
    </>
  );
};

export default BankListTyping;
