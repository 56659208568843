import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

interface Factor {
  id: number;
  date: string;
  table: number;
  value: number;
  bank: {
    bankName: string;
  };
}

interface EditFactorModalProps {
  show: boolean;
  onHide: () => void;
  onSave: () => void;
  factorToEdit: Factor | null;
}

const EditFactorModal: React.FC<EditFactorModalProps> = ({
  show,
  onHide,
  onSave,
  factorToEdit,
}) => {
  const [date, setDate] = useState('');
  const [table, setTable] = useState<number>();
  const [value, setValue] = useState<number>();

  useEffect(() => {
    if (factorToEdit) {
      setDate(factorToEdit.date);
      setTable(factorToEdit.table);
      setValue(factorToEdit.value);
    }
  }, [factorToEdit]);

  const handleSave = async () => {
    if (!factorToEdit) return;

    try {
      await axios.patch(`https://bi.gfttech.com.br/port/factor/${factorToEdit.id}`, {
        date,
        table,
        value,
      });
      alert('Fator atualizado com sucesso!');
      onSave();
    } catch (error) {
      alert('Erro ao atualizar fator.');
      console.error(error);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Fator {factorToEdit?.id} - {factorToEdit?.bank.bankName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Data</Form.Label>
            <Form.Control
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Tabela</Form.Label>
            <Form.Control
              type="number"
              value={table}
              onChange={(e) => setTable(Number(e.target.value))}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Valor</Form.Label>
            <Form.Control
              type="number"
              step="0.00001"
              value={value}
              onChange={(e) => setValue(Number(e.target.value))}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditFactorModal;