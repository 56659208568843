import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import styles from './DropdownButtonPrimary.module.scss';

interface DropdownItem {
  label: string;
  link: string;
}

interface CustomDropdownButtonProps {
  title: string;
  items: DropdownItem[];
}

const DropDownButtonPrimary: React.FC<CustomDropdownButtonProps> = ({ title, items }) => {
  return (
    <DropdownButton
      id="dropdown-basic-button"
      title={title}
      className={`${styles.buttonPrimary}`}
      variant="none"
    >
      {items.map((item, index) => (
        <Dropdown.Item key={index} href={item.link}>
          {item.label}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default DropDownButtonPrimary;
