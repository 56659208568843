import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col } from "react-bootstrap";
import styled from "styled-components";

export const BankCard = styled(Col)`
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 35px;
  padding: 1rem;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  text-align: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 1rem #F28705;
  }

  &.selected {
    box-shadow: 0 0 1rem #F28705;
  }

  @media (max-width: 764px) {
    max-width: 15rem;
    margin-left: 0;
    margin-bottom: 1rem;
    padding: 0.8rem;
  }

  @media (max-width: 480px) {
    max-width: 13rem;
    padding: 0.6rem;
  }
`;

export const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: white;
  background: gray;
  padding: 3px;
  border-radius: 50%;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 1.25rem;

  &.selected {
    background-color: #153B7F;
  }

  @media (max-width: 764px) {
    font-size: 1.1rem;
    top: 0.4rem;
    right: 0.4rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    top: 0.3rem;
    right: 0.3rem;
  }
`;

export const BankName = styled.div`
  font-size: 1rem;
  margin-top: 0.5rem;
  font-family: 'PlusJakartaSans-Bold', sans-serif;
  color: #F28705;

  @media (max-width: 764px) {
    font-size: 0.9rem;
    margin-top: 0.4rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
    margin-top: 0.3rem;
  }
`;

export const BankLogo = styled.img`
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 0.5rem;

  @media (max-width: 764px) {
    max-width: 80px;
    max-height: 80px;
  }

  @media (max-width: 480px) {
    max-width: 60px;
    max-height: 60px;
  }
`;