import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import styles from './SendFile.module.scss';
import { motion } from 'framer-motion';
import PageSubTitle from '../../../../components/PageSubTitle';
import NavBar from '../../../../components/NavBar';
import PageTitle from '../../../../components/PageTitle';
import ToastValidationSimulation from '../../../../components/ToastValidationSimulation';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import ModalErrorBatchFile from '../../../../components/Modals/Errors/ModalErrorBatchFile';
import * as XLSX from 'xlsx';
import Logo from '../../../../components/Logo';
import { URL_SIMULATIONS } from '../../../../config';
import SpinnerDefault from '../../../../components/Spinner';

export default function SendFileCovenantBatch() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [toastMessage, setToastMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [uniqueCpfCount, setUniqueCpfCount] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const subtitleTextClient = "Anexe o arquivo conforme o modelo padrão.";

  const handleBack = (e: React.FormEvent) => {
    window.location.href = '/ConsultaLote/Convenios/SelecaoBancos';
  };

  const handleDownloadExample = async () => {
    try {
      const response = await axios.post(
          `${URL_SIMULATIONS}/proposal/simulation/build-document`,
          {},
          { responseType: 'blob' }
      );

      if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'Template-Acelerai-Padrao.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
      } else {
          setToastMessage('Erro ao baixar o modelo.');
          console.log(response.status);
      }
  } catch (error) {
      console.error('Erro na download:', error);
  }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setSelectedFile(file);
        processFile(file); // Processa o arquivo para contar os CPFs únicos
        setShowErrorModal(false);
      } else {
        setShowErrorModal(true);
        setSelectedFile(null);
      }
    }
  };

  const processFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]]; // Assume que os CPFs estão na primeira planilha

      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as unknown[]; // Faz o cast para unknown[]
      const cpfColumnIndex = 0; // Índice da coluna que contém os CPFs

      const cpfs = (jsonData as (string[] | any[])[]) // Faz o cast para uma matriz de arrays
        .slice(1) // Ignora o cabeçalho
        .map((row: any[]) => row[cpfColumnIndex])
        .filter((cpf: string | undefined) => cpf); // Filtra valores não definidos

      const uniqueCpfs = new Set(cpfs);
      setUniqueCpfCount(uniqueCpfs.size);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!selectedFile || uniqueCpfCount === null) {
      setToastMessage('Selecione algum arquivo para prosseguirmos.');
      return;
    }
  
    const formData = new FormData();
  
    const token = localStorage.getItem('token');
    let selectedBanks = JSON.parse(localStorage.getItem('selectedBanks') || '[]'); 
    const formDataLocalStorage = JSON.parse(localStorage.getItem('formData') || '{}');
    const productId = localStorage.getItem('selectedProductId') || "";
    const productDescription = localStorage.getItem('selectedProductDescription') || "";
  
    // Verificar se selectedBanks é de fato um array
    if (!Array.isArray(selectedBanks)) {
      selectedBanks = []; // Garantir que seja uma lista vazia se houver erro
      setToastMessage('Erro: Lista de bancos selecionados está inválida.');
      return;
    }
  
    formData.append('file', selectedFile);
    formData.append('covenantId', formDataLocalStorage.covenantId);
    formData.append('covenantDescription', formDataLocalStorage.covenantDescription);
    formData.append('productId', productId);
    formData.append('productDescription', productDescription);
    formData.append('selectedBanks', selectedBanks.join(','));
    
    console.log(JSON.stringify(selectedBanks)); 

    setLoading(true);

    try {
      const response = await axios.post(
        `${URL_SIMULATIONS}/proposal/simulation/batch`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      if (response.status === 200) {
        const batchId = response.data.batchId;
        window.location.href = `/ConsultaLote/Convenios/Retorno?lote=${batchId}&tC=${uniqueCpfCount}`;
      } else {
        setToastMessage(response.data.message || 'Erro ao enviar a planilha.');
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Erro ao realizar a requisição.';
      setToastMessage(errorMessage);
    } 
    finally {
      setLoading(false);
    }
  };
  
  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <div className={styles.logoSection}>
        <Logo/>
      </div>

      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={styles.titlesSection}> 
          <div className={`${styles.titleSection} mt-5`}>
            <PageTitle size="h2">Consulta em Lote</PageTitle>
          </div>
          <div className={`${styles.subTitleSection} mb-3`}>
            <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
          </div>
        </div>
        
        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Row className='mt-5'>
            <Col md={4} sm={12}>
              <ButtonPrimary content={<><FontAwesomeIcon icon={faDownload} /> Baixar modelo padrão</>} onClick={handleDownloadExample}/>
            </Col>
          </Row>

          <Row className='mt-4 mb-5'>
            <Col md={4} sm={12}>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control type="file" onChange={handleFileChange}/>
              </Form.Group>
            </Col>
          </Row>

          <Row className='mt-5'>
            <Col className='d-flex justify-content-start mt-5'>
              <ButtonPrimary 
                content={<><FontAwesomeIcon icon={faArrowLeft} /> <span>Voltar</span></>} 
                onClick={handleBack}
              />
            </Col>
            <Col className='d-flex justify-content-center mt-5'>
            {loading && 
              <SpinnerDefault/>
              }
            </Col>
            <Col className='d-flex justify-content-end mt-5'>
              <ButtonPrimary 
                content={<><span>Prosseguir</span> <FontAwesomeIcon icon={faArrowRight} /></>} 
                onClick={handleSubmit}
              />
            </Col>
          </Row>
          
        </Container>
        {toastMessage && <ToastValidationSimulation message={toastMessage} />}
        {showErrorModal && <ModalErrorBatchFile show={showErrorModal} onClose={() => setShowErrorModal(false)} />}
      </motion.div>
    </>
  );
}
