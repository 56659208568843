import { useState } from 'react';
import styles from './CreateForm.module.scss';
import { Col, Row } from 'react-bootstrap';
import ButtonPrimary from '../../../../../../../components/Buttons/ButtonPrimary';
import ToastWarning from '../../../../../../../components/ToastWarning';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import CreateAccess from '../../../../../../../components/Modals/Access/CreateAccess';
import { BASE_URL } from '../../../../../../../config';
  
const CreateForm = ({onProceed}: {onProceed: (page: string) => void;}) =>{
    const [login, setLogin] = useState('');
    const [bankUserId, setBankUserId] = useState('');
    const [password, setPassword] = useState('');
    const [key, setKey] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [cpf, setCpf] = useState<string>('');

    const bankName = localStorage.getItem('bankName');
    const bankId = localStorage.getItem('bankId');

    const handleModal = () => {
        setShowModal(true);
        setTimeout(()=> {
            setShowModal(false);
            onProceed('access');
        }, 3000);
    };

    const formatCPF = (cpf: string): string => {
        const numericCpf = cpf.replace(/\D/g, '');
        return numericCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    };

    const keysEnabled = Number(bankId) === 1 || Number(bankId) === 7;

    const buttonDesable = () => {
        const fieldsIvalid = login.trim() === '' || password.trim() === '' || cpf.trim() === ''; 
        const keysFieldInvalid = keysEnabled && (key.trim() === '' || secretKey.trim() === ''); 
        return fieldsIvalid || keysFieldInvalid;
    };

    const handleSubmit = async () => {
        const token = localStorage.getItem('token');

        try{
            const res = await fetch(`${BASE_URL}/acesso-usuario-banco`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    bank_id: bankId,
                    login: login, 
                    access_name: "",
                    password: password,
                    bank_user_id: bankUserId,
                    api_key: key,  
                    secret_key: secretKey,
                    user_digitizer_cpf: cpf
                }),
            });
            if(res.ok){
                handleModal();
            }
        } catch(error) {
            setToastMessage('Falha ao enviar dados!');
            console.error(error);
        }
    }

    const keyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter' && !buttonDesable()){
            handleSubmit();
        }
    };

    const fields = [
        { id: "login", label: "Login", type: "text", value: login, onChange: (e: any) => setLogin(e.target.value), required: true},
        { id: "bank_user_id", label: "Código do Operador", type: "text", value: bankUserId, onChange: (e: any) => setBankUserId(e.target.value), required: false},
        { id: "cpf", label: "CPF", type: "text", value: formatCPF(cpf), onChange: (e: any) => setCpf(e.target.value), required: true},
        { id: "senha", label: "Senha", type: showPassword ? "text" : "password", value: password, onChange: (e: any) => setPassword(e.target.value), 
            extraContent: (
                <div className={`${styles.icon}`} onClick={() => setShowPassword(!showPassword)}>
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </div>
            ),
            required: true,
        },
        ...(keysEnabled ? [
                  { id: "key", label: "Key", type: "text", value: key, onChange: (e: any) => setKey(e.target.value), required: keysEnabled},
                  { id: "secretKey", label: "Secret Key", type: "text", value: secretKey, onChange: (e: any) => setSecretKey(e.target.value), onKeyDown: keysEnabled ? keyPress : undefined, required: keysEnabled},
              ]
            : []),
    ];

    return(
        <>
            <div className={`${styles.title} d-flex justify-content-center mt-3 mb-2`}>
                <h2>{bankName}</h2>
            </div>
            {fields.map((field) => (
                <div key={field.id} className={styles.floatingLabel}>
                    <input
                        type={field.type}
                        id={field.id}
                        value={field.value}
                        onChange={field.onChange}
                        onKeyDown={field.onKeyDown}
                        className={`form-control ${styles.input}`}
                        required={field.required}
                    />
                    <label htmlFor={field.id}>{field.label}</label>
                    {field.extraContent && field.extraContent}
                </div>
            ))}
            <Row>
                <Col className="d-flex justify-content-center mt-3">
                    <ButtonPrimary
                        disabled={buttonDesable()}
                        content="Salvar informações" 
                        onClick={handleSubmit}
                    />
                </Col>

                <CreateAccess show={showModal} onClose={() => setShowModal(false)}/>
            </Row>
            
            {toastMessage && <ToastWarning message={toastMessage} />}
        </>
    )
}

export default CreateForm;