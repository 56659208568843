// src/components/ButtonPrimary.tsx
import React from 'react';
import styles from './ButtonGray.module.scss';
import { Button } from 'react-bootstrap';

interface ButtonGrayProps {
  content: any;
  onClick: () => void;
  disabled?: boolean;
}

const ButtonGray: React.FC<ButtonGrayProps> = ({ content, onClick, disabled }) => {
  return (
    <Button
      className={`btn ${styles.buttonGray}`}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </Button>
  );
};

export default ButtonGray;