import { Modal } from "react-bootstrap";
import ButtonPrimary from "../../../Buttons/ButtonPrimary";
import ButtonDelete from "../../../Buttons/ButtonDelete";
import { BASE_URL } from "../../../../config";
import { useState } from "react";
import ToastWarning from "../../../ToastWarning";
import ButtonGray from "../../../Buttons/ButtonGray";

interface DeleteProps{
    showModal: boolean;
    onClose: () => void;
    id: number;
}

const DeleteAccess = ({showModal, onClose, id}: DeleteProps) =>{
    const [toastMessage, setToastMessage] = useState<string>('');
    const token = localStorage.getItem('token');

    const handleRemove = async () => {
        try{
            const res = await fetch(`${BASE_URL}/acesso-usuario-banco/${id}`,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if(res.ok){
                setToastMessage('Acesso removido com sucesso!');
            } else{
                console.log(res.statusText);
                setToastMessage('Erro na remoção do acesso!');
            }
        } catch(error){
            console.error('Erro na remoção:', error);
            setToastMessage('Erro ao remover o acesso!');
        }

        setTimeout(() => {
            onClose();
        }, 1000);
    }

    return(
        <Modal show={showModal} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Excluir Acesso?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <ButtonDelete onClick={handleRemove} content="Excluir"/>
                <ButtonGray onClick={onClose} content="Cancelar"/>
            </Modal.Footer>
            {toastMessage && <ToastWarning message={toastMessage} />}
        </Modal>
    )
}

export default DeleteAccess;