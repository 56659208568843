import { useState, useEffect } from "react";
import { Container, Table, Button, Row, Col, Spinner } from "react-bootstrap";
import Select from "react-select";
import Logo from "../../../components/Logo";
import styles from "./TypingListEffected.module.scss";
import axios from "axios";
import dayjs from "dayjs";
import ButtonGrayUltimate from "../../../components/Buttons/ButtonGrayUltimate";
import { faInfoCircle, faLink, faRefresh, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonPrimary from "../../../components/Buttons/ButtonPrimary";
import ProposalDetailsModal from "../../../components/Modals/TypingEffected";
import Pagination from "../../../components/Pagination";
import PaginationRequestTyping from "../../../components/PaginationRequestTyping";
import NavBar from "../../../components/NavBar";
import LinkProposal from "../../../components/Modals/LinkProposal";

type BankOption = {
  value: number | string;
  label: string;
};

type ProposalData = {
  contract_id: string;
  proposal_number: string;
  client: {
    full_name: string;
    cpf: string;
  };
  digitalization_date: string | null;
  import_date: string | null;
  gross_value: string | null;
  situation?: {
    description: string;
  };
  bank: {
    id_bank: number;
    name: string;
  };
};

type Pagination = {
  current_page: number;
  last_page: number;
  per_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
  prev_page_url: string | null;
  links: {
    url: string | null;
    label: string;
    active: boolean;
  }[];
  to: number;
  total: number;
};

type Link = {
  url: string;
  label: string;
  active: string;
};

export default function TypingListEffectedAgent() {
  const [menuOpen, setMenuOpen] = useState<boolean>(
    localStorage.getItem("isMenuOpen") === "true"
  );
  const token = localStorage.getItem("token");
  const [allData, setAllData] = useState<ProposalData[]>([]);
  const [filteredData, setFilteredData] = useState<ProposalData[]>([]);
  const [banks, setBanks] = useState<BankOption[]>([]);
  const [statuses, setStatuses] = useState<BankOption[]>([]);
  const [selectedBank, setSelectedBank] = useState<BankOption | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<BankOption | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pagination, setPagination] = useState<Pagination>({
    current_page: 1,
    last_page: 1,
    per_page: 1,
    to: 1,
    total: 1,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "", // Inicializando com uma string vazia ou null se preferir
    prev_page_url: null, // Permitir null, se não houver página anterior
    links: []
  });
  
  const [selectedRecord, setSelectedRecord] = useState<ProposalData | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalLink, setShowModalLink] = useState(false);
  
  // Estado para armazenar o partner_id
  const [partnerId, setPartnerId] = useState<number | null>(null);

  // Filtro por período de datas
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [numberProposalFilter, setNumberProposalFilter] = useState("")
  const [cpfClientFilter, setCpfClientFilter] = useState("")
  const [nameClientFilter, setNameClientFilter] = useState("")
  
  const [sortBy, setSortBy] = useState<"date" | "value">("date");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  // Função para validar e pegar o partner_id
  const validatePartnerId = async () => {
    try {
      const response = await axios.get(
        "https://webservices.gfttech.com.br/api/v1/parceiro/dados-logado", 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const partnerData = response.data.partnerData;
      
      if (partnerData && partnerData.partner_id) {
        setPartnerId(partnerData.partner_id);
      } else {
        console.error("Partner ID não encontrado.");
      }
    } catch (error) {
      console.error("Erro ao validar o partner_id:", error);
    }
  };

  // Função para obter dados da API com partner_id
  const fetchData = async (page = 1) => {
    if (!partnerId) {
      console.error("Partner ID não válido.");
      return;
    }

    try {
      // Montar os query params com base nos filtros
      const params: Record<string, string | undefined> = {
        page: String(page), // Garantir que todos os valores sejam strings
        perPage: '5',
        cpf_cliente: cpfClientFilter || undefined,
        nome_cliente: nameClientFilter || undefined,
        numero_proposta: numberProposalFilter || undefined,
        startDate: startDate || undefined,
        endDate: endDate || undefined,
        banco : selectedBank ? String(selectedBank.value) : undefined, // Filtro banco
        situacao: selectedStatus ? String(selectedStatus.value) : undefined, // Filtro status
        order: 'desc'
      };
  
      // Remover valores undefined e criar o query string
      const queryParams = new URLSearchParams(
        Object.entries(params)
          .filter(([_, v]) => v !== undefined) // Filtrar apenas os valores definidos
          .map(([k, v]) => [k, String(v)]) // Garantir que todos os valores sejam strings
      ).toString();
  
      const response = await axios.get(
        `https://webservices.gfttech.com.br/api/v1/propostas-digitadas/${partnerId}?${queryParams}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const responseData = response.data;
  
      // Atualizar dados e paginação
      setAllData(responseData.data);
      setPagination({
        current_page: responseData.current_page,
        last_page: responseData.last_page,
        per_page: responseData.per_page,
        to: responseData.to,
        total: responseData.total,
        first_page_url: responseData.first_page_url || "", // Preencher com valor vazio caso seja null
        last_page_url: responseData.last_page_url || "",   // Preencher com valor vazio caso seja null
        next_page_url: responseData.next_page_url || "",   // Preencher com valor vazio caso seja null
        prev_page_url: responseData.prev_page_url || null, // Permitir null
        links: responseData.links.map((link: Link) => ({
          url: link.url,
          label: link.label,
          active: link.active === 'true',
        }))
      });
      
  
      // gera lista única de bancos para o filtro
      const bankOptions: BankOption[] = responseData.data.map((item: ProposalData) => ({
        value: item.bank.id_bank,
        label: item.bank.name,
      }));
      const uniqueBankOptions = bankOptions.filter(
        (option, index, self) =>
          index === self.findIndex((o) => o.value === option.value && o.label === option.label)
      );
      setBanks(uniqueBankOptions);
  
      // gera lista única de status para o filtro
      const statusOptions: BankOption[] = responseData.data
        .map((item: ProposalData) =>
          item.situation?.description
            ? { value: item.situation.description, label: item.situation.description }
            : { value: "Sem Status", label: "Sem Status" }
        )
        .filter((item: any) => item.value !== "");
      const uniqueStatusOptions = statusOptions.filter(
        (option, index, self) =>
          index === self.findIndex((o) => o.value === option.value && o.label === option.label)
      );
      setStatuses(uniqueStatusOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    validatePartnerId();  // valida o partner_id quando o componente é montado
  }, []);

  useEffect(() => {
    if (partnerId) {
      fetchData();  // chama a função de busca de dados assim que o partnerId for validado
    }
  }, [partnerId]);

  useEffect(() => {
    const filtered = allData.filter(
      (item) =>
        (!selectedBank || item.bank.id_bank === selectedBank.value) &&
        (!selectedStatus || item.situation?.description === selectedStatus.value) &&
        (item.proposal_number.includes(searchTerm) ||
          item.client.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.client.cpf.includes(searchTerm)) &&
        (startDate ? dayjs(item.digitalization_date).isAfter(dayjs(startDate)) : true) &&
        (endDate ? dayjs(item.digitalization_date).isBefore(dayjs(endDate)) : true)
    );

    // Ordenação
    const sorted = filtered.sort((a, b) => {
      if (sortBy === "date") {
        return sortOrder === "asc"
          ? dayjs(a.digitalization_date).isBefore(dayjs(b.digitalization_date))
            ? -1
            : 1
          : dayjs(a.digitalization_date).isBefore(dayjs(b.digitalization_date))
          ? 1
          : -1;
      } else {
        const aValue = parseFloat(a.gross_value || "0");
        const bValue = parseFloat(b.gross_value || "0");
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      }
    });

    setFilteredData(sorted);
  }, [allData, selectedBank, selectedStatus, searchTerm, startDate, endDate, sortBy, sortOrder]);

  const formatCurrency = (value: string | null) =>
    value
      ? `R$ ${parseFloat(value).toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
        })}`
      : "-";

  const formatDate = (date: string | null) =>
    date ? dayjs(date).format("DD/MM/YYYY") : "-";

  const openModal = (record: ProposalData) => {
    setSelectedRecord(record);
    setShowModal(true);
  };

  const openModalLink = (record: ProposalData) => {
    setSelectedRecord(record);
    setShowModalLink(true);
  };
  const closeModal = () => setShowModal(false);
  const closeModalLink = () => setShowModalLink(false);
  const handleSort = (column: "date" | "value") => {
    const newSortOrder = sortBy === column && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  const exportarExcel = async (dadosTabela: any) => {
    if(numberProposalFilter || cpfClientFilter || nameClientFilter || startDate || endDate){
      try {
        const response = await axios.post(
          'https://webapi.gfttech.com.br/api/portal/createFile/excel',
          {
            fileName: 'LISTAGEM_PROPOSTAS_ACELEREAI',
            pageName: 'ListagemPropostasClientes',
            pageHeader: [
              'Nº PROPOSTA',
              'CPF',
              'CLIENTE',
              'BANCO',
              'DATA DIGITAÇÃO',
              'VALOR BRUTO',
              'VALOR LIQUIDO',
              'STATUS',
            ],
            data: dadosTabela.map((record: any) => ({
              'Nº PROPOSTA': record.proposal_number,
              CPF: record.client.cpf ,
              CLIENTE: record.client.full_name,
              BANCO: record.bank.name,
              'DATA DIGITAÇÃO':  formatDate(record.digitalization_date),
              'VALOR BRUTO': formatCurrency(record.gross_value),
              'VALOR LIQUIDO': formatCurrency(record.net_value),
              STATUS: record.situation?.description || 'Sem Status',
            })),
          },
          {
            responseType: 'blob',
          }
        );
    
        const fileBlob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileURL = URL.createObjectURL(fileBlob);
    
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = 'listagem_propostas_acelereai.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        } catch (error) {
          console.error('Erro ao gerar o arquivo:', error);
      }
    }
    else {
      const response = await fetch(
          `https://webservices.gfttech.com.br/api/v1/propostas-digitadas/${partnerId}?perPage=${pagination.total}`,
          {
              method: "GET",
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`,
              },
          }
      );
  
      const data = await response.json();
      const proposals: any[] = data.data.map((value: any) => (
        {
          proposalNumber: value.proposal_number,
          cpf: value.client.cpf,
          name: value.client.full_name,
          bank: value.bank.name,
          date: formatDate(value.digitalization_date),
          grossValue: formatCurrency(value.gross_value) ,
          netValue: formatCurrency(value.net_value),
          status: value.situation.description
        }
      ));
  
      const responseAll = await axios.post(
        'https://webapi.gfttech.com.br/api/portal/createFile/excel',
        {
          fileName: 'LISTAGEM_PROPOSTAS_ACELEREAI',
          pageName: 'ListagemPropostasClientes',
          pageHeader: [
            'Nº PROPOSTA',
            'CPF',
            'CLIENTE',
            'BANCO',
            'DATA_DIGITACAO',
            'VALOR BRUTO',
            'VALOR LIQUIDO',
            'STATUS'
          ],
          data: proposals,
        },
        {
          responseType: 'blob',
        }
      );

      const fileBlob = new Blob([responseAll.data]);
      const fileURL = URL.createObjectURL(fileBlob);
  
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = 'listagem_propostas_acelereai.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }
  };

  const applyFilter = () => {
    fetchData();
  }

  return (
    <>
      <section className={`${styles.navbarSection} mt-5`}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <div className={styles.logoSection}>
        <Logo/>
      </div>

      <Container
        className={`${styles.contentSection} ${
          menuOpen ? styles.sideBarOpen : styles.sideBarClose
        } mt-5`}
      >
        <Row className="mb-4">
          <Col md="3">
            <input
              type="text"
              className="form-control"
              placeholder="CPF"
              value={cpfClientFilter}
              onChange={(e) => setCpfClientFilter(e.target.value)}
            />
          </Col>
          <Col md="4">
            <input
              type="text"
              className="form-control"
              placeholder="Nome do Cliente"
              value={nameClientFilter}
              onChange={(e) => setNameClientFilter(e.target.value)}
            />
          </Col>
          <Col md="3">
            <input
              type="text"
              className="form-control"
              placeholder="Proposta"
              value={numberProposalFilter}
              onChange={(e) => setNumberProposalFilter(e.target.value)}
            />
          </Col>
          <Col md="2">
            <ButtonPrimary content={<FontAwesomeIcon icon={faSearch}/>} onClick={applyFilter}/>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-start">
          <ButtonPrimary
              onClick={applyFilter}
              disabled={isLoading}
              content={
                isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Atualizando...
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faRefresh} className="me-2" />
                    <b>Atualizar</b>
                  </>
                )
              }
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <ButtonGrayUltimate onClick={() => exportarExcel(allData)} content="Exportar" />
          </Col>
        </Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>PROPOSTA</th>
              <th>CPF</th>
              <th>CLIENTE</th>
              <th>BANCO</th>
              <th>DATA</th>
              <th>VALOR</th>
              <th>STATUS</th>
              <th>DADOS</th>
              <th>LINK</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((record) => (
              <tr key={record.proposal_number}>
                <td>{record.proposal_number}</td>
                <td>{record.client.cpf}</td>
                <td>{record.client.full_name}</td>
                <td>{record.bank.name}</td>
                <td>{formatDate(record.digitalization_date)}</td>
                <td>{formatCurrency(record.gross_value)}</td>
                <td>{record.situation?.description || "Sem Status"}</td>
                <td>
                  <ButtonGrayUltimate
                    content={<FontAwesomeIcon icon={faInfoCircle} />}
                    onClick={() => openModal(record)}
                  />
                </td>
                <td>
                  <ButtonGrayUltimate
                    content={<FontAwesomeIcon icon={faLink} />}
                    onClick={() => openModalLink(record)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Row className="mt-3">
          <PaginationRequestTyping
            currentPage={pagination.current_page}
            lastPage={pagination.last_page}
            firstPageUrl={pagination.first_page_url}
            nextPageUrl={pagination.next_page_url}
            prevPageUrl={pagination.prev_page_url}
            pageUrls={pagination.links}
            onPageChange={fetchData}
          />
        </Row>
      </Container>

      <ProposalDetailsModal
        show={showModal}
        onHide={closeModal}
        record={selectedRecord}
      />

      <LinkProposal
        show={showModalLink}
        onHide={closeModalLink}
        record={selectedRecord}
      />

    </>
  );
}