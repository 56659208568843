import { useState } from 'react';
import { Form } from 'react-bootstrap';
import ButtonPrimary from '../../../../../components/Buttons/ButtonPrimary';
import styles from './ChangePassword.module.scss';
import ToastWarning from '../../../../../components/ToastWarning';
import { BASE_URL } from '../../../../../config';

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            setToastMessage('As novas senhas não correspondem.');
            return;
        }
        
        try{
            const idUser = 4;
            const token = localStorage.getItem('token');

            const res = await fetch(`${BASE_URL}/usuario/atualizar-senha/${idUser}`,{
                method: 'PUT',
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body:JSON.stringify({
                    old_password: currentPassword,
                    password: newPassword,
                    password_confirmation: newPassword 
                })
            });
            
            
            if(res.ok){
                const data = await res.json();
                console.log(data);
                setToastMessage('Senha alterada com sucesso!');
            } else{
                console.log(res.statusText);
                setToastMessage('Erro ao alterar a senha.');
            }

        } catch(error){
            setToastMessage('Erro na alteração de senha');
            console.error(error);
        }
    };

    const buttonDesable = () => {
        return newPassword.trim() === '' || confirmPassword.trim() === '' || currentPassword.trim() === '';
    }

    return (
        <Form onSubmit={handleSubmit}>
        
            <Form.Group className={styles.floatingLabel} controlId="currentPassword">
                <Form.Control
                    type="password"
                    placeholder=" "
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <Form.Label>Senha Atual</Form.Label>
            </Form.Group>
            
            <Form.Group className={styles.floatingLabel} controlId="newPassword">
                <Form.Control
                    type="password"
                    placeholder=" "
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <Form.Label>Nova Senha</Form.Label>
            </Form.Group>
            
            <Form.Group className={styles.floatingLabel} controlId="confirmPassword">
                <Form.Control
                    type="password"
                    placeholder=" "
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Form.Label>Confirmar Nova Senha</Form.Label>
            </Form.Group>
            <div className='d-flex justify-content-center mt-5'>
                <ButtonPrimary disabled={buttonDesable()} content="Alterar Senha" onClick={handleSubmit} />
            </div>
            {toastMessage && <ToastWarning message={toastMessage} />}
        </Form>
    );
};

export default ChangePassword;
