import { Card, Modal } from 'react-bootstrap';
import styles from './Cards.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faKeyboard, faMoneyBillWave, faPaperPlane, faShop, faUser, faUsers, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonDelete from '../../../../../../components/Buttons/ButtonDelete';
import ButtonPrimary from '../../../../../../components/Buttons/ButtonPrimary';
import { BASE_URL } from '../../../../../../config';

interface CardData {
    card_id: number;
    card_name: string;
    user_cards_id: number; 
}

interface IconProp {
    iconState: boolean;
    cardsData: { card_id: number; card_name: string; user_cards_id: number }[]; 
    onResponse: (response: any) => void;
}

const Cards: React.FC<IconProp> = ({ iconState, cardsData, onResponse }) => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState<CardData | null>(null);

    const handleModal = (card: CardData) => {
        setShowModal(true);
        setSelectedCard(card)
    }

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedCard(null);
    }

    // Mapeamento com base no card_id
    const CardIcons: { [key: number]: any } = {
        1: {icon: faUser, route: ''},
        2: {icon: faUsers, route: ''},
        3: {icon: faKeyboard, route: ''},
        4: {icon: faShop, route: ''},
        5: {icon: faMoneyBillWave, route: ''},
        6: {icon: faBell, route: ''},
        7: {icon: faPaperPlane, route: '/ListagemSimulacoes'},
    };
    

    const handleRemove = async (e: any, card: CardData) => {
        e.stopPropagation();
        e.preventDefault(); //impede que o link se propague para o ícone de exclusão

        try {
            const res = await fetch(`${BASE_URL}/acelerai/card/user/remove/${card.user_cards_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.ok) {
                onResponse('Card removido com sucesso!');
            } else {
                onResponse('Error na remoção do card!');
                console.log('Erro ao remover o card', res.statusText);
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
            onResponse('Erro ao remover card!');
        }
    };

    const UnitaryBody = () => {
        return(
            <>
                <Modal.Header closeButton>
                    <Modal.Title className={styles.modalTitle}>Consulta Unitária</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <ButtonDelete content="INSS" onClick={() => navigate('/ConsultaUnitaria/Inss/SelecaoBancos')} />
                    <ButtonPrimary content="Convênios" onClick={() => navigate('/ConsultaUnitaria/Convenios')} />
                </Modal.Footer>
            </>
        )
    }
   
    const BatchBody = () => {
        return(
            <>
                <Modal.Header closeButton>
                    <Modal.Title className={styles.modalTitle}>Consulta Lote</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <ButtonDelete content="INSS" onClick={() => navigate('/ConsultaLote/INSS')} />
                    <ButtonPrimary content="Convênios" onClick={() => navigate('/ConsultaLote/Convenios')} />
                </Modal.Footer>
            </>
        )
    }

    const renderDirection = (card: CardData) => {
        if(card.card_id === 1 || card.card_id === 2){
            handleModal(card); //abre o modal caso o id dos cards seja correspondente a consulta unitária ou lote
        } else{
            const route = CardIcons[card.card_id].route;
            if (route) {
                navigate(route); //redireciona a rota indicada 
            }
        }
    }

    // Filtra os cards para exibir apenas aqueles que o usuário selecionou
    const filteredCards = cardsData.filter((card) => card.user_cards_id);

    return (
        <>
            {filteredCards.map((filterCard) => (
                <div className={`${styles.mainCard}`} key={filterCard.card_id}>
                    <div onClick={() => renderDirection(filterCard)} className={styles.linkCard}>
                        <Card className={styles.cardContainer}>
                            {iconState && (
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className={styles.iconOption}
                                    onClick={(e) => handleRemove(e, filterCard)}
                                />
                            )}
                            <Card.Body className={`${styles.iconContainer} d-flex justify-content-center`}>
                                <FontAwesomeIcon icon={CardIcons[filterCard.card_id].icon} className={styles.icon} />
                            </Card.Body>
                            <span className={styles.text}>{filterCard.card_name}</span>
                        </Card>
                    </div>
                </div>
            ))}

            <Modal show={showModal} onHide={handleModalClose}>
                {selectedCard && (
                    <>
                    {selectedCard.card_id === 1 && <UnitaryBody />}
                    {selectedCard.card_id === 2 && <BatchBody />}
                    </>
                )}
            </Modal>
        </>
    );
};

export default Cards;