import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './BlockPrint.module.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import leroError from '../../../../assets/images/NO PICTURES@3x.png'

interface BlockPrintProps {
  show: boolean;
  onClose: () => void;
}

const BlockPrint: React.FC<BlockPrintProps> = ({ show, onClose }) => {
  return (
    <Modal className={styles.modalGeral} show={show} onHide={onClose}>
      <Modal.Header className={styles.modalHeader} closeButton>
        <Modal.Title>Captura de tela detectada!</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <div className={styles.rowLero}>
            <div className='d-flex justify-content-center'>
              <img className={styles.imageModal} src={leroError}/>
            </div>
        </div>
        <h5 className='text-center'>
          Por favor, não faça capturas de tela desta página.
        </h5>
      </Modal.Body>
    </Modal>
  );
};

export default BlockPrint;
