import { useState } from 'react';
import styles from './Notifications.module.scss';
import NavBar from '../../components/NavBar';
import { Card, Container } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';
import leroTec from '../../assets/images/lero_tec.png';
import Pagination from '../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import ButtonDelete from '../../components/Buttons/ButtonDelete';
import styled from 'styled-components';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import Logo from '../../components/Logo';

const CustomCheckbox = styled(FontAwesomeIcon)`
    position: absolute;
    top: 1rem;
    left: 103%;
    border: 1px solid #ccc;
    background: #fff;
    color: #fff;
    padding: 3px;
    border-radius: .15rem;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 1.25rem;
    height: .9rem;
    width: 1rem;

    &.selected {
        background-color: #153B7F;
        color: #fff;
        border: none;
    }
`;

export default function Notifications() {
    const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
    const [showCheckbox, setShowCheckbox] = useState<boolean>(false);
    const [selectNotifications, setSelectNotifications] = useState<number[]>([]);
    const [allSelected, setAllSelected] = useState(false);

    const notifications = [
        {id: 1, content: 'notificação teste 1', date: '2024-10-03T11:26:32.000000Z'},
        {id: 2, content: 'notificação teste 2', date: '2024-09-30T11:26:32.000000Z'},
        {id: 3, content: 'notificação teste 3', date: '2024-10-01T11:26:32.000000Z'},
        {id: 4, content: 'notificação teste 4', date: '2024-08-24T09:26:32.000000Z'},
        {id: 5, content: 'notificação teste 5', date: '2024-10-01T11:40:32.000000Z'},
        {id: 6, content: 'notificação teste 6', date: '2024-09-03T11:26:32.000000Z'},
        {id: 7, content: 'notificação teste 7', date: '2024-09-15T11:26:32.000000Z'},
    ];

    const orderNotifications = notifications.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    //const currentNotifications = orderNotifications.slice(0,3);

    //variáveis para paginação
    const itemsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(orderNotifications.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, orderNotifications.length);
    const currentItems = orderNotifications.slice(startIndex, endIndex);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const toggleCheckbox = () => {
        setShowCheckbox(prev => {
            const newShowCheckbox = !prev;
            if (!newShowCheckbox) {
                setSelectNotifications([]);
            }
            return newShowCheckbox;
        });
    };
    
    const handleSelectAll = () => {
        if (allSelected) {
            setSelectNotifications([]);
        } else {
            setSelectNotifications(notifications.map(notification => notification.id));
        }
        setAllSelected(!allSelected); 
    };
    
    
    const handleCheckboxChange = (id: number) => {
        setSelectNotifications(prev =>
            prev.includes(id)
                ? prev.filter(notificationId => notificationId !== id) 
                : [...prev, id]
        );
    };

    const handleSubmitDelete = () => {
        console.log('notificaçõesSelecionadas: ', selectNotifications);
    }

    return(
        <>
            <section className={styles.navbarSection}>
                <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
            </section>
            <Logo/>

            <Container className={styles.contentSection}>
                <div className='d-flex justify-content-between mb-3'>
                    <div className="d-flex justify-content-start">
                        <PageTitle size="h3">Notificações</PageTitle>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button
                            onClick={toggleCheckbox}
                            className={styles.buttonCheckbox}
                        >
                           <FontAwesomeIcon icon={faSquare} className={`${showCheckbox ? styles.iconActive : styles.icon}`} /> 
                           <span>Selecionar</span> 
                        </button>
                    </div>
                </div>

                <div className={`${styles.layoutContainer} ${menuOpen ? styles.layoutContainer : styles.layoutContainerClose}`}>
                    <aside className={styles.sidebar}>
                        <img src={leroTec} alt="Lero Tec" />
                    </aside>

                    <div className={styles.verticalLine}></div>

                    <Container className="mt-3">
                        <main className={styles.notificationsContent}>
                            {currentItems.map(notification => (
                                <Card key={notification.id} className={`${styles.notificationCard} mb-3`}>
                                    <Card.Body>
                                        <ul className="list-group">
                                            <li className="list-group-item d-flex justify-content-between align-items-start border-0">
                                                <div className="ms-2 me-auto">
                                                    {notification.content}
                                                </div>
                                                <span className={`${styles.notificationDate} badge bg-primary rounded-pill`}>
                                                    {new Date(notification.date).toLocaleString()}
                                                </span>
                                            </li>
                                        </ul>
                                    </Card.Body>
                                    {showCheckbox && (
                                        <CustomCheckbox
                                            icon={faCheck}
                                            onClick={() => handleCheckboxChange(notification.id)}
                                            className={selectNotifications.includes(notification.id) ? 'selected' : ''}
                                            aria-hidden="true"
                                        />
                                    )}
                                </Card>
                            ))}

                            {showCheckbox && (
                                <div className={`${styles.buttonsContainer} d-flex justify-content-end mt-3`}>
                                    <ButtonDelete 
                                        content={<FontAwesomeIcon icon={faTrash} />} 
                                        onClick={handleSubmitDelete}
                                    />
                                    <ButtonPrimary
                                        content={<FontAwesomeIcon icon={faCheck} />}
                                        onClick={handleSelectAll}
                                    />
                                </div>
                            )}
                        </main>

                        {totalPages > 1 && (
                            <Pagination
                                totalItems={notifications.length}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                        )}
                    </Container>
                </div>
            </Container>
        </>
    )
}