import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './BankList.module.scss';
import ButtonPrimary from '../../../../../../components/Buttons/ButtonPrimary';
import notFound from '../../../../../../assets/images/NO PICTURES@3x.png';
import Pagination from '../../../../../../components/Pagination';

import { BankCard, BankLogo, BankName, CheckIcon, Container } from './BankListComponents'
import { BASE_URL } from '../../../../../../config';
import SpinnerDefault from '../../../../../../components/Spinner';
import ToastWarning from '../../../../../../components/ToastWarning';
import SearchField from '../../../../../../components/Search/SearchField';

interface Bank{
    id_bank: number;
    image_path: string;
    name: string;
    number_bank: string;
}

interface FormattedBank{
    id: number;
    path: string;
    name: string;
}

const BankList = ({onProceed} : {onProceed: any}) => {
    const [selectedBank, setSelectedBank] = useState<number | null>(null);
    const [banks, setBanks] = useState<FormattedBank[]>([]);
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [toastMessage, setToastMessage] = useState('');
    
    const filteredBanks = banks.filter(bank => 
        bank.name.toLowerCase().includes(search.toLowerCase())
    );

    //variáveis para paginação
    const itemsPerPage = 3;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(filteredBanks.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredBanks.length);
    const currentItems = filteredBanks.slice(startIndex, endIndex);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleBankSelected = (id: number) => {
        if (selectedBank === id) {
            setSelectedBank(null);
            localStorage.removeItem('bankName');
            localStorage.removeItem('bankId');
            return;
        }
    
        const selectedBankName = banks.find(bank => bank.id === id)?.name || '';
        localStorage.setItem('bankName', selectedBankName);
        localStorage.setItem('bankId', String(id));
        setSelectedBank(id);
    }

    const handleBank = async () => {
        const token = localStorage.getItem('token');

        try {
            setLoading(true);
            const res = await fetch(`${BASE_URL}/dados-bancario/banco/buscar-todos`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.ok) {
                const data: Bank[] = await res.json();
                const formattedData: FormattedBank[] = data
                    .filter((bank) => bank.id_bank !== 6 && bank.id_bank !== 16)
                    .map((bank) => ({
                        id: bank.id_bank,
                        name: bank.name,
                        path: bank.image_path
                    }));
                setBanks(formattedData);
            } else {
                console.log(res.statusText);
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
            setToastMessage("Erro ao buscar bancos");
        } finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        handleBank();
    }, []);

    const selectedBankName = banks.find(bank => bank.id === selectedBank)?.name;
    const buttonDisable = selectedBank === null;

    return (
        <>
            <SearchField
                search={search}
                onSearchChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearch(e.target.value)
                    setCurrentPage(1);
                }}
                placeholder="Pesquisar por banco..."
            />

            <Container>
                {loading ? (
                        <SpinnerDefault/>
                    )
                    :(
                        <Row className="d-flex justify-content-center">
                            {currentItems.map(bank => (
                                <BankCard 
                                    sm={12} md={3} 
                                    key={bank.id}
                                    onClick={() => handleBankSelected(bank.id)}
                                    isSelected={selectedBank === bank.id}
                                >
                                    <CheckIcon
                                        icon={faCheck}
                                        className={selectedBank === bank.id ? 'selected' : ''}
                                        aria-hidden="true"
                                    />
                                    {bank.path ? (
                                        <BankLogo src={bank.path} alt={bank.name} />
                                    ) : (
                                        <BankLogo src={notFound} alt="Nenhuma imagem encontrada" />
                                    )}
                                    <BankName>{bank.name}</BankName>
                                </BankCard>
                            ))}
                        </Row>
                    )
                }
            </Container>
            {selectedBank && (
                <Row className={styles.messageBank}>
                    <Col className="d-flex justify-content-end mt-3 mb-4">
                        <span>Banco selecionado: {selectedBankName}</span>
                    </Col>
                </Row>
            )}
            {totalPages > 1 && (
                <Pagination
                    totalItems={filteredBanks.length}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            )}
            <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-start mt-5">
                    <ButtonPrimary 
                        onClick={() => onProceed('access')}
                        content={<>
                            <FontAwesomeIcon icon={faArrowLeft} /> 
                            <span>Voltar</span>
                        </>} 
                    />
                </div>
                <div className="d-flex justify-content-end mt-5">
                    <ButtonPrimary 
                        disabled={buttonDisable} 
                        onClick={() => onProceed('form')}
                        content={<>
                            <span>Prosseguir</span>
                            <FontAwesomeIcon icon={faArrowRight} /> 
                        </>} 
                    />
                </div>
            </div>
            {toastMessage && <ToastWarning message={toastMessage} />}
        </>
    );
}

export default BankList;