import { useState, useEffect } from 'react';
import NavBar from "../../../components/NavBar";
import { Col, Container, Row } from "react-bootstrap";
import styled from 'styled-components';
import styles from './BatchQuery.module.scss';
import PageTitle from "../../../components/PageTitle";
import BankListSimulationBatch from "./BankListSimulationBatch";
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import PageSubTitle from '../../../components/PageSubTitle';
import ProgressIndicator from '../../../components/ProgressContainer';
import { motion } from 'framer-motion';
import AvailableBalance from '../../../components/AvailableBalance';
import Logo from '../../../components/Logo';

const CustomCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 1.25rem;
  height: 1.25rem;
  appearance: none;
  background-color: #153B7F;
  border: 2px solid #153B7F;
  border-radius: 0.25rem;
  cursor: pointer;
  position: relative;

  &:checked::before {
    content: '';
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    background: white;
    border-radius: 0.15rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 165, 0, 0.25);
  }
`;

const CustomLabel = styled.label`
  font-size: 1.25rem;
  color: #153B7F;
  margin-left: 0.5rem;
  font-weight: bold;
  font-family: 'PlusJakartaSans-Light', sans-serif;
`;

export default function BatchQuery() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [result, setResult] = useState<any>(null);
  const [formData, setFormData] = useState<any>(null);
  const [selectedBanks, setSelectedBanks] = useState<string[]>([]);
  const [showUnitaryFields, setShowUnitaryFields] = useState<boolean>(false);

  const totalSteps = 5;
  const currentStep = showUnitaryFields ? 2 : 1;

  useEffect(() => {
    // Recupera a lista de bancos selecionados do local storage ao montar o componente
    const savedSelectedBanks = JSON.parse(localStorage.getItem('selectedBanks') || '[]');
    setSelectedBanks(savedSelectedBanks);
    const storedResult = localStorage.getItem('apiResult');
    const storedFormData = localStorage.getItem('formData');

    if (storedResult) {
      setResult(JSON.parse(storedResult));
    }

    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }

    // Cleanup function to clear localStorage when component unmounts
    return () => {
      localStorage.removeItem('apiResult');
      localStorage.removeItem('formData');
      localStorage.removeItem('selectedBanks');
      localStorage.removeItem('processedCpfs');
    };
  }, []);

  useEffect(() => {
    // Atualiza o local storage sempre que selectedBanks mudar
    localStorage.setItem('selectedBanks', JSON.stringify(selectedBanks));
  }, [selectedBanks]);

  const handleSelectAllChange = () => {
    setSelectAll(prev => !prev);
  };

  const handleBankSelection = (selected: string[]) => {
    setSelectedBanks(selected);
  };

  const handleProceed = () => {
    if (selectedBanks.length > 0) {
      window.location.href = '/ConsultaLote/SelecaoConvenios'
    }
  };

  const handleBack = () => {
    setShowUnitaryFields(false);
  };

  const isButtonDisabled = selectedBanks.length === 0;

  const subtitleText = "Selecione um ou mais bancos para realizar a sua consulta.";

  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Logo/>

      <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
        <div className="d-flex justify-content-end">
          <AvailableBalance />
        </div>
        <div className="mt-5 d-flex justify-content-between align-items-center">
          <PageTitle size="h2">Consulta em Lote</PageTitle>
          <div className="d-flex align-items-center">
            <CustomCheckbox
              id="selectAll"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <CustomLabel htmlFor="selectAll">Selecionar Todos</CustomLabel>
          </div>
        </div>
        <div className='mb-5'>
          <PageSubTitle size='h5'>{subtitleText}</PageSubTitle>
        </div>
        <Row className='mb-5'>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <BankListSimulationBatch isSelectAll={selectAll} onSelectionChange={handleBankSelection} />
          </motion.div>
        </Row>

        <Row>
          <Col className='mt-3 d-flex justify-content-between' md={12}>
            {showUnitaryFields && (
              <Col className='d-flex justify-content-start'>
                <ButtonPrimary
                  content={<><FontAwesomeIcon icon={faArrowLeft} /> Voltar</>}
                  onClick={handleBack}
                />
              </Col>
            )}
            <Col className={showUnitaryFields ? 'd-flex justify-content-end' : 'd-flex justify-content-end'}>
              <ButtonPrimary 
                content={<><span>Prosseguir</span> <FontAwesomeIcon icon={faArrowRight} /></>} 
                disabled={isButtonDisabled}
                onClick={handleProceed}
              />
            </Col>
          </Col>
        </Row>

        <Row>
          <Col className='mt-3 d-flex justify-content-center' md={12}>
            <ProgressIndicator totalSteps={totalSteps} currentStep={currentStep} />
          </Col>
        </Row>
      </Container>
    </>
  );
}