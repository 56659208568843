import { Modal } from "react-bootstrap";
import ButtonPrimary from "../../../Buttons/ButtonPrimary";
import ButtonGray from "../../../Buttons/ButtonGray";
import { BASE_URL } from "../../../../config";
import { useEffect, useState } from "react";
import ToastWarning from "../../../ToastWarning";

interface ModalProps{
    showModal: boolean;
    onClose: () => void;
    id: number;
}

const ExternalModal = ({showModal, onClose, id}: ModalProps) => {
    const [is, setIs] = useState<boolean>(false);
    const [cod, setCod] = useState<string>('');
    const [toastMessage, setToastMessage] = useState<string>('');

    const handleSearch = async () => {
        const token = localStorage.getItem('token');
        if(token){
            try{
                const res = await fetch(`${BASE_URL}/external-code?buscar=${id}`,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await res.json();
                if (res.ok){
                    setCod(data.ExternalCode);
                    setIs(false);
                } else{
                    setIs(true);
                    setCod('');
                }
            } catch(err){
                throw err;
            }
        }
    };

    useEffect(() => {
        handleSearch();
    },[id]);

    const handleSubmit = async () => {
        const token = localStorage.getItem('token');

        try{
            const res = await fetch(`${BASE_URL}/external-code`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({external_code: cod, user_id: id}),
            });

            if (res.ok){
                setToastMessage("Código externo criado com sucesso!")
            } 
        } catch(e){
            setToastMessage("erro ao gerar código externo");
            throw e;
        }

        setTimeout(() => {
            setToastMessage('');
            onClose();
        }, 3000);
    }

    return(
        <Modal show={showModal} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Código Externo - Usuário {id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className={ `mb-2`}>
                        <input
                            type="text"
                            value={cod}
                            onChange={(e) => setCod(e.target.value)}
                            className="form-control"
                            placeholder="Código externo"
                            disabled={is === false}
                            required
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {is && (
                    <ButtonPrimary onClick={handleSubmit} content="Criar"/>
                )}
                <ButtonGray onClick={onClose} content="Cancelar"/>
            </Modal.Footer>
            {toastMessage && <ToastWarning message={toastMessage}/>}
        </Modal>
    )
}

export default ExternalModal;