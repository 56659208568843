import React, { useState, useEffect } from "react";
import { Form, Alert, Accordion, Row, Col, Card, Badge, Table } from "react-bootstrap";
import Select from "react-select";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import PageSubTitle from "../PageSubTitle";

interface BankOption {
  value: number;
  label: string;
}

export default function SimulatorPort() {
  const [formData, setFormData] = useState({
    bankId: 0,
    name: "",
    cpf: "",
    benefit: "",
    telephone: "",
    dateOfBirth: "",
    agreement: "",
    contractId: "",
    numberOfOpenInstallments: 0,
    balance: 0,
    originalInstallmentValue: 0,
    desirableInstallmentValue: 0,
  });

  const [bankOptions, setBankOptions] = useState<BankOption[]>([]);
  const [results, setResults] = useState<any[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await fetch("https://bi.gfttech.com.br/port/banks");
        if (response.ok) {
          const data = await response.json();
          const options = data.map((bank: any) => ({
            value: bank.id,
            label: bank.bankName,
          }));
          setBankOptions(options);
        }
      } catch (error) {
        console.error("Erro ao buscar bancos:", error);
      }
    };

    fetchBanks();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    console.log(formData);
  };

  const handleSelectChange = (selectedOption: BankOption | null) => {
    setFormData({ ...formData, bankId: selectedOption ? selectedOption.value : 0 });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessages([]);
    setResults([]);
  
    const sanitizedPayload = {
      ...formData,
      desirableInstallmentValue: parseFloat(formData.desirableInstallmentValue.toString()), // Certificando que é número
      originalInstallmentValue: parseFloat(formData.originalInstallmentValue.toString()), // Certificando que é número
      balance: parseFloat(formData.balance.toString()), // Certificando que é número
      numberOfOpenInstallments: parseInt(formData.numberOfOpenInstallments.toString()) || 0, // Certificando que é número
      benefit: formData.benefit || null,
      agreement: formData.agreement || null,
      contractId: formData.contractId || null,
    };
  
    try {
      const response = await fetch(
        "https://bi.gfttech.com.br/port/portabilities/calculate",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(sanitizedPayload),
        }
      );
  
      if (!response.ok) {
        if (response.status === 400) {
          const errorData = await response.json();
          setErrorMessages(errorData.message || ["Erro desconhecido."]);
        } else {
          throw new Error("Erro ao processar a solicitação.");
        }
      } else {
        const data = await response.json();
        setResults(data);
      }
    } catch (error) {
      console.error(error);
      setErrorMessages(["Erro de conexão. Tente novamente."]);
    }
  };

  const formatCurrency = (value: number | string | null | undefined) => {
    if (value == null || value === undefined || value === "")
      return "Indisponível";
    const numberValue = typeof value === "string" ? parseFloat(value) : value;
    if (isNaN(numberValue)) return "Indisponível"; // Se não for um número, retorna 'Indisponível'
    return numberValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="container mt-4">
      <Form onSubmit={handleSubmit}>
        {/* Dados do cliente */}
        <Row>
          <Col sm="12" md="6">
            <Form.Group className="mb-3">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" md="6">
            <Form.Group className="mb-3">
              <Form.Label>CPF</Form.Label>
              <Form.Control
                type="text"
                name="cpf"
                value={formData.cpf}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="4">
            <Form.Group className="mb-3">
              <Form.Label>Telefone</Form.Label>
              <Form.Control
                type="text"
                name="telephone"
                value={formData.telephone}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" md="4">
            <Form.Group className="mb-3">
              <Form.Label>Data de Nascimento</Form.Label>
              <Form.Control
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" md="4">
            <Form.Group className="mb-3">
              <Form.Label>Matrícula</Form.Label>
              <Form.Control
                type="text"
                name="benefit"
                value={formData.benefit}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Dados da Simulação */}
        <Row>
          <Col sm="12" md="6">
            <Form.Group className="mb-3">
              <Form.Label>Banco Origem</Form.Label>
              <Select
                options={bankOptions}
                onChange={handleSelectChange}
                placeholder="Selecione um banco"
              />
            </Form.Group>
          </Col>
          <Col sm="12" md="6">
            <Form.Group className="mb-3">
              <Form.Label>Convênio</Form.Label>
              <Form.Control
                type="text"
                name="agreement"
                value={formData.agreement}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="3">
            <Form.Group className="mb-3">
              <Form.Label>ID do Contrato</Form.Label>
              <Form.Control
                type="text"
                name="contractId"
                value={formData.contractId}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" md="3">
            <Form.Group className="mb-3">
              <Form.Label>Saldo</Form.Label>
              <Form.Control
                type="number"
                name="balance"
                value={formData.balance}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" md="2">
            <Form.Group className="mb-2">
              <Form.Label>Parcelas em Aberto</Form.Label>
              <Form.Control
                type="number"
                name="numberOfOpenInstallments"
                value={formData.numberOfOpenInstallments}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" md="2">
            <Form.Group className="mb-3">
              <Form.Label>Parcela Original</Form.Label>
              <Form.Control
                type="number"
                name="originalInstallmentValue"
                value={formData.originalInstallmentValue}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" md="2">
            <Form.Group className="mb-3">
              <Form.Label>Parcela Desejável</Form.Label>
              <Form.Control
                type="number"
                name="desirableInstallmentValue"
                value={formData.desirableInstallmentValue}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
       
        <div className="d-flex justify-content-center mt-5">
          <ButtonPrimary onClick={handleSubmit} content="Simular"/>
        </div>

      </Form>

      {errorMessages.length > 0 && (
        <Alert variant="danger" className="mt-3">
          <ul>
            {errorMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </Alert>
      )}

      {results.length > 0 && ( 
        <>
        <div className="d-flex justify-content-center mt-5">
          <PageSubTitle>{results.length} Banco(s) retornado(s)</PageSubTitle>
        </div>
        <Accordion>
          {/* Banco e Parcelas */}
          {results.map((result: any, index: number) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>
                <Badge bg="primary" className="me-2">
                  {result.bancoDestino}
                </Badge>
              </Accordion.Header>
              <Accordion.Body>
                <Table bordered hover className="mt-3">
                  <thead>
                    <tr>
                      <th>Condição</th>
                      <th>Valor do Contrato</th>
                      <th>Troco</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result.portabilities.map((port: any, i: number) => (
                      <tr key={i}>
                        <td>{port.table}</td>
                        <td>{`${formatCurrency(port.valorContrato)}`}</td>
                        <td>{formatCurrency(port.troco)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        </>
      )}
    </div>
  );
}
